import { useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { createAttachmentLink } from '../../actions/attachmentLink';
import EmailService from '../../services/EmailService';
import SmsService from '../../services/SmsService';

const AddAttachmentLinkForm = ({ history }) => {
    const location = useLocation();
    let form = location.state
	const [validated, setValidated] = useState(false)
	const [formData, setFormData] = useState({
		formId: form._id,
		createdBy: localStorage.getItem('supportID'),
		note: '',
		earningLessthan: false,
		recieptBenefits: false,
		settlementScheme: false,
		permissionforUk: false,
		proof: false,
        otherProof: false,
	});
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleCheckboxChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.checked });
	};
	const handleSubmit = async (e) => {
		const form = e.currentTarget
        if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
            try {
                let res = await createAttachmentLink(formData);
                if(res.status===201){               
                    sendNotification(res.data._id,location.state.personalDetails.email,location.state.personalDetails.telephone)
                    history.push('/studentsList')
                }
            } catch (err) {
                alert(err.message);
            }
        }
		setValidated(true)
	};


    const sendNotification = (formId, email, number) => {
        EmailService.sendConfirmation(
			email,
			'Attachment',
`
Your course application is being processed, unfortunately we cannot complete the application until you update the documents needed to meet the colleges requirements.
Please click the link to see what is required and to upload your document proof.
${window.location.origin}/attachments-single?id=${formId}

Thanks,
The My Free Course Team
Website: www.myfreecourse.co.uk
`
		)
        
        SmsService.sendSms(
			number,
`
Your course application is being processed, unfortunately we cannot complete the application until you update the documents needed to meet the colleges requirements.
Please click the link to see what is required and to upload your document proof.
${window.location.origin}/attachments-single?id=${formId}

Thanks,
The My Free Course Team
`
		)
	}



	return (<>
    <div className="container">
        <br/>
        <h2>Make a Attachment Link</h2>
        <br/>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                Name: {form.personalDetails.title} {form.personalDetails.firstName} {form.personalDetails.lastName}
                    <br/>
                    <Form.Group>
						<Form.Label>Note</Form.Label>
						<Form.Control
							as='textarea'
                            name='note'
							placeholder='This note would be visible on the Attachment Link'
							value={formData.note} 
							onChange={handleChange}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <b>All of these proofs would replace the previously present proofs</b><br/>
                    <Form.Group>
                        <Form.Check 
                            type="switch"
                            id="custom-switch-Earning"
                            name='earningLessthan'
                            label="Earning Less Attachments (lets user add 2 attachments)"
                            value={formData.earningLessthan} 
                            onChange={handleCheckboxChange}
                        />
                    </Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="custom-switch-benefits"
                        name='recieptBenefits'
                        label="reciept of benefits (lets user add 1 attachment)"
                        value={formData.recieptBenefits} 
                        onChange={handleCheckboxChange}
                    />
                    </Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="Attachments-custom-switch"
                        name='settlementScheme'
                        label="Attachments for 'Have you obtained settled status under then EU Settlement Scheme?' (lets user add 2 attachment)"
                        value={formData.settlementScheme} 
                        onChange={handleCheckboxChange}
                    />
                    </Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="Attachmentsfor-custom-switch"
                        name='permissionforUk'
                        label="Attachments for 'What immigration permissions do you currently hold in the UK?' (lets user add 2 attachment)"
                        value={formData.permissionforUk} 
                        onChange={handleCheckboxChange}
                    />
                    </Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="Attach-proof"
                        name='proof'
                        label="Attach proof (ID proof)"
                        value={formData.proof} 
                        onChange={handleCheckboxChange}
                    /></Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="additional-proof"
                        name='otherProof'
                        label="Other proofs"
                        value={formData.otherProof} 
                        onChange={handleCheckboxChange}
                    /></Form.Group>
                    
                </div>
                <div className='col-md-6'>
                    
                </div>
            </div>
            <br/><br/>
			<div>
				<Button variant="contained" color="primary"	className='mr-5 next-Button-form' onClick={handleSubmit}>
					Generate A Link
				</Button>
			</div>
        </Form>
        <br/><br/>
    </div>
    </>)}

    export default AddAttachmentLinkForm