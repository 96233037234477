import React from 'react'
import { toast } from 'react-toastify'
import * as admin from '../services/adminService'
import axios from 'axios'
import MaterialTable from 'material-table'
import { deleteCollege } from '../actions/formActions'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

toast.configure()
const Colleges = ({ history }) => {
	const [college, setCollege] = React.useState([])
	const sign = '\u00A3'
	const getCollegeData = () => {
		admin
			.getCollegeCount()
			.then((res) => {
				setCollege(res.data.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	React.useEffect(getCollegeData, [])



    const handleDelete = async (id) =>{
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to delete this application?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: () => {
                    deleteCollege(id)
                    let prevForms = college
                    setCollege((prevForms.filter(x=> x._id !== id)).reverse())
            }
            }
            ]
          });
    }
	return (
		<div className='container'>
            
            <Button
                className='button button-group-download'
                onClick={() => {
                    history.push('/add-college')
                }}
            >
                Add new
            </Button>
            <br/><br/> 
			{college.length == 0 ? (
				<p>There are no Colleges</p>
			) : (
                <>
                <div>
                <MaterialTable
                    title="Colleges list"
                    data={college.reverse()}
                    columns={[
                        { title: 'Name', field: 'collegeName',editable: 'never' },
                        { title: 'Active', field: 'isActive',lookup: {true:"Yes",false:"No"} },
                        // { title: 'Priority', field: 'priority',lookup: {'1':"Normal",'2':"High",'3':"Top"} },
                        // { title: 'Contract Amount', field: 'contractAmount'},
                        // { title: 'Price', field: 'pricePerApp',editable: 'never'},
                        // { title: 'Contract Value', field: 'contractValue'},
                        // { title: 'Delievered', field: 'formsDelievered' },
                        // { title: 'Remaining', field: 'remainingForms' },
                        // { title: 'Revenue', field: 'revenue',editable: 'never'},
                        { title: 'Reports', field: 'reports',editable: 'never',lookup: {true:"Yes",false:"No"}},
                    ]} 
                    detailPanel={rowData => {
                        return (
                          <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <p><b>Email: </b>{rowData.email}</p>
                                    <p><b>Password: </b>{rowData.password}</p>
                                    <p><b>Are Employer information Mandatory: </b>{rowData.employmentdetailsNecessary}</p>
                                    <p><b>PDF: </b>{rowData.pdf}</p>
                                    <p><b>Accepted Proofs</b></p>
                                    <ul>
                                        {rowData.proofs.map(x=><li>{x}</li>)}
                                    </ul>
                                </div>
                            </div>
                          </div>
                        )
                      }}
                    options={{
                        pageSize:10,
                        filtering: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        // rowData => ({
                        //     icon: 'refresh',
                        //     tooltip: 'Reset',
                        //     onClick: (event, rowData) => {resetFormsDelivered(rowData._id)},
                        //  }),
                        rowData => ({
                            icon: 'edit',
                            tooltip:'Edit',
                            onClick: (event, rowData) => {history.push('/edit/?id=' + rowData._id, rowData)}
                        }),
                        {
                            icon: 'delete',
                            isFreeAction: false,
                            tooltip: 'Delete Application',
                            onClick: (event, rowData) => {
                                handleDelete(rowData._id)
                            }
                        }

                    ]}
                        

                />
                </div>
				
            </>)}

		</div>
	)
}

export default Colleges
