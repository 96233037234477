import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import { saveFormsFilledByCallCenter } from '../../actions/formActions'

const CallCenterFroms = ({ history }) => {

    const [currentStep, setStep] = useState(0)
    const [appliedCourse, setAppliedCourse] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [email, setEmail] = useState("")
    const [PostCodeBL, setPCodeBL] = useState("")
    const [mobile, setMobile] = useState("")
    const [title, setTitle] = useState("")
    const [gender, setGender] = useState("")
    const [dob, setDOB] = useState("")
    const [addLine1, setAdd] = useState("")
    const [collegeID, setCollegeID] = useState("")
    const [contractID, setContractID] = useState("")
    const [age, setAge] = useState("")
    const [city, setCity] = useState("")
    const [county, setCounty] = useState("")
    const [yearsAtAdd, setYears] = useState("")
    const [oldPostcode, setOldPostcode] = useState("")
    const [employmentType, setEmploymentType] = useState("")
    const [annualSalary, setAnnualSalary] = useState("")
    const [emergencyContactName, setEmerCon] = useState("")
    const [emergencyTelephone, setEmerTel] = useState("")
    const [relationshipToLearner, setRelLearn] = useState("")
    const [employementStatus, setEmpStatus] = useState("")
    const [hoursPerWeek, setHours] = useState("")
    const [length, setLength] = useState("")
    const [employerName, setName] = useState("")
    const [employerAdd, setEmployerAddress] = useState("")
    const [postcode, setPostcode] = useState("")
    const [ph, setPh] = useState("")
    const [unemployedLength, setUnEmpLength] = useState("")
    const [lookingForWork, setLookingForWork] = useState("")
    const [dValue1, setValue1] = useState('')
    const [dValue2, setValue2] = useState('')
    const [dValue3, setValue3] = useState('')
    const [dValue5, setValue5] = useState('')
    const [dValue6, setValue6] = useState('')
    const [dValue7, setValue7] = useState('')
    const [dValue8, setValue8] = useState('')
    const [dValue9, setValue9] = useState('')
    const [dValue10, setValue10] = useState('')
    const [dValue11, setValue11] = useState('')
    const [areYou, setAreYou] = useState()
    const [receiptBenifits, setReceiptBenifits] = useState('')
    const [evidence, setEvidence] = useState("")
    const [evidence1, setEvidence1] = useState("")
    const [evidence2, setEvidence2] = useState("")
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [level, setLevel] = useState("")
    const [mathGrades, setMathGrades] = useState("")
    const [englishGrades, setEnglishGrades] = useState("")
    const [GCSEEnglishabove, setGCSEEnglishabove] = useState(false)
    const [GCSEMathsabove, setGCSEMathsabove] = useState(false)
    const [fullTimeEducationElse, setFullTimeEducationElse] = useState("")
    const [showFields, setShowFields] = useState(true)

    const [ethnicOrigin, setEthnicOrigin] = useState("")
    const [disabilities, setDisablities] = useState("")
    const [wheelchair, setWheelchair] = useState("")
    const [firstLang, setFirstLang] = useState("")
    const [resident, setResident] = useState("")
    const [nationality, setNationality] = useState("")
    const [inWhichCountryDoYouLive, setInWhichCountryDoYouLive] = useState("")
    const [countryOfBirth, setCountryOfBirth] = useState('')
    const [startDateEmployment, setStartDateEmployment] = useState('')
    const [statementScheme, setStatementScheme] = useState("")
    const [nonEEACitizen, setNonEEACitizen] = useState("")
    const [opdValue1, setOpValue] = useState("")
    const [opdValue2, setOpValue2] = useState("")
    const [opdValue3, setOpValue3] = useState("")
    const [opdValue4, setOpValue4] = useState("")
    const [opdValue5, setOpValue5] = useState("")
    const [opdValue6, setOpValue6] = useState("")
    const [opdValue7, setOpValue7] = useState("")
    const [opdValue8, setOpValue8] = useState("")
    const [opdValue9, setOpValue9] = useState("")
    const [opdValue10, setOpValue10] = useState("")
    const [opdValue11, setOpValue11] = useState("")
    const [opdValue12, setOpValue12] = useState("")
    const [opdValue13, setOpValue13] = useState("")
    const [opdValue14, setOpValue14] = useState("")
    const [opdValue15, setOpValue15] = useState("")
    const [opdValue16, setOpValue16] = useState("")
    const [opdValue17, setOpValue17] = useState("")
    const [opdValue18, setOpValue18] = useState("")

    const [criminalConv, setCriminalConvictions] = useState("")
    const [show4, setShow4] = useState(false)
    const [showFileUpload, setFileUpload] = useState(false)
    const [perField, setPerField] = useState(false)
    const [id1, setID1] = useState(null)
    const [id2, setID2] = useState(null)
    const [id3, setID3] = useState(null)
    const [id4, setID4] = useState(null)

    const [normalData, setNormalData] = useState();
    const [cpcaData, setCpcaData] = useState();
    const [gmcaData, setGmcaData] = useState();
    const [lcraData, setLcraData] = useState();
    const [londonData, setLondonData] = useState();
    const [lutonData, setLutonData] = useState();
    const [ntcaData, setNtcaData] = useState();
    const [scrcaData, setScrcaData] = useState();
    const [tvcaData, setTvcaData] = useState();
    const [wecaData, setWecaData] = useState();
    const [wmcaData, setWmcaData] = useState();
    const [wycaData, setWycaData] = useState();
    const [d2n2, setD2n2] = useState();
    const [basingstoke, setBasingstoke] = useState();
    const [dcaList, setDcaList] = useState();
    const [allCourses, setAllCourses] = React.useState([])
    const [collegeData, setCollegeData] = useState()
    const [contracts, setContracts] = useState();


    useEffect(async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL + /course/)
        let data = response.data.data.sort((a, b) => a.courseCode.localeCompare(b.courseCode))
        setAllCourses(data)


        let contract = await axios.get(process.env.REACT_APP_API_URL + "/contracts/all")
        setContracts(contract.data.data)
    }, [])


    function scrollTtoView() {
        document.getElementById('title-assessment').scrollIntoView({
            behavior: 'smooth'
        });
    }

    useEffect(async () => {
        let collegeData = await axios.get(process.env.REACT_APP_API_URL + '/admin/collegedata')
        setCollegeData(collegeData.data.data)
    }, [])

    useEffect(() => {
        fetch('/normal.txt')
            .then((r) => r.text())
            .then(text => {
                setNormalData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/cpca.txt')
            .then((r) => r.text())
            .then(text => {
                setCpcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/gmca.txt')
            .then((r) => r.text())
            .then(text => {
                setGmcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/lcra.txt')
            .then((r) => r.text())
            .then(text => {
                setLcraData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/london.txt')
            .then((r) => r.text())
            .then(text => {
                setLondonData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/luton.txt')
            .then((r) => r.text())
            .then(text => {
                setLutonData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/wycalist.txt')
            .then((r) => r.text())
            .then(text => {
                setWycaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/ntca.txt')
            .then((r) => r.text())
            .then(text => {
                setNtcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/scrca.txt')
            .then((r) => r.text())
            .then(text => {
                setScrcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/dcaList.txt')
            .then((r) => r.text())
            .then(text => {
                setDcaList(text);
            })
    }, [])



    useEffect(() => {
        fetch('/tvca.txt')
            .then((r) => r.text())
            .then(text => {
                setTvcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/weca.txt')
            .then((r) => r.text())
            .then(text => {
                setWecaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/wmca.txt')
            .then((r) => r.text())
            .then(text => {
                setWmcaData(text);
            })
    }, [])

    useEffect(() => {
        fetch('/basingstoke.txt')
            .then((r) => r.text())
            .then(text => {
                setBasingstoke(text);
            })
    }, [])



    useEffect(() => {
        fetch('/d2n2.txt')
            .then((r) => r.text())
            .then(text => {
                setD2n2(text);
            })
    }, [])


    function arrayDif(colleges, listName) {
        let finalColleges = []
        for (let i = 0; i < colleges.length; i++) {
            for (let k = 0; k < colleges[i].whitelist.length; k++) {
                if (colleges[i].whitelist[k] !== listName) {
                    if (finalColleges.includes(colleges[i]) === false) {
                        let clg = colleges[i]
                        let index = clg.whitelist.indexOf(clg.whitelist[k])
                        let list = clg.whitelist.splice(index, 1)
                        clg.whitelist = list
                        finalColleges.push(clg)
                    }
                }
            }
        }
        return finalColleges
    }


    const postCode = () => {
        let postCodesList = []
        let contractsF = (contracts).filter(x => x.isActive === true)
        let contractsList = contractsF.filter(x => x.coursesList.includes(appliedCourse))

        contractsList.map(x => {
            (x.whitelist).map(list => {
                if (postCodesList.includes(list) === false)
                    postCodesList.push(list)
            })
        })

        if (postCodesList.includes("Normal")) {
            if (normalData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Normal")
            }
        }

        if (postCodesList.includes("Wmca")) {
            if (wmcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Wmca")
            }
        }

        if (postCodesList.includes("Weca")) {
            if (wecaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Weca")
            }
        }

        if (postCodesList.includes("Tvca")) {
            if (tvcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Tvca")
            }
        }

        if (postCodesList.includes("Scrca")) {
            if (scrcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Scrca")
            }
        }

        if (postCodesList.includes("Ntca")) {
            if (ntcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Ntca")
            }
        }

        if (postCodesList.includes("Wyca")) {
            if (wycaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Wyca")
            }
        }

        if (postCodesList.includes("London")) {
            if (londonData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "London")
            }
        }

        if (postCodesList.includes("Lcra")) {
            if (lcraData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Lcra")
            }
        }

        if (postCodesList.includes("Gmca")) {
            if (gmcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Gmca")
            }
        }

        if (postCodesList.includes("Cpca")) {
            if (cpcaData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Cpca")
            }
        }

        if (postCodesList.includes("Luton_codes")) {
            if (lutonData.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Luton_codes")
            }
        }

        if (postCodesList.includes("Basingstoke")) {
            if (basingstoke.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "Basingstoke")
            }
        }

        if (postCodesList.includes("dcaList")) {
            if (dcaList.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "dcaList")
            }
        }

        if (postCodesList.includes("d2n2")) {
            if (d2n2.includes(PostCodeBL) === false) {
                contractsList = arrayDif(contractsList, "d2n2")
            }
        }

        if (contractsList.length > 0) {
            alert("Validated")
            const randomCollegeIndex = Math.floor(Math.random() * contractsList.length)
            const chosenContract = contractsList[randomCollegeIndex]
            const chosenCollege = collegeData.find(x => x._id === chosenContract.collegeID)
            setContractID(chosenContract._id)
            setCollegeID(chosenCollege._id)
            localStorage.setItem('countryOfBirth', chosenCollege.countryOfBirth)
            localStorage.setItem('startDateEmployment', chosenCollege.startDateEmployment)
            localStorage.setItem('employmentdetailsNecessary', chosenCollege.employmentdetailsNecessary)
            localStorage.setItem('softEnglishTest', chosenCollege.softEnglishTest)
            localStorage.setItem('inWhichCountryDoYouLive', chosenCollege.inWhichCountryDoYouLive)
            localStorage.setItem('fullTimeEducationElse', chosenCollege.fullTimeEducationElse)
            localStorage.setItem('proofs', chosenCollege.proofs)
            localStorage.setItem('ifEmploymentType', chosenCollege.ifEmploymentType || false)
            localStorage.setItem('ifAnualSalary', chosenCollege.ifAnualSalary || false)
            return { 'college': chosenCollege._id, 'contract': chosenContract._id }
        } else {
            alert("Failed")
            return null
        }

    }

    const handleChange = (e) => {
        setEmpStatus(e.target.value)
        setEmpStatus(e.target.value)
        if (e.target.value === 'Unemployed, looking for work') {
            setShow(true)
            setEvidence("")
            setEvidence1("")
            setEvidence2("")
        } else if (e.target.value === 'Employed but on less than £17,374.50 per year') {
            setShow1(true)
            setShow(false)
            setShow3(false)
            setEvidence("Yes Less than")
            setEvidence1("Yes")
            setEvidence2("Yes")
        } else if (e.target.value === 'Self Employed but on less than £17,374.50' || e.target.value === 'Greater London Employed but on less than £21,547' || e.target.value === 'Greater London Self Employed but on less than £21,547') {
            setShow1(true)
            setShow(false)
            setShow3(false)
            setEvidence("Yes")
            setEvidence1("Yes")
            setEvidence2("Yes")
        } else {
            setShow1(false)
            setShow(false)
            setShow3(false)
            setEvidence("")
            setEvidence1("")
            setEvidence2("")
        }
    }

    const handleChange1 = (e) => {
        setValue11(e.target.value)
        setReceiptBenifits(e.target.value)
        if (e.target.value === 'Yes') {
            setShow2(true)
            setEvidence("Yes")
        } else {
            setShow2(false)
            setEvidence("")
        }
    }
    const handleChange2 = (e) => {
        setLevel(e.target.value)
        if (e.target.value === 'None') {
            setShowFields(false)
        } else {
            setShowFields(true)
        }
    }
    const handleChange3 = (e) => {
        setDisablities(e.target.value)
        if (e.target.value === 'Yes') {
            setShow4(true)
        } else {
            setShow4(false)
        }
    }
    const handleChange4 = (e) => {
        setNonEEACitizen(e.target.value)
        if (e.target.value === 'Yes') {
            setFileUpload(true)
            setID1("Yes")
            setID2("Yes")
        } else {
            setFileUpload(false)
            setID1("")
            setID2("")
        }
    }
    const handleChange5 = (e) => {
        setStatementScheme(e.target.value)
        if (e.target.value === 'Yes') {
            setID3("Yes")
        } else {
            setID3("")
        }
    }

    const submitHandler = (e) => {
        if (receiptBenifits === "Yes") {
            setEvidence("Yes");
        }
        let college
        let contract
        if (!collegeID || !contractID) {
            let funcres = postCode()
            college = funcres.college
            contract = funcres.contract
        }
        let data = {
            detailsFormData: {
                appliedCourse: appliedCourse,
            },
            personalDetails: {
                title: title,
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                gender: gender,
                dob: dob,
                age: age,
                addLine1: addLine1,
                city: city,
                county: county,
                postcode: PostCodeBL,
                telephone: mobile,
                email: email,
                yearsAtAdd: yearsAtAdd,
                oldPostcode: oldPostcode,
            },
            emergencyDetails: {
                emergencyContactName: emergencyContactName,
                emergencyTelephone: emergencyTelephone,
                relationshipToLearner: relationshipToLearner,
            },
            employmentDetails: {
                employementStatus: employementStatus,
                hoursPerWeek: hoursPerWeek,
                length: length,
                employmentType: employmentType,
                annualSalary:annualSalary,
                employerName: employerName,
                employerAdd: employerAdd,
                postcode: postcode,
                ph: ph,
                unemployedLength: unemployedLength,
                lookingForWork: lookingForWork,
                areYou: areYou,
                dValue1: dValue1,
                dValue2: dValue2,
                dValue3: dValue3,
                dValue5: dValue5,
                dValue6: dValue6,
                dValue7: dValue7,
                dValue8: dValue8,
                dValue9: dValue9,
                dValue10: dValue10,
                dValue11: dValue11,
                evidence: evidence,
                evidence1: evidence1,
                evidence2: evidence2,
            },
            qualificationDetails: {
                level: level,
                mathGrades: mathGrades,
                englishGrades: englishGrades,
                GCSEMathsabove: GCSEMathsabove,
                GCSEEnglishabove: GCSEEnglishabove,
                fullTimeEducationElse: fullTimeEducationElse

            },
            oppDetails: {
                ethnicOrigin: ethnicOrigin,
                disabilities: disabilities,
                dValue1: opdValue1,
                dValue2: opdValue2,
                dValue3: opdValue3,
                dValue4: opdValue4,
                dValue5: opdValue5,
                dValue6: opdValue6,
                dValue7: opdValue7,
                dValue8: opdValue8,
                dValue9: opdValue9,
                dValue10: opdValue10,
                dValue11: opdValue11,
                dValue12: opdValue12,
                dValue13: opdValue13,
                dValue14: opdValue14,
                dValue15: opdValue15,
                dValue16: opdValue16,
                dValue17: opdValue17,
                dValue18: opdValue18,
                wheelchair: wheelchair,
                firstLang: firstLang,
                nationality: nationality,
                statementScheme: statementScheme,
                resident: resident,
                nonEEACitizen: nonEEACitizen,
                criminalConv: criminalConv,
                attachment1: id1,
                attachment2: id2,
                attachment3: id3,
            },
            StaffID: localStorage.getItem('callCenterID'),
            collegeID: collegeID || college,
            contractId: contractID || contract
        }

        const form = e.currentTarget
        if (form.checkValidity() === false) {
            console.log("error")
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            saveFormsFilledByCallCenter(data)
            history.push('/all-forms-callcenter')
        }
    }

    return (
        <>
            <FormContainer>
                <h2 id="title-assessment">
                    <b>A new form</b>
                </h2>
                {(currentStep === 0) && <>
                    <Button
                        onClick={() => setStep(1)}
                        className='next-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'right'
                        }}
                    >
                        {' '}
                        start
                    </Button>
                </>
                }
                <Form>

                    {/* Step 1 Detail form  */}
                    {(currentStep === 1) &&
                        <>
                            <Form.Group controlId='appliedCourse'>
                                <Form.Label>
                                    <b>Which course are you applying for?</b>{' '}
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    value={appliedCourse}
                                    onChange={(e) => setAppliedCourse(e.target.value)}
                                >
                                    <option disabled value=''>[Please Select One]</option>
                                    {allCourses.map(x => <option value={x.courseCode}>{x.courseCode}</option>)}
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='title'>
                                <Form.Label>Title </Form.Label>
                                <Form.Control
                                    as='select'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Mr'>Mr</option>
                                    <option value='Miss'>Miss</option>
                                    <option value='Ms'>Ms</option>
                                    <option value='Mx'>Mx</option>
                                    <option value='Mrs'>Mrs</option>
                                    <option value='other'>Other</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='firstName'>
                                <Form.Label>First Name </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter First Name'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='middleName'>
                                <Form.Label>Middle Name </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Middle Name'
                                    value={middleName}
                                    onChange={(e) => setMiddleName(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='lastName'>
                                <Form.Label>Last Name </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Last Name'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='email'>
                                <Form.Label>Email </Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Enter Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='PostId'>
                                <Form.Label>Post Code</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter PostCode'
                                    value={PostCodeBL}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\s/g, '');
                                        setPCodeBL(e.target.value.toUpperCase())
                                    }}
                                ></Form.Control>
                                <Form.Text className='text-muted'>
                                    No Special Charcters and no spaces.
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button className='next-Button-form' onClick={() => postCode()}> Validate PostCode</Button>
                            <Form.Group controlId='mobile'>
                                <Form.Label>Mobile </Form.Label>
                                <Form.Control
                                    type='number'
                                    placeholder='Enter Mobile No'
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    }



                    {/* Step 2 Personal Details form  */}
                    {(currentStep === 2) && <>

                        <Form.Group controlId='gender'>
                            <Form.Label>Gender </Form.Label>
                            <Form.Control
                                as='select'
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <option selected disabled value=''>
                                    [Please select one]
                                </option>
                                <option value='Female'>Female</option>
                                <option value='Male'>Male</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='dob'>
                            <Form.Label>Date Of Birth</Form.Label>
                            <Form.Control
                                type='date'
                                value={dob}
                                onChange={(e) => setDOB(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='age'>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                type='number'
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='addLine1'>
                            <Form.Label>Address Line</Form.Label>
                            <Form.Control
                                type='text'
                                value={addLine1}
                                onChange={(e) => setAdd(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='city'>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type='text'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='county'>
                            <Form.Label>County</Form.Label>
                            <Form.Control
                                type='text'
                                value={county}
                                onChange={(e) => setCounty(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='yearsAtAdd'>
                            <Form.Label>Years at Address</Form.Label>
                            <Form.Control
                                type='number'
                                value={yearsAtAdd}
                                onChange={(e) => setYears(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {(yearsAtAdd < 3) && <>
                            <Form.Group controlId='PostId'>
                                <Form.Label>Previous Home postcode </Form.Label>
                                <Form.Control
                                    type='text'
                                    required
                                    placeholder='Enter PostCode'
                                    value={oldPostcode}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/\s/g, '');
                                        setOldPostcode(e.target.value.toUpperCase())
                                    }}
                                ></Form.Control>
                                <Form.Text className='text-muted'>
                                    No Special Charcters and no spaces.
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>}

                    </>
                    }


                    {/* Step 3 Emergency details  */}
                    {(currentStep === 3) && <>
                        <Form.Group controlId='emergencyContactName'>
                            <Form.Label>Emergency Contact Name</Form.Label>
                            <Form.Control
                                type='text'
                                value={emergencyContactName}
                                onChange={(e) => setEmerCon(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='relationshipToLearner'>
                            <Form.Label>Relation to Learner</Form.Label>
                            <Form.Control
                                type='text'
                                value={relationshipToLearner}
                                onChange={(e) => setRelLearn(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='emergencyTelephone'>
                            <Form.Label>Telephone</Form.Label>
                            <Form.Control
                                type='number'
                                value={emergencyTelephone}
                                onChange={(e) => setEmerTel(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                    }


                    {/* Step 4 Employment details  */}
                    {(currentStep === 4) && <>
                        <Form.Group controlId='employementStatus'>
                            <Form.Label>Please select your employment status </Form.Label>
                            <Form.Control
                                as='select'
                                value={employementStatus}
                                onChange={handleChange}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value='In paid employment (or self employed)'>
                                    Living outside London - Employed or self employed
                                </option>
                                <option value='Employed but on less than £17,374.50 per year'>
                                    Living outside London - Earning less than £17,374.50
                                </option>
                                {/* <option value='Self Employed but on less than £17,374.50'>
								Self Employed but on less than £17,374.50
							</option> */}
                                <option value='Greater London Employed but on less than £21,547'>
                                    Living in London - Earning less than £21,547
                                </option>
                                <option value='Living in London - Employed or self employed'>
                                    Living in London - Employed or self employed
                                </option>
                                <option value='Unemployed, looking for work'>
                                    Unemployed/Unwaged/Retired
                                </option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {!show && !show1 && !show3 && (
                            <>
                                {localStorage.getItem('ifEmploymentType') === 'true' && <Form.Group controlId='emlpoyedType'>
                                    <Form.Label>
                                        Employment Type
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        required
                                        value={employmentType}
                                        onChange={(e) => setEmploymentType(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Self Employed'>
                                            Self Employed
                                        </option>
                                        <option value='Employed'>
                                            Employed
                                        </option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                }

                                <Form.Group controlId='hoursPerWeek'>
                                    <Form.Label>
                                        Please select how many hours you work per week?
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={hoursPerWeek}
                                        onChange={(e) => setHours(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='0 to 10 hours per week'>
                                            0 to 10 hours per week
                                        </option>
                                        <option value='11 to 20 hours per week'>
                                            11 to 20 hours per week
                                        </option>
                                        <option value='21 to 30 hours per week'>
                                            21 to 30 hours per week
                                        </option>
                                        <option value='30+ hours per week'>30+ hours per week</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {(localStorage.getItem('ifAnualSalary') === 'true') && <Form.Group controlId='anualSalary'>
                                    <Form.Label>Please share your Annual Salary.</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required
                                        value={annualSalary}
                                        onChange={(e) => setAnnualSalary(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>}
                                <Form.Group controlId='length'>
                                    <Form.Label>
                                        Please select the length of your employment (months){' '}
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Up to 3'>Up to 3</option>
                                        <option value='4-6'>4-6</option>
                                        <option value='7-12'>7-12</option>
                                        <option value='12+'>12+</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* <Form.Group controlId='CHeckbox'>
								<Form.Check
									type='checkbox'
									label='I can confirm that I am not in receipt of benefits'
									className='mr-2'
									value='dValue11'
									name='dValue11'
									onChange={(e) => {
										setValue11(e.target.value)
									}}
								></Form.Check>
								<Form.Control.Feedback type='invalid'>
									Please fill the required field.
								</Form.Control.Feedback>
							</Form.Group> */}

                                <Form.Group controlId='employerName'>
                                    <Form.Label>Please enter the name of your employer.{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                        value={employerName}
                                        onChange={(e) => setName(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='employerAdd'>
                                    <Form.Label>Employer address{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={employerAdd}
                                        required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                        onChange={(e) => setEmployerAddress(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='postcode'>
                                    <Form.Label>Postcode{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                        maxLength="8"
                                        value={postcode}
                                        onChange={(e) => setPostcode(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='ph'>
                                    <Form.Label>Phone Number{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                    <Form.Control
                                        type='number'
                                        required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                        value={ph}
                                        onChange={(e) => setPh(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group >
                                    <Form.Label>Start Date Employment</Form.Label>
                                    <Form.Control
                                        type='date'
                                        required={(localStorage.getItem('startDateEmployment') === "true")}
                                        value={startDateEmployment}
                                        onChange={(e) => setStartDateEmployment(e.target.value)}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        )}

                        {show && (
                            <>
                                <h3>Step 3.5: Unwaged, Unemployed or Retired </h3>
                                <Form.Group controlId='unemployedLength'>
                                    <Form.Label>
                                        Please select how long you have been unwaged, unemployed or
                                        retired
                                    </Form.Label>
                                    <Form.Control
                                        as='select'

                                        value={unemployedLength}
                                        onChange={(e) => setUnEmpLength(e.target.value)}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='0-5 months'>0-5 months</option>
                                        <option value='6-11 months'>6-11 months</option>
                                        <option value='12-23 months'>12-23 months</option>
                                        <option value='24-35 months'>24-35 months</option>
                                        <option value='Over 36 months'>Over 36 months</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='areYou'>
                                    <Form.Label>
                                        Are you 19-23 (on first day of learning) and enrolling onto
                                        qualifications up to and including Level 2
                                    </Form.Label>
                                    <Form.Control
                                        as='select'

                                        onChange={(e) => setAreYou(e.target.value)}
                                        value={areYou}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId='receiptBenifits'>
                                    <Form.Label>Are you in receipt of benefits</Form.Label>
                                    <Form.Control
                                        as='select'

                                        className='mr-2'
                                        onChange={handleChange1}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {show2 && (
                                    <>
                                        <Form.Group controlId='dec'>
                                            <Form.Label>
                                                Benefit status? (Please tick all that apply){' '}
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Check
                                            type='checkbox'
                                            label='JSA'
                                            name='dValue1'
                                            value='dValue1'
                                            onChange={(e) => {
                                                setValue1(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Income Support'
                                            name='dValue2'
                                            value='dValue2'
                                            onChange={(e) => {
                                                setValue2(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Council Tax Benefit'
                                            name='dValue3'
                                            value='dValue3'
                                            onChange={(e) => {
                                                setValue3(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='ESA (Any)'
                                            name='dValue5'
                                            value='dValue5'
                                            onChange={(e) => {
                                                setValue5(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Incapacity Benefit'
                                            name='dValue6'
                                            value='dValue6'
                                            onChange={(e) => {
                                                setValue6(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Housing Benefit'
                                            name='dValue7'
                                            value='dValue7'
                                            onChange={(e) => {
                                                setValue7(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Universal Credit'
                                            name='dValue8'
                                            value='dValue8'
                                            onChange={(e) => {
                                                setValue8(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Employed but on low wage'
                                            name='dValue9'
                                            value='dValue9'
                                            onChange={(e) => {
                                                setValue9(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Group controlId='lookingForWork'>
                                            <Form.Label>Are you actively looking for work </Form.Label>
                                            <Form.Control
                                                as='select'
                                                required
                                                value={lookingForWork}
                                                className='mr-2'
                                                onChange={(e) => setLookingForWork(e.target.value)}
                                            >
                                                <option selected disabled value=''>
                                                    [Please select one]
                                                </option>
                                                <option value='Yes'>Yes</option>
                                                <option value='No'>No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type='invalid'>
                                                Please fill the required field.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                                )}

                                <Form.Group controlId='areYourRetired'>
                                    <Form.Label>Are you retired?</Form.Label>
                                    <Form.Control
                                        as='select'
                                        onChange={(e) => {
                                            setValue10(e.target.value)
                                        }}
                                    >
                                        <option selected disabled value=''>
                                            [Please select one]
                                        </option>
                                        <option value='Yes'>Yes</option>
                                        <option value='No'>No</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill the required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        )}

                        <>
                            {!show && show1 && (
                                <>

                                    {localStorage.getItem('ifEmploymentType') === 'true' && <Form.Group controlId='emlpoyedType'>
                                        <Form.Label>
                                            Employment Type
                                        </Form.Label>
                                        <Form.Control
                                            as='select'
                                            required
                                            value={employmentType}
                                            onChange={(e) => setEmploymentType(e.target.value)}
                                        >
                                            <option selected disabled value=''>
                                                [Please select one]
                                            </option>
                                            <option value='Self Employed'>
                                                Self Employed
                                            </option>
                                            <option value='Employed'>
                                                Employed
                                            </option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }

                                    <Form.Group controlId='hoursPerWeek'>
                                        <Form.Label>
                                            Please select how many hours you work per week?
                                        </Form.Label>
                                        <Form.Control
                                            as='select'

                                            value={hoursPerWeek}
                                            onChange={(e) => setHours(e.target.value)}
                                        >
                                            <option selected disabled value=''>
                                                [Please select one]
                                            </option>
                                            <option value='0 to 10 hours per week'>
                                                0 to 10 hours per week
                                            </option>
                                            <option value='11 to 20 hours per week'>
                                                11 to 20 hours per week
                                            </option>
                                            <option value='21 to 30 hours per week'>
                                                21 to 30 hours per week
                                            </option>
                                            <option value='30+ hours per week'>
                                                30+ hours per week
                                            </option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {(localStorage.getItem('ifAnualSalary') === 'true') && <Form.Group controlId='anualSalary'>
                                        <Form.Label>Please share your Annual Salary.</Form.Label>
                                        <Form.Control
                                            type='text'
                                            required
                                            value={annualSalary}
                                            onChange={(e) => setAnnualSalary(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>}
                                    <Form.Group controlId='length'>
                                        <Form.Label>
                                            Please select the length of your employment (months){' '}
                                        </Form.Label>
                                        <Form.Control
                                            as='select'

                                            value={length}
                                            onChange={(e) => setLength(e.target.value)}
                                        >
                                            <option selected disabled value=''>
                                                [Please select one]
                                            </option>
                                            <option value='Up to 3'>Up to 3</option>
                                            <option value='4-6'>4-6</option>
                                            <option value='7-12'>7-12</option>
                                            <option value='12+'>12+</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId='employerName'>
                                        <Form.Label>Please enter the name of your employer.{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                            value={employerName}
                                            onChange={(e) => setName(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='employerAdd'>
                                        <Form.Label>Employer Address{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                            value={employerAdd}
                                            onChange={(e) => setEmployerAddress(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='postcode'>
                                        <Form.Label>Postcode{(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                            value={postcode}
                                            onChange={(e) => setPostcode(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='ph'>
                                        <Form.Label>Phone Number {(localStorage.getItem('employmentdetailsNecessary') === "true") ? '(Mandatory)' : '(Optional)'}</Form.Label>
                                        <Form.Control
                                            type='number'
                                            required={(localStorage.getItem('employmentdetailsNecessary') === "true")}
                                            value={ph}
                                            onChange={(e) => setPh(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Start Date Employment</Form.Label>
                                        <Form.Control
                                            type='date'
                                            required={(localStorage.getItem('startDateEmployment') === "true")}
                                            value={startDateEmployment}
                                            onChange={(e) => setStartDateEmployment(e.target.value)}
                                        ></Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )}
                        </>
                    </>
                    }

                    {/* Step 5 Qualifications details  */}
                    {(currentStep === 5) &&
                        <>
                            <Form.Group controlId='level'>
                                <Form.Label>What is your highest level of qualification? </Form.Label>
                                <Form.Control as='select' value={level} onChange={handleChange2}>
                                    <option selected disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='None'>None, not sure or n/a</option>
                                    <option value='Entry Level'>
                                        Entry Level - (e.g. Basic/Foundation Level, Word Power or Number
                                        Power, GCF Entry Level Award/Certificate)
                                    </option>
                                    <option value='Level 1'>
                                        Level 1 - (e.g. GC SE(5 or more grades D-G or fewer than 5 grades
                                        A*-C), AS Level, CSE below grade 1, GNVQ Foundation, QCF Level 1
                                        Award/Certificate/ Diploma or NVQ 1)
                                    </option>
                                    <option value='Level 2'>
                                        Level 2 (e.g. 5 or more GCSE/O Levels at grades A* - C, 1 A Level,
                                        2/3 AS Levels, 5 or more CSE grade 1’s, GNVQ Intermediate, QCF
                                        Diploma Level 2)
                                    </option>
                                    <option value='Full Level 2'>
                                        Full Level 2 (e.g. 5 or more GCSE/O Levels at grades A* - C, 1 A
                                        Level, 2/3 AS Levels, 5 or more CSE grade 1’s, GNVQ Intermediate,
                                        QCF Diploma Level 2)
                                    </option>
                                    <option value='Level 3'>
                                        Level 3 - (e.g. 4 or more AS Levels, 2 or more A Levels, BTEC
                                        Diploma, BTEC Extended Diplomas, QAA Access to Higher Education, QCF
                                        Diplomas Level 3)
                                    </option>
                                    <option value='Full Level 3'>
                                        Full Level 3 - (e.g. 4 or more AS Levels, 2 or more A Levels, BTEC
                                        Diploma, BTEC Extended Diplomas, QAA Access to Higher Education, QCF
                                        Diplomas Level 3)
                                    </option>
                                    <option value='Level 4'>
                                        Level 4 - (e.g. Higher National Certificate (HNC), QCF Level 4
                                        Award/Certificate/Diploma or NVQ Level 4)
                                    </option>
                                    <option value='Level 5'>
                                        Level 5 - (e.g. Foundation Degree, Higher National Diploma (HND) or
                                        QCF Level 5 Award/Certificate/Diploma)
                                    </option>
                                    <option value='Level 6'>
                                        Level 6 - (e.g. Bachelors Degree, Professional Graduate Certificate
                                        in Education (PGCE) or QCF Level 6 Award/Certificate/Diploma)
                                    </option>
                                    <option value='Level 7'>Level 7</option>
                                    <option value='Level 7 and above'>Level 7 and Above</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {showFields && (
                                <>

                                    <Form.Group controlId='CHeckbox1'>
                                        <Form.Check
                                            type='checkbox'
                                            label='Do you have GCSE English Grade 4/C and above?'
                                            className='mr-2'
                                            checked={GCSEEnglishabove}
                                            name='GCSE English above'
                                            onChange={(e) => {
                                                setGCSEEnglishabove(!GCSEEnglishabove)
                                            }}
                                        ></Form.Check>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='CHeckbox2'>
                                        <Form.Check
                                            type='checkbox'
                                            label='Do you have GCSE MathsM Grade 4/C and above?'
                                            className='mr-2'
                                            value={GCSEMathsabove}
                                            name='GCSE Maths above'
                                            onChange={(e) => {
                                                setGCSEMathsabove(!GCSEMathsabove)
                                            }}
                                        ></Form.Check>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='mathGrades'>
                                        <Form.Label>Maths GCSE Grades:</Form.Label>
                                        <Form.Control
                                            as='select'
                                            value={mathGrades}
                                            onChange={(e) => setMathGrades(e.target.value)}
                                        >

                                            <option disabled selected value=''>
                                                [Please select one]
                                            </option>
                                            <option value='A*'>A*</option>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                            <option value='D'>D</option>
                                            <option value='E'>E</option>
                                            <option value='F'>F</option>
                                            <option value='G'>G</option>
                                            <option value='U'>U</option>
                                            <option value='9'>9</option>
                                            <option value='8'>8</option>
                                            <option value='7'>7</option>
                                            <option value='6'>6</option>
                                            <option value='5'>5</option>
                                            <option value='4'>4</option>
                                            <option value='3'>3</option>
                                            <option value='2'>2</option>
                                            <option value='1'>1</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId='englishGrades'>
                                        <Form.Label>English GCSE Grades:</Form.Label>
                                        <Form.Control
                                            as='select'
                                            value={englishGrades}
                                            onChange={(e) => setEnglishGrades(e.target.value)}
                                        >

                                            <option disabled selected value=''>
                                                [Please select one]
                                            </option>
                                            <option value='A*'>A*</option>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                            <option value='D'>D</option>
                                            <option value='E'>E</option>
                                            <option value='F'>F</option>
                                            <option value='G'>G</option>
                                            <option value='U'>U</option>
                                            <option value='9'>9</option>
                                            <option value='8'>8</option>
                                            <option value='7'>7</option>
                                            <option value='6'>6</option>
                                            <option value='5'>5</option>
                                            <option value='4'>4</option>
                                            <option value='3'>3</option>
                                            <option value='2'>2</option>
                                            <option value='1'>1</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </>
                            )}

                            <Form.Group>
                                <Form.Label>
                                    Are you in full time education elsewhere?
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={fullTimeEducationElse}
                                    onChange={(e) => setFullTimeEducationElse(e.target.value)}
                                >
                                    <option disabled value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'> No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill in this required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </>
                    }


                    {/* Step 6 OOP details  */}
                    {(currentStep === 6) &&
                        <>
                            <Form.Group controlId='ethnicOrigin'>
                                <Form.Label>What is your Ethnic Origin?</Form.Label>
                                <Form.Control
                                    as='select'

                                    value={ethnicOrigin}
                                    onChange={(e) => setEthnicOrigin(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='English/Welsh/Scottish/Northern Irish/British'>
                                        English/Welsh/Scottish/Northern Irish/British
                                    </option>
                                    <option value='Irish'>Irish</option>
                                    <option value='Gypsy or Irish traveller'>
                                        Gypsy or Irish traveller
                                    </option>
                                    <option value='Any other white background'>
                                        Any other white background
                                    </option>
                                    <option value='White and Black Carribean'>
                                        White and Black Carribean
                                    </option>
                                    <option value='White and Black African'>White and Black African</option>
                                    <option value='White and Asian'>White and Asian</option>
                                    <option value='Any other mixed/multiple ethnic background'>
                                        Any other mixed/multiple ethnic background
                                    </option>
                                    <option value='Indian'>Indian</option>
                                    <option value='Pakistani'>Pakistani</option>
                                    <option value='Bangladeshi'>Bangladeshi</option>
                                    <option value='Chinese'>Chinese</option>
                                    <option value='Any other Asian background'>
                                        Any other Asian background
                                    </option>
                                    <option value='African'>African</option>
                                    <option value='Caribbean'>Caribbean</option>
                                    <option value='Any other Black/African/Caribbean'>
                                        Any other Black/African/Caribbean
                                    </option>
                                    <option value='Arab'>Arab</option>
                                    <option value='Any other ethnic group'>Any other ethnic group</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='disabilities'>
                                <Form.Label>
                                    Do you have any disabilities or learning difficulties?
                                </Form.Label>
                                <Form.Control
                                    as='select'

                                    value={disabilities}
                                    onChange={handleChange3}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {show4 && (
                                <>
                                    <Form.Group controlId=''>
                                        <Form.Label>Select those which apply</Form.Label>
                                        <br />

                                        <Form.Check
                                            type='checkbox'
                                            label='Visual Impairment'
                                            name='opdValue1'
                                            value={opdValue1}
                                            onChange={(e) => {
                                                setOpValue(e.target.value)
                                            }}
                                            className='mr-2'
                                        ></Form.Check>

                                        <Form.Check
                                            type='checkbox'
                                            label='Hearing Impairment'
                                            name='opdValue2'
                                            value={opdValue2}
                                            onChange={(e) => setOpValue2(e.target.value)}
                                            className='mr-2'
                                        ></Form.Check>

                                        <Form.Check
                                            type='checkbox'
                                            label='Disability affecting mobility'
                                            name='opdValue3'
                                            value={opdValue3}
                                            onChange={(e) => setOpValue3(e.target.value)}
                                            className='mr-2'
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Profound complex disabilities'
                                            name='opdValue4'
                                            value={opdValue4}
                                            className='mr-2'
                                            onChange={(e) => setOpValue4(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Social and emotional difficulties'
                                            name='opdValue5'
                                            value={opdValue5}
                                            className='mr-2'
                                            onChange={(e) => setOpValue5(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Mental Health Difficulties'
                                            name='opdValue6'
                                            value={opdValue6}
                                            className='mr-2'
                                            onChange={(e) => setOpValue6(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Moderate Learning Difficulties'
                                            name='opdValue7'
                                            value={opdValue7}
                                            className='mr-2'
                                            onChange={(e) => setOpValue7(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Severe Learning Difficulties'
                                            name='opdValue8'
                                            value={opdValue8}
                                            className='mr-2'
                                            onChange={(e) => setOpValue8(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Dyslexia'
                                            name='opdValue9'
                                            value={opdValue9}
                                            className='mr-2'
                                            onChange={(e) => setOpValue9(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Dyscalculia'
                                            name='opdValue10'
                                            value={opdValue10}
                                            className='mr-2'
                                            onChange={(e) => setOpValue10(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Autism Spectrum Disorder'
                                            name='opdValue11'
                                            value={opdValue11}
                                            className='mr-2'
                                            onChange={(e) => setOpValue11(e.target.value)}
                                        ></Form.Check>
                                        {/* <Form.Check
                                            type='checkbox'
                                            label="Asperger's Syndrome"
                                            name='opdValue12'
                                            value={opdValue12}
                                            className='mr-2'
                                            onChange={(e) => setOpValue12(e.target.value)}
                                        ></Form.Check> */}
                                        <Form.Check
                                            type='checkbox'
                                            label='Temporary disability after illness or accident'
                                            name='opdValue13'
                                            value={opdValue13}
                                            className='mr-2'
                                            onChange={(e) => setOpValue13(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Speech, language and communication needs '
                                            name='opdValue14'
                                            value={opdValue14}
                                            className='mr-2'
                                            onChange={(e) => setOpValue14(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Other physical disabilities'
                                            name='opdValue15'
                                            value={opdValue15}
                                            className='mr-2'
                                            onChange={(e) => setOpValue15(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Medical condition e.g. epilepsy, asthma, diabetes'
                                            name='opdValue16'
                                            value={opdValue16}
                                            className='mr-2'
                                            onChange={(e) => setOpValue16(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Other learning difficulties'
                                            name='opdValue17'
                                            value={opdValue17}
                                            className='mr-2'
                                            onChange={(e) => setOpValue17(e.target.value)}
                                        ></Form.Check>
                                        <Form.Check
                                            type='checkbox'
                                            label='Other disabilities'
                                            name='opdValue18'
                                            value={opdValue18}
                                            className='mr-2'
                                            onChange={(e) => setOpValue18(e.target.value)}
                                        ></Form.Check>
                                    </Form.Group>
                                </>
                            )}
                            <Form.Group controlId='wheelchair'>
                                <Form.Label>Are you a wheelchair user? </Form.Label>
                                <Form.Control
                                    as='select'

                                    value={wheelchair}
                                    onChange={(e) => setWheelchair(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='firstLang'>
                                <Form.Label>is English your first language?</Form.Label>
                                <Form.Control
                                    as='select'

                                    value={firstLang}
                                    onChange={(e) => setFirstLang(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='nationality'>
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={nationality}
                                    onChange={(e) => setNationality(e.target.value)}
                                >
                                    <option disabled selected value="">[Please select one]</option>
                                    <option value="afghan">Afghan</option>
                                    <option value="albanian">Albanian</option>
                                    <option value="algerian">Algerian</option>
                                    <option value="american">American</option>
                                    <option value="andorran">Andorran</option>
                                    <option value="angolan">Angolan</option>
                                    <option value="antiguans">Antiguans</option>
                                    <option value="argentinean">Argentinean</option>
                                    <option value="armenian">Armenian</option>
                                    <option value="australian">Australian</option>
                                    <option value="austrian">Austrian</option>
                                    <option value="azerbaijani">Azerbaijani</option>
                                    <option value="bahamian">Bahamian</option>
                                    <option value="bahraini">Bahraini</option>
                                    <option value="bangladeshi">Bangladeshi</option>
                                    <option value="barbadian">Barbadian</option>
                                    <option value="barbudans">Barbudans</option>
                                    <option value="batswana">Batswana</option>
                                    <option value="belarusian">Belarusian</option>
                                    <option value="belgian">Belgian</option>
                                    <option value="belizean">Belizean</option>
                                    <option value="beninese">Beninese</option>
                                    <option value="bhutanese">Bhutanese</option>
                                    <option value="bolivian">Bolivian</option>
                                    <option value="bosnian">Bosnian</option>
                                    <option value="brazilian">Brazilian</option>
                                    <option value="british">British</option>
                                    <option value="bruneian">Bruneian</option>
                                    <option value="bulgarian">Bulgarian</option>
                                    <option value="burkinabe">Burkinabe</option>
                                    <option value="burmese">Burmese</option>
                                    <option value="burundian">Burundian</option>
                                    <option value="cambodian">Cambodian</option>
                                    <option value="cameroonian">Cameroonian</option>
                                    <option value="canadian">Canadian</option>
                                    <option value="cape verdean">Cape Verdean</option>
                                    <option value="central african">Central African</option>
                                    <option value="chadian">Chadian</option>
                                    <option value="chilean">Chilean</option>
                                    <option value="chinese">Chinese</option>
                                    <option value="colombian">Colombian</option>
                                    <option value="comoran">Comoran</option>
                                    <option value="congolese">Congolese</option>
                                    <option value="costa rican">Costa Rican</option>
                                    <option value="croatian">Croatian</option>
                                    <option value="cuban">Cuban</option>
                                    <option value="cypriot">Cypriot</option>
                                    <option value="czech">Czech</option>
                                    <option value="danish">Danish</option>
                                    <option value="djibouti">Djibouti</option>
                                    <option value="dominican">Dominican</option>
                                    <option value="dutch">Dutch</option>
                                    <option value="east timorese">East Timorese</option>
                                    <option value="ecuadorean">Ecuadorean</option>
                                    <option value="egyptian">Egyptian</option>
                                    <option value="emirian">Emirian</option>
                                    <option value="equatorial guinean">Equatorial Guinean</option>
                                    <option value="eritrean">Eritrean</option>
                                    <option value="estonian">Estonian</option>
                                    <option value="ethiopian">Ethiopian</option>
                                    <option value="fijian">Fijian</option>
                                    <option value="filipino">Filipino</option>
                                    <option value="finnish">Finnish</option>
                                    <option value="french">French</option>
                                    <option value="gabonese">Gabonese</option>
                                    <option value="gambian">Gambian</option>
                                    <option value="georgian">Georgian</option>
                                    <option value="german">German</option>
                                    <option value="ghanaian">Ghanaian</option>
                                    <option value="greek">Greek</option>
                                    <option value="grenadian">Grenadian</option>
                                    <option value="guatemalan">Guatemalan</option>
                                    <option value="guinea-bissauan">Guinea-Bissauan</option>
                                    <option value="guinean">Guinean</option>
                                    <option value="guyanese">Guyanese</option>
                                    <option value="haitian">Haitian</option>
                                    <option value="herzegovinian">Herzegovinian</option>
                                    <option value="honduran">Honduran</option>
                                    <option value="hungarian">Hungarian</option>
                                    <option value="icelander">Icelander</option>
                                    <option value="indian">Indian</option>
                                    <option value="indonesian">Indonesian</option>
                                    <option value="iranian">Iranian</option>
                                    <option value="iraqi">Iraqi</option>
                                    <option value="irish">Irish</option>
                                    <option value="israeli">Israeli</option>
                                    <option value="italian">Italian</option>
                                    <option value="ivorian">Ivorian</option>
                                    <option value="jamaican">Jamaican</option>
                                    <option value="japanese">Japanese</option>
                                    <option value="jordanian">Jordanian</option>
                                    <option value="kazakhstani">Kazakhstani</option>
                                    <option value="kenyan">Kenyan</option>
                                    <option value="kittian and nevisian">Kittian and Nevisian</option>
                                    <option value="kuwaiti">Kuwaiti</option>
                                    <option value="kyrgyz">Kyrgyz</option>
                                    <option value="laotian">Laotian</option>
                                    <option value="latvian">Latvian</option>
                                    <option value="lebanese">Lebanese</option>
                                    <option value="liberian">Liberian</option>
                                    <option value="libyan">Libyan</option>
                                    <option value="liechtensteiner">Liechtensteiner</option>
                                    <option value="lithuanian">Lithuanian</option>
                                    <option value="luxembourger">Luxembourger</option>
                                    <option value="macedonian">Macedonian</option>
                                    <option value="malagasy">Malagasy</option>
                                    <option value="malawian">Malawian</option>
                                    <option value="malaysian">Malaysian</option>
                                    <option value="maldivan">Maldivan</option>
                                    <option value="malian">Malian</option>
                                    <option value="maltese">Maltese</option>
                                    <option value="marshallese">Marshallese</option>
                                    <option value="mauritanian">Mauritanian</option>
                                    <option value="mauritian">Mauritian</option>
                                    <option value="mexican">Mexican</option>
                                    <option value="micronesian">Micronesian</option>
                                    <option value="moldovan">Moldovan</option>
                                    <option value="monacan">Monacan</option>
                                    <option value="mongolian">Mongolian</option>
                                    <option value="moroccan">Moroccan</option>
                                    <option value="mosotho">Mosotho</option>
                                    <option value="motswana">Motswana</option>
                                    <option value="mozambican">Mozambican</option>
                                    <option value="namibian">Namibian</option>
                                    <option value="nauruan">Nauruan</option>
                                    <option value="nepalese">Nepalese</option>
                                    <option value="new zealander">New Zealander</option>
                                    <option value="ni-vanuatu">Ni-Vanuatu</option>
                                    <option value="nicaraguan">Nicaraguan</option>
                                    <option value="nigerien">Nigerian</option>
                                    <option value="north korean">North Korean</option>
                                    <option value="northern irish">Northern Irish</option>
                                    <option value="norwegian">Norwegian</option>
                                    <option value="omani">Omani</option>
                                    <option value="pakistani">Pakistani</option>
                                    <option value="palauan">Palauan</option>
                                    <option value="panamanian">Panamanian</option>
                                    <option value="papua new guinean">Papua New Guinean</option>
                                    <option value="paraguayan">Paraguayan</option>
                                    <option value="peruvian">Peruvian</option>
                                    <option value="polish">Polish</option>
                                    <option value="portuguese">Portuguese</option>
                                    <option value="qatari">Qatari</option>
                                    <option value="romanian">Romanian</option>
                                    <option value="russian">Russian</option>
                                    <option value="rwandan">Rwandan</option>
                                    <option value="saint lucian">Saint Lucian</option>
                                    <option value="salvadoran">Salvadoran</option>
                                    <option value="samoan">Samoan</option>
                                    <option value="san marinese">San Marinese</option>
                                    <option value="sao tomean">Sao Tomean</option>
                                    <option value="saudi">Saudi</option>
                                    <option value="scottish">Scottish</option>
                                    <option value="senegalese">Senegalese</option>
                                    <option value="serbian">Serbian</option>
                                    <option value="seychellois">Seychellois</option>
                                    <option value="sierra leonean">Sierra Leonean</option>
                                    <option value="singaporean">Singaporean</option>
                                    <option value="slovakian">Slovakian</option>
                                    <option value="slovenian">Slovenian</option>
                                    <option value="solomon islander">Solomon Islander</option>
                                    <option value="somali">Somali</option>
                                    <option value="south african">South African</option>
                                    <option value="south korean">South Korean</option>
                                    <option value="spanish">Spanish</option>
                                    <option value="sri lankan">Sri Lankan</option>
                                    <option value="sudanese">Sudanese</option>
                                    <option value="surinamer">Surinamer</option>
                                    <option value="swazi">Swazi</option>
                                    <option value="swedish">Swedish</option>
                                    <option value="swiss">Swiss</option>
                                    <option value="syrian">Syrian</option>
                                    <option value="taiwanese">Taiwanese</option>
                                    <option value="tajik">Tajik</option>
                                    <option value="tanzanian">Tanzanian</option>
                                    <option value="thai">Thai</option>
                                    <option value="togolese">Togolese</option>
                                    <option value="tongan">Tongan</option>
                                    <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                                    <option value="tunisian">Tunisian</option>
                                    <option value="turkish">Turkish</option>
                                    <option value="tuvaluan">Tuvaluan</option>
                                    <option value="ugandan">Ugandan</option>
                                    <option value="ukrainian">Ukrainian</option>
                                    <option value="uruguayan">Uruguayan</option>
                                    <option value="uzbekistani">Uzbekistani</option>
                                    <option value="venezuelan">Venezuelan</option>
                                    <option value="vietnamese">Vietnamese</option>
                                    <option value="welsh">Welsh</option>
                                    <option value="yemenite">Yemenite</option>
                                    <option value="zambian">Zambian</option>
                                    <option value="zimbabwean">Zimbabwean</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>In which country do you normally live?</Form.Label>
                                <Form.Control
                                    as='select'
                                    required
                                    value={inWhichCountryDoYouLive}
                                    onChange={(e) => setInWhichCountryDoYouLive(e.target.value)}
                                >
                                    <option selected disabled value="">Please Select One</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Aland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                                    <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo - Brazzaville</option>
                                    <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curacao">Curaçao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czechia</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="England">England</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                                    <option value="Holy See (Vatican City State)">Vatican City</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">North Korea</option>
                                    <option value="Korea, Republic of">South Korea</option>
                                    <option value="Kosovo">Kosovo</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia</option>
                                    <option value="Moldova, Republic of">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar (Burma)</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Curaçao</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn Islands</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Réunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Barthelemy">St. Barthélemy</option>
                                    <option value="Saint Helena">St. Helena</option>
                                    <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                                    <option value="Saint Lucia">St. Lucia</option>
                                    <option value="Saint Martin">St. Martin</option>
                                    <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                                    <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Scotland">Scotland</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Serbia and Montenegro">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Sint Maarten">Sint Maarten</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                                    <option value="Swaziland">Eswatini</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syria</option>
                                    <option value="Taiwan, Province of China">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-Leste">Timor-Leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Vietnam</option>
                                    <option value="Whales">Whales</option>
                                    <option value="Virgin Islands, British">British Virgin Islands</option>
                                    <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                                    <option value="Wallis and Futuna">Wallis & Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Country of Birth</Form.Label>
                                <Form.Control
                                    as='select'
                                    required={(localStorage.getItem('countryOfBirth') === "true")}
                                    value={countryOfBirth}
                                    onChange={(e) => setCountryOfBirth(e.target.value)}
                                >
                                    <option selected value="">Please Select One</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Aland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua & Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
                                    <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo - Brazzaville</option>
                                    <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'Ivoire">Côte d’Ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curacao">Curaçao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czechia</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="England">England</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
                                    <option value="Holy See (Vatican City State)">Vatican City</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">North Korea</option>
                                    <option value="Korea, Republic of">South Korea</option>
                                    <option value="Kosovo">Kosovo</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia</option>
                                    <option value="Moldova, Republic of">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar (Burma)</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Curaçao</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn Islands</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Réunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Barthelemy">St. Barthélemy</option>
                                    <option value="Saint Helena">St. Helena</option>
                                    <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
                                    <option value="Saint Lucia">St. Lucia</option>
                                    <option value="Saint Martin">St. Martin</option>
                                    <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
                                    <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Scotland">Scotland</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Serbia and Montenegro">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Sint Maarten">Sint Maarten</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
                                    <option value="South Sudan">South Sudan</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
                                    <option value="Swaziland">Eswatini</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syria</option>
                                    <option value="Taiwan, Province of China">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-Leste">Timor-Leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad & Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Vietnam</option>
                                    <option value="Whales">Whales</option>
                                    <option value="Virgin Islands, British">British Virgin Islands</option>
                                    <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
                                    <option value="Wallis and Futuna">Wallis & Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='resident'>
                                <Form.Label>
                                    Have you been resident in the UK for the last 3 years?
                                </Form.Label>
                                <Form.Control
                                    as='select'

                                    value={resident}
                                    onChange={(e) => setResident(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option disabled value='No' >No</option>
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    Only those accepted who have livd in UK for last 3 years.
                                </Form.Text>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='statementScheme'>
                                <Form.Label>
                                    Have you obtained settled status under then EU Settlement Scheme?
                                </Form.Label>
                                <Form.Control
                                    as='select'

                                    value={statementScheme}
                                    onChange={handleChange5}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='nonEEACitizen'>
                                <Form.Label>
                                    Are you a non EEA citizen with permission from the UK government to live
                                    in the UK?
                                </Form.Label>
                                <Form.Control
                                    as='select'

                                    value={nonEEACitizen}
                                    onChange={handleChange4}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {showFileUpload && (
                                <>
                                    <Form.Group controlId='perField'>
                                        <Form.Label>
                                            What immigration permissions do you currently hold in the UK?
                                        </Form.Label>
                                        <Form.Control
                                            as='select'

                                            value={perField}
                                            onChange={(e) => setPerField(e.target.value)}
                                        >
                                            <option disabled selected value=''>
                                                [Please select one]
                                            </option>
                                            <option value='Asylum Seeker'>Asylum Seeker</option>
                                            <option value='Indefinite Leave'>Indefinite Leave</option>
                                            <option value='Exceptional / Discretionary Leave'>
                                                Exceptional / Discretionary Leave
                                            </option>
                                            <option value='Humanitarian Protection'>
                                                Humanitarian Protection
                                            </option>
                                            <option value='Refugee'>Refugee</option>
                                            <option value='Famile Member of EU citizen'>
                                                Famile Member of EU citizen
                                            </option>
                                            <option value='British/EU/EEA/Citizen '>
                                                British/EU/EEA/Citizen{' '}
                                            </option>
                                        </Form.Control>
                                        <Form.Control.Feedback type='invalid'>
                                            Please fill the required field.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </>
                            )}
                            <Form.Group controlId='criminalConv'>
                                <Form.Label>
                                    Do you have any criminal convictions, cautions or pending prosecutions?
                                </Form.Label>
                                <Form.Control

                                    as='select'
                                    value={criminalConv}
                                    onChange={(e) => setCriminalConvictions(e.target.value)}
                                >
                                    <option disabled selected value=''>
                                        [Please select one]
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    }
                    {(currentStep > 0 && currentStep < 6) && <><Button
                        onClick={() => {
                            setStep(currentStep + 1);
                            scrollTtoView();
                        }}
                        className='next-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'right'
                        }}
                    >
                        {' '}
                        Next
                    </Button></>}
                    {(currentStep === 6) && <><Button
                        onClick={(e) => submitHandler(e)}
                        className='next-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'right'
                        }}
                    >
                        {' '}
                        Submit
                    </Button></>}
                    {(currentStep > 1 && currentStep < 16) && <><Button
                        onClick={() => {
                            setStep(currentStep - 1);
                            scrollTtoView();
                        }}
                        className='back-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'left'
                        }}
                    >
                        {' '}
                        Back
                    </Button></>
                    }
                </Form>
            </FormContainer>
        </>
    )

}

export default CallCenterFroms