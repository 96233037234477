import React from 'react'
import { toast } from 'react-toastify'
import * as admin from '../services/adminService'
import MaterialTable from 'material-table'
import { deleteContracts } from '../actions/formActions'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

toast.configure()
const Contracts = ({ history }) => {
	const [contracts, setContracts] = React.useState([])
	const sign = '\u00A3'
	const getContractData = () => {
		admin
			.getContracts()
			.then((res) => {
				setContracts(res.data.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	React.useEffect(getContractData, [])


	

    const handleDelete = async (id) =>{
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to delete this application?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: () => {
                    deleteContracts(id)
                    let prevForms = contracts
                    setContracts((prevForms.filter(x=> x._id !== id)).reverse())
            }
            }
            ]
          });
    }
	return (
		<div className='container'>
            <br/><br/>
            <Button
                className='button button-group-download'
                onClick={() => {
                    history.push('/add-contract')
                }}
            >
                Add new
            </Button>
            <br/><br/> 
			{contracts.length == 0 ? (
				<p>There are no Contracts</p>
			) : (
                <>
                <div>
                <MaterialTable
                    title="Contracts list"
                    data={contracts.reverse()}
                    columns={[
                        { title: 'Name', field: 'name',editable: 'never' },
                        { title: 'College', field: 'collegeName',editable: 'never'},
                        { title: 'Active', field: 'isActive',lookup: {true:"Yes",false:"No"} },
                        { title: 'Priority', field: 'priority',lookup: {'1':"Normal",'2':"High",'3':"Top"} },
                        { title: 'Contract Amount', field: 'Amount'},
                        { title: 'Price', field: 'pricePerApp',editable: 'never'},
                        { title: 'Contract Value', field: 'contractValue'},
                        { title: 'Delievered', field: 'formsDelievered' },
                        { title: 'Remaining', field: 'remainingForms' },
                        { title: 'Revenue', field: 'revenue',editable: 'never'},
                        { title: 'Reports', field: 'reports',editable: 'never',lookup: {true:"Yes",false:"No"}},
                    ]} 
                    detailPanel={rowData => {
                        return (
                          <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                   
                                    <p><b>Start Date: </b>{rowData.startDate}</p>
                                    <p><b>End Date: </b>{rowData.endDate}</p>
                                    <p><b>Accepted Area Codes: </b>{rowData.whitelist.toString()}</p>
                                    <p><b>Notes: </b>{rowData.notes}</p>
                                </div>
                                <div  className='col-md-6'>
                                    <p><b>Accepted Courses</b></p>
                                    <ul>
                                        {rowData.coursesList.map(x=><li>{x}</li>)}
                                    </ul>
                                </div>
                            </div>
                          </div>
                        )
                      }}
                    options={{
                        pageSize:10,
                        filtering: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip:'Edit',
                            onClick: (event, rowData) => {history.push('/edit-contract/?id=' + rowData._id, rowData)}
                        }),
                        {
                            icon: 'delete',
                            isFreeAction: false,
                            tooltip: 'Delete Application',
                            onClick: (event, rowData) => {
                                handleDelete(rowData._id)
                            }
                        }

                    ]}
                        

                />
                </div>
				
            </>)}
            <br/><br/>
		</div>
	)
}

export default Contracts