import React, { useState, useEffect } from 'react'
import { CDataTable , CButton } from '@coreui/react'
import emailConfirmation from '../../services/EmailService'
import sms from '../../services/SmsService'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getCallCenterFormsForUser,getAllCallCenterForms } from '../../actions/formActions'


const CallCenterAllFormsList = () => {
	let [forms, setForms] = React.useState([])

	useEffect(async() => {
        let res
        if (localStorage.getItem('callCenterID') === null){
            res = await getAllCallCenterForms()
        }
        else{
            res = await getCallCenterFormsForUser(localStorage.getItem('callCenterID'))
        }
            // console.log(res)
            const a = (res).map((item) => {
                return {
                    ...item,
                    Name: item.personalDetails.firstName + ' ' + item.personalDetails.lastName,
                    email: item.personalDetails.email,
                    appliedCourse: item.detailsFormData.appliedCourse,
                    phone: item.personalDetails.telephone,
                    createdAt: item.createdAt
                }
            })
			setForms(a.reverse())
	}, [])
    // console.log(forms);
	const fields = [
		{ key: 'Name' },
		'email',
		'appliedCourse',
		'phone',
		'createdAt',
        {
            key: 'Status',
            label: 'Status',
            _style: { width: '10%' },
            sorter: false,
            filter: false,
        },
        {
            key: 'sendemail',
            label: '',
            _style: { width: '10%' },
            sorter: false,
            filter: false,
        },
	]
    const onSendProofLink = (formId, email, number) => {
        emailConfirmation.sendProofEmail(
			email,
			'Last Step',
			`
            Thanks for your interest in the funded Level 2 courses.<br />
            Funded places are limited so please use the below link to complete your application.<br/>
            <br />
            <b>Complete your application in 3 simple steps.</b><br />
            1- Click the following link:<br />
            <a href="${window.location.origin}/email_completion_attachment?form_id=${formId}">${window.location.origin}/email_completion_attachment?form_id=${formId}</a> <br/>
            2- Upload your proofs and sign online<br />
            3- Press Submit to send you application<br />
            <br/> 
            Once your application is received it will be passed to the college enrolment team to complete your enrolment and give you access to your chosen course.<br />
            We hope you enjoy your course and if you need any further help, please email our support team - support@myfreecourse.co.uk.<br />
            <br />Thanks,<br />
            The My Free Course Team<br />
            Website: www.myfreecourse.co.uk<br />
        `
		)
        // console.log(number)
        sms.sendSms(
			number,
            `
            Thanks for your interest in the funded course. Places on your chosen course are limited so please complete your application to avoid disappointment.
            1- Click the following link: ${window.location.origin}/email_completion_attachment?form_id=${formId}
            2- Complete the online questionnaire 
            3- Upload your proofs and submit
            The application takes minutes and you could start your course within a few days.
            Thanks,
            The My Free Course Team
        `
		)
		toast('Mail and Text Sent')
	}



	return (
		<div className='container incomplete-container'>
            
            
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
					<CDataTable
						items={forms}
						fields={fields}
						columnFilter
						tableFilter
						itemsPerPageSelect
						itemsPerPage={50}
						hover
						sorter
						pagination                    
    					scopedSlots={{
                            Status: (item, index) => {
                                return (
									<td className='py-2'>
                                        {item.status && <>
                                            <SelectStatus
                                                status={item.status}
                                                formId={item._id}
                                            />
                                        </>}
                                        
									</td>
								)
                            },
                            sendemail: (item, index) => {
                                return (
                                    <td className='py-2'>
                                        <CButton
                                            color='primary'
                                            variant='outline'
                                            shape='square'
                                            size='sm'
                                            onClick={async (e) => {
												e.preventDefault()
												onSendProofLink(item._id, item.personalDetails.email, item.personalDetails.telephone)
											}}
                                        >
                                            Send Link
                                        </CButton>
                                        <CButton
                                                color='primary'
                                                variant='outline'
                                                shape='square'
                                                style={{marginTop:"5px"}}
                                                size='sm'
                                                onClick={() => {navigator.clipboard.writeText("myfreecourse.co.uk/email_completion_attachment?form_id="+item._id)}}>Copy</CButton>
                                            
                                    </td>
                                )
                            }
                        }}
					/>
				</div>
			)}
		</div>
	)
}


const SelectStatus = ({ status, formId }) => {
	const [selectedStatus, setSelectedstatus] = useState(status)
    // console.log(appliedCourse);
	return (
		<select
            value={selectedStatus}
			onChange={async (e) => {
				setSelectedstatus(e.target.value)
				await axios.post(process.env.REACT_APP_API_URL + '/formsbycall/change_status', {
					formId: formId,
					status: e.target.value,
				})
				window.location.reload()
			}}

		>
            <option selected disabled value=''>[Please Select One]</option>
            <option value='pending'>Pending</option>
            <option value='Called (CB)'>Called (CB)</option>
            <option value='Will complete'>Will complete</option>
            <option value='Sent link'>Sent link</option>
            <option value='Not interested '>Not interested </option>
            <option value='Wrong number'>Wrong number</option>	
		</select>
	)
}

export default CallCenterAllFormsList
