import React, { useEffect, useState } from 'react'
import StudentFormData from '../common/StudentFormData'
import Table from "react-bootstrap/Table";
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { getCompleteUserFormDetails } from '../actions/formActions';
import axios from 'axios';


const GetStudentData = () => {
    const location = useLocation();
    
	const [products, setProducts] = useState({})
	const [allColleges, setAllColleges] = useState([])
	const [courses, setCourses] = useState([])

	let a = window.location.search
	let b = new URLSearchParams(a).get('id')
    const getUserType = () => {
        if (!localStorage.getItem('collegeID') && !localStorage.getItem('callCenterID')) {
            return 'admin'
        }
    }

        useEffect(async() => {
            
        if (location.state){
            setAllColleges(location.state.Contracts)
            setCourses(location.state.courses)
            setProducts(location.state.data)
        } else{ 

                let contracts = await axios.get(process.env.REACT_APP_API_URL + `/contracts/all-for-studentsList`)
                console.log("contracts",contracts)
			    setAllColleges(contracts.data.data)

                let form = await axios.get(process.env.REACT_APP_API_URL + `/form/get_form_by_id/${b}`)
                console.log("form",form)
			    setProducts(form.data.form)

                
                let response = await axios.get(process.env.REACT_APP_API_URL + '/course/all')
                console.log("response",response)
                setCourses(response.data.data)
        }
        }, [])

	return (
		<div>
            {/* <div className='container' >
                {!localStorage.getItem('collegeID') && <>
                    <Button 
                style={{float:"right"}}
                    className='button button-group-download'
                    onClick={async()=>{
                        await getCompleteUserFormDetails(b)
                        window.location.href = '/edit/personal'
                    }}>Edit this Application</Button>
                    </>}
                
            </div> */}
			<div className='container' style={{minWidth:'1400px'}}>
				{courses.length>0 && (
					<div>
						<StudentFormData product={products} college={allColleges} courses={courses} />
					</div>
				)}
                <br/><br/>
                {getUserType()==='admin' && 
                <Table striped bordered className="table-show-data">
                    <tr>
                        <td>
                            English Test RNN college:
                        </td>
                        <td>
                            {"https://www.myfreecourse.co.uk/english-assessments/rnn?form_id="+b}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            English Test 2 (15 Questions):
                        </td>
                        <td>
                            {"https://www.myfreecourse.co.uk/english-assessment?form_id="+b}
                        </td>
                    </tr>
                </Table>
                }
			</div>
		</div>
	)
}

export default GetStudentData
