import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import auth from '../services/adminService'

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
    let callcenter= ['/callcenterhome','/incompleteForms-cc','/add-form-callcenter','/all-forms-callcenter','/update/qualification','/update/oppurtunities','/update/employment','/update/declaration','/update/personal','/update/emergencydetails']
	let supportStaff=['/failedStep1','/database-incompleteForms','/approvedForms','/all-attachment-links','/add-attachment-link','/studentslist','/allStudents','/incompleteForms','/trash','/incomplete-trash','/show-data','/edit/personal','/edit/emergencydetails','/edit/employment','/edit/qualification','/edit/oppurtunities','/edit/declaration','/edit/proof']
    if ((localStorage.getItem('callCenterID')==="6332fe9546a8ac00160bbf80")){
        callcenter.push('/all-callcenter-staff')
    }
    if ((localStorage.getItem('supportID')==="633a9edd7392990016b86705")){
        supportStaff.push('/all-courses')
        supportStaff.push('/addcourse')
        supportStaff.push('/edit-course')
    }
    
    if (localStorage.getItem('collegeID')) {
		if (path === '/collegehome' || path === '/studentslist' || path === '/show-data'|| path === '/college-complete-signup' || path==='/new-contract') {
			return (
				<Route
					{...rest}
					render={(props) => {
						return Component ? <Component {...props} /> : render(props)
					}}
				/>
			)
		} else {
			return <Redirect to='/collegehome' />
		}
	} else if(localStorage.getItem('supportID')){
        if (supportStaff.includes(path)) {
			return (
				<Route
					{...rest}
					render={(props) => {
						return Component ? <Component {...props} /> : render(props)
					}}
				/>
			)
		} else {
			return <Redirect to='/studentslist' />
		}
    } else if (localStorage.getItem('callCenterID')) {
		if (callcenter.includes(path)) {
			return (
				<Route
					{...rest}
					render={(props) => {
						return Component ? <Component {...props} /> : render(props)
					}}
				/>
			)
		} else {
			return <Redirect to='/incompleteForms-cc' />
		}
	} else {
		return (
			<Route
				{...rest}
				render={(props) => {
					if (!auth.getCurrentUser()) return <Redirect to='/admin' />
					return Component ? <Component {...props} /> : render(props)
				}}
			/>
		)
	}
}

export default ProtectedRoute
