import React from "react";
import DetailedCourse from "../common/DetailedCourse";
import * as auth from "../services/authService";
import { Container, Row, Col } from "react-bootstrap";
const Details = () => {
  let a = window.location.search;
  let b = new URLSearchParams(a).get("id");
    console.log(b)
  const [products, setProducts] = React.useState([]);

  const getData = () => {
    auth
      .getCourse()
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //getData();
  React.useEffect(getData, []);
  return (
    <div>
      {products.length === 0 ? (
        <p>There are no Products</p>
      ) : (
        <div style={{ color: "white" }}>
          {products.data
            .filter((x) => x.courseTitle === b)
            .map((product, index) => (
              <div>
                <DetailedCourse key={index} product={product} />
              </div>
            ))}
        </div>
      )}
      <section className="services section-bg" id="partners" style={{borderTop: "1px solid #eaeaea",marginTop: "30px",paddingTop: "0px"}}>
      <div className="container">
        <div className="row social-logo-section1" >
            <div className="col">
                <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{width:"100%"}} alt="" />
            </div>
        </div>
        <center>
            <div className="row social-logo-section only-on-mobile" >
                <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
            </div>
        </center>
        </div>

    </section>
    </div>
    
  );
};

export default Details;
