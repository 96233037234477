import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ScrollToMount from '../../common/ScrollToMount'
import FormContainer from './FormContainer'
import { getUserDetails, savePersonalDetails } from '../../actions/formActions'
import FormCompletetionSteps from './FormCompletetionSteps'
import { toast } from 'react-toastify'
const SecondForm = ({ history }) => {
	const form = useSelector((state) => state.form)
	const form1 = useSelector((state) => state.userDetails)
	const { formDetailloading, error, s } = form1
	const { personalDetails , detailsFormData } = form

	const [title, setTitle] = useState(personalDetails.title)
	const [firstName, setFirstName] = useState(personalDetails.firstName)
	const [lastName, setLastName] = useState(personalDetails.lastName)
	const [gender, setGender] = useState(personalDetails.gender)
	const [dob, setDOB] = useState(personalDetails.dob)
	const [addLine1, setAdd] = useState(personalDetails.addLine1)
	const [age, setAge] = useState(personalDetails.age)
	const [city, setCity] = useState(personalDetails.city)
    const [oldPostcode,setOldPostcode] = useState(personalDetails.oldPostcode)
	const [county, setCounty] = useState(personalDetails.county)
	const [postcode, setPostcode] = useState(personalDetails.postcode)
	const [yearsAtAdd, setYears] = useState(personalDetails.yearsAtAdd)
	const [telephone, setTel] = useState(personalDetails.telephone)
	const [email, setEmail] = useState(personalDetails.email)
	const [nationalInsNo, setNIN] = useState(personalDetails.nationalInsNo)

	const [validated, setValidated] = useState(false)

	const dispatch = useDispatch()
    
    const checkPhone =(phone)=>{
        if(phone.toString().length===11){
            return true
        } else {
            return false
        }
    }


	const submitHandler = (e) => {
		const form = e.currentTarget
        if (checkPhone(telephone)===false){
            toast.error("Only 11 characters allowed in Phone number")
			e.preventDefault()
			e.stopPropagation()
        }
		else if(form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
			var element = document.getElementById('Error-msg-form')
			element.classList.remove('Error-msg-form-displayNone')
			element.classList.add('Error-msg-form-display')
			document.getElementById('Error-msg-form').scrollIntoView({
				behavior: 'smooth',
			})
		} else {
			e.preventDefault()
			dispatch(
				savePersonalDetails({
					title,
					firstName,
					lastName,
					gender,
					dob,
                    oldPostcode,
					addLine1,
					age,
					city,
					county,
					postcode,
					yearsAtAdd,
					telephone,
					email,
					nationalInsNo,
				})
			)
			history.push('/emergencydetails')
		}

		setValidated(true)
	}

	return (
		<>
			<div className='form-jumbotron'>
				<h3>LEARNER APPLICATION FORM</h3>
			</div>
			<div className='jumbotron text-center splash top-personal'>
				<h2 className='heading-jumbotron-p'>CONGRATULATIONS</h2>
				<h5>You qualify for a funded online course</h5>
			</div>
			<center>
				<div className='container only-on-mobile'>
					<img
						src='https://res.cloudinary.com/my-free-course/image/upload/v1641932783/Site-pics/personal_page_images_nocdmn.png'
						alt=''
					/>
					
				</div>
			</center>
			<FormContainer>
				<ScrollToMount />
				<FormCompletetionSteps step1 step2 />

				<h1>Step 2: Start your application below</h1>
				<br />
				<div
					className='text-center mb-5 Error-msg-form Error-msg-form-displayNone'
					id='Error-msg-form'
				>
					<p>Please fix one or more fields.</p>
				</div>
				<Form noValidate validated={validated} onSubmit={submitHandler}>
                <div className='text-left mt-5 mb-3 form-notice top-personal-box'>
						<p>
                        Spaces are limited and are on a first come first serve basis. Please complete your application to
secure your place, your application progress will automatically be saved so you can come back to
the form at any time.<br/><br/>
Access your funded course in 3 steps:<br/>

<b>Step 1:</b> Complete your online application<br/>
<b>Step 2:</b> We check our application to make sure you qualify for the funding<br/>
<b>Step 3:</b> The college will contact you to complete your enrolment{' '}
						</p>
					</div>
					<Form.Group controlId='title'>
						<Form.Label>Title </Form.Label>
						<Form.Control
							as='select'
							required
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Mr'>Mr</option>
							<option value='Miss'>Miss</option>
							<option value='Ms'>Ms</option>
							<option value='Mx'>Mx</option>
							<option value='Mrs'>Mrs</option>
							<option value='other'>Other</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId='gender'>
						<Form.Label>Gender </Form.Label>
						<Form.Control
							as='select'
							required
							value={gender}
							onChange={(e) => setGender(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Female'>Female</option>
							<option value='Male'>Male</option>
						</Form.Control>
					</Form.Group>

					<Form.Group controlId='dob'>
						<Form.Label>Date Of Birth</Form.Label>
						<Form.Control
							type='date'
							required
							value={dob}
							onChange={(e) => setDOB(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='age'>
						<Form.Label>Age</Form.Label>
						<Form.Control
							type='number'
							required
							value={age}
							onChange={(e) => setAge(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='addLine1'>
						<Form.Label>Address Line</Form.Label>
						<Form.Control
							type='text'
							required
							value={addLine1}
							onChange={(e) => setAdd(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='city'>
						<Form.Label>City</Form.Label>
						<Form.Control
							type='text'
							required
							value={city}
							onChange={(e) => setCity(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId='county'>
						<Form.Label>County</Form.Label>
						<Form.Control
							type='text'
							required
							value={county}
							onChange={(e) => setCounty(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='yearsAtAdd'>
						<Form.Label>Years at Address</Form.Label>
						<Form.Control
							type='number'
							required
							value={yearsAtAdd}
							onChange={(e) => setYears(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
                    {(yearsAtAdd<3)&&<>
                        <Form.Group controlId='PostId'>
						<Form.Label>Previous Home postcode </Form.Label>
						<Form.Control
							type='text'
							required
							placeholder='Enter PostCode'
							value={oldPostcode}
							onChange={(e) => {
                                e.target.value = e.target.value.replace(/\s/g, '');
                                setOldPostcode(e.target.value.toUpperCase())
                            }}
						></Form.Control>
						<Form.Text className='text-muted'>
							No Special Charcters and no spaces.
						</Form.Text>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    </>}
					<Form.Group controlId='telephone'>
						<Form.Label>Telephone</Form.Label>
						<Form.Control
							type='number'
							required
							value={telephone}
							onChange={(e) => setTel(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId='nationalInsNo'>
						<Form.Label>National Insurance Number</Form.Label>
						<Form.Control
							type='text'
							required
							pattern='^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-dA-D]{1}?\s*$'
							value={nationalInsNo}
							onChange={(e) => setNIN(e.target.value.toUpperCase().trim())}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field / Please enter valid national insurance
							number
						</Form.Control.Feedback>
					</Form.Group>
					<div className='buttons-form'>
						<Button
							onClick={(e) => {
								window.location.href = '/form/'+detailsFormData.appliedCourse
							}}
							variant='primary'
							className='mr-5 back-Button-form'
						>
							Back
						</Button>

						<Button
							type='submit'
							variant='primary'
							style={{
								backgroundColor: 'green',
								borderColor: 'green',
								color: 'white',
							}}
							className='mrt next-Button-form'
						>
							Save Progress & Continue
						</Button>
					</div>
				</Form>
			</FormContainer>
		</>
	)
}

export default SecondForm
