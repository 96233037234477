import http from './httpService';

import jwtDecode from 'jwt-decode'

const apiPoint = "/admin/login"
const tokenKey = "token"

export async function adminlogin(email, password) {
    const { data: jwt } = await http.post(apiPoint, { email, password })
    localStorage.setItem(tokenKey, jwt.token);
    localStorage.setItem("user", jwt.data[0].name)
}

// get college Data
export async function getCollegeCount() {
    const point = "/admin/collegedata"
    return await http.get(point)
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);

        return jwtDecode(jwt);

    } catch (ex) {
        return null
    }
}
// get college Data
export async function getAllCourses() {
    const point = "/course/all"
    return await http.get(point)
}

const addCoursePoint = "/course/registerCourse"

export function addCourse(data) {
    return http.post(addCoursePoint, data)
}

// update college data
export function updateCollegeInfo(
    id,
    collegeName,
    email,
    password,
    // coursesList, 
    // allCourses,
    employmentdetailsNecessary,
    // whitelist,
    proofs,
    // contractAmount,
    // pricePerApp,
    isActive,
    softEnglishTest,
    inWhichCountryDoYouLive,
    fullTimeEducationElse,
    englistAssessmentActive,
    reports,
    // priority,
    pdf,
    startDateEmployment,
    countryOfBirth,
    ifEmploymentType,
    ifAnualSalary) {
    const api = "/admin/college/" + id
    return http.patch(api, {
        collegeName,
        email,
        password,
        // coursesList, 
        // allCourses,
        employmentdetailsNecessary,
        // whitelist,
        proofs,
        // contractAmount,
        // pricePerApp,
        isActive,
        softEnglishTest,
        inWhichCountryDoYouLive,
        fullTimeEducationElse,
        englistAssessmentActive,
        reports,
        // priority,
        pdf,
        startDateEmployment,
        countryOfBirth,
        ifEmploymentType,
        ifAnualSalary
    })
}

// add college data
export function AddNewCollegeData(
    collegeName,
    email,
    password,
    // coursesList, 
    // allCourses,
    employmentdetailsNecessary,
    // whitelist,
    proofs,
    // contractAmount,
    // pricePerApp,
    isActive,
    softEnglishTest,
    inWhichCountryDoYouLive,
    fullTimeEducationElse,
    englistAssessmentActive,
    reports,
    // priority,
    pdf,
    startDateEmployment,
    countryOfBirth,
    ifEmploymentType,
    ifAnualSalary) {
    const api = "/college/add"
    return http.post(api, {
        collegeName,
        email,
        password,
        // coursesList, 
        // allCourses,
        employmentdetailsNecessary,
        // whitelist,
        proofs,
        // contractAmount,
        // pricePerApp,
        isActive,
        softEnglishTest,
        inWhichCountryDoYouLive,
        fullTimeEducationElse,
        englistAssessmentActive,
        reports,
        // priority,
        pdf,
        startDateEmployment,
        countryOfBirth,
        ifEmploymentType,
        ifAnualSalary
    })
}

// get contracts Data
export async function getContracts() {
    const point = "/contracts/all"
    return await http.get(point)
}

// add contract data
export function AddNewContract(
    contractName,
    coursesList,
    whitelist,
    contractAmount,
    pricePerApp,
    isActive,
    reports,
    notes,
    priority,
    startDate,
    endDate,
    collegeId
) {
    const api = process.env.REACT_APP_API_URL + "/contracts/add-contract"
    return http.post(api, {
        contractName,
        coursesList,
        whitelist,
        contractAmount,
        pricePerApp,
        isActive,
        reports,
        notes: notes,
        priority,
        startDate,
        endDate,
        collegeId
    })
}

// update contract data
export function updateContract(
    id,
    contractName,
    coursesList,
    whitelist,
    contractAmount,
    pricePerApp,
    isActive,
    reports,
    notes,
    priority,
    startDate,
    endDate,
    collegeId) {
    const api = "/contracts/edit/" + id
    return http.patch(api, {
        contractName,
        coursesList,
        whitelist,
        contractAmount,
        pricePerApp,
        isActive,
        reports,
        notes,
        priority,
        startDate,
        endDate,
        collegeId
    })
}


// add course data
export function AddNewCourse(
    courseTitle,
    courseDescription,
    shortDescription,
    courseBenefits,
    heading,
    courseContentarr,
    courseCode,
    status,
    courseLength,
    awardingBody,
    courseLevel,
    funding,
    learningMethods,
    img) {
    const api = "/course/registerCourse"
    return http.post(api, {
        courseTitle,
        courseDescription,
        shortDescription,
        courseBenefits,
        heading,
        courseContentarr,
        courseCode,
        status,
        courseLength,
        awardingBody,
        courseLevel,
        funding,
        learningMethods,
        img
    })
}




// edit course data
export function editCourse(
    id,
    courseTitle,
    courseDescription,
    shortDescription,
    courseBenefits,
    heading,
    courseContentarr,
    courseCode,
    status,
    courseLength,
    awardingBody,
    courseLevel,
    funding,
    learningMethods,
    img) {
    const api = "/course/edit/" + id
    return http.patch(api, {
        courseTitle,
        courseDescription,
        shortDescription,
        courseBenefits,
        heading,
        courseContentarr,
        courseCode,
        status,
        courseLength,
        awardingBody,
        courseLevel,
        funding,
        learningMethods,
        img
    })
}


// send email
export function sendEmail(email, subject, message) {
    return http.post('/form/email', { email, subject, message })
}


export default {
    adminlogin,
    getCurrentUser,
    addCourse,
    getCollegeCount,
    updateCollegeInfo,
    sendEmail
}



// add contract data by college tehmself
export function AddNewContractCollegeSelf(
    contractName,
    notes,
    coursesList,
    whitelist,
    monthlyApplication,
    startDate,
    contractLength,
    collegeID,
    nameOfRepresentative,
    position,
    invoicingEmail,
    signature,
    postcode,
    address,
    website,
    invoicingNumber,
    phone
) {
    const api = process.env.REACT_APP_API_URL + "/contracts/add-contract-by-college"
    return http.post(api, {
        contractName,
        notes,
        coursesList,
        whitelist,
        monthlyApplication,
        startDate,
        contractLength,
        collegeID,
        nameOfRepresentative,
        position,
        invoicingEmail,
        signature,
        postcode,
        address,
        website,
        invoicingNumber,
        phone
    })
}
