import React, { useEffect } from 'react'
import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import { getAttachmentLinkById, uploadsAttachmentLink } from '../../actions/attachmentLink'

const AttachmentLinkForUser = ({ history }) => {
	const urlParams = new URLSearchParams(history.location.search)
	const formId = urlParams.get('id')
	const [validated, setValidated] = useState(false)
    
    const [data,setData] = useState()
    const [Evidence,setEvidence] = useState()
    const [Evidence1,setEvidence1] = useState()
    const [Evidence2,setEvidence2] = useState()
    const [id,setID] = useState()
    const [id1,setID1] = useState()
    const [id2,setID2] = useState()
    const [id3,setID3] = useState()
    const [id4,setID4] = useState()
    const [loading,setLoading] = useState(false)
    const [otherProof,setOtherProof] = useState()
    useEffect(async()=>{
        if(urlParams.get('id')){
            let res = await getAttachmentLinkById(urlParams.get('id'))
            setData(res)
        } else {
            toast.error('Missing ID')
        }
    },[])
    
	const uploadImage = (file) => {
        setLoading(true)
		const data = new FormData()
		data.append('file', file)
		data.append('upload_preset', 'evidence')
		data.append('cloud_name', 'my-free-course')
		return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
			method: 'POST',
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
                setLoading(false)
				return res.url
			})
	}

    const submitHandler = async (e) => {
		const form = e.currentTarget
        
        if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
            try {
				e.preventDefault()
                let res = await uploadsAttachmentLink({
                    formId:data.formId,
                    otherProof:otherProof,
                    Evidence:Evidence,
                    Evidence1:Evidence1,
                    Evidence2:Evidence2,
                    id:id,
                    id1:id1,
                    id2:id2,
                    id3:id3,
                    id4:id4,
                    linkId: formId
                });
                if(res.status===201){
                    history.push('/thank-you')
                }
            } catch (err) {
                toast.error(err.message);
            }
		}
		setValidated(true)
    }
	return (
		<>
			<FormContainer>
				{/* <ScrollToMount /> */}
				<h1>
					<b>Missing Application Proofs</b>
				</h1>
                {data && <>
                    <br/><b>Note from Team:</b> {data.note}
                    <br/><b>Name:</b> {data.formData[0].personalDetails.title +' ' +data.formData[0].personalDetails.firstName + ' ' +data.formData[0].personalDetails.lastName}
                    <br/><b>Email:</b> {data.formData[0].personalDetails.email}
                    <br/><br/>
                    <Form noValidate validated={validated} onSubmit={submitHandler}>

                        {/* First Check */}
                        {(data.earningLessthan)&&<>
                            <br/><hr/><br/>
                            <Form.Group controlId='evidence'>
									<Form.Label><b>Proof of income</b></Form.Label>
									<Form.Control
										type='file'
                                        accept='image/*,application/pdf'
										required 
                                        onChange={async(e) => {
                                                setEvidence(await uploadImage(e.target.files[0]))
                                        }}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>
										Please upload required file.
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId='evidence1'>
									<Form.Label><b>Additional Evidence proof</b></Form.Label>
									<Form.Control
										type='file'
                                        accept='image/*,application/pdf'
                                        onChange={async(e) => {
                                            setEvidence1(await uploadImage(e.target.files[0]))
                                    }}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>
										Please upload required file.
									</Form.Control.Feedback>
								</Form.Group>
                        </>}

                        {(data.recieptBenefits) && <>
                            <br/><hr/><br/>

									<Form.Group controlId='evidence3'>
										<Form.Label><b>Proof of Benefits receipt</b></Form.Label>
										<Form.Control
											type='file'
                                            required
                                            accept='image/*,application/pdf'
											onChange={async(e) => {
                                                setEvidence2(await uploadImage(e.target.files[0]))
                                        }}
										></Form.Control>
										<Form.Control.Feedback type='invalid'>
											Please upload required file.
										</Form.Control.Feedback>
									</Form.Group>
                        </>}

                        {(data.settlementScheme) && <>
                            <br/><hr/><br/>
                            
                            <b>Have you obtained settled status under then EU Settlement Scheme?</b><br/><br/>
                            <Form.Group controlId='id1'>
                            <Form.Label className="form-notice">
                            <b>You obtained settled status under then EU Settlement Scheme?</b><br/><br/>
                                Providing proof of your residency status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided you will be deemed ineligible for funding and not permitted to access your chosen course.
                                <br/><br/>Please provide one of the below proof documents. Acceptable proof documents are:
                                <br/>
                                1- Picture of your residence card (BRP Card)
                                <br/>
                                2- Screenshot of your ‘share code’ from the government website below.
                                <br/><br/><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                <br/><br/><b>You will also need to provide proof of address dated within the last 3 months.</b>

								</Form.Label>
                                <Form.Label>
                                <b>Proof of residency status</b>
                                </Form.Label>
                                <Form.Control
                                    type='file'
                                    accept='image/*,application/pdf'
                                    required
                                    onChange={async(e) => {
                                        setID1(await uploadImage(e.target.files[0]))
                                    }}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please upload required file.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='id2'>
                                <Form.Label>
                                <b>Proof of address dated within last 3 months</b>
                                </Form.Label>
                                <Form.Control
                                    type='file'
                                    accept='image/*,application/pdf'
                                    onChange={async(e) => {
                                        setID2(await uploadImage(e.target.files[0]))
                                    }}
                                    ></Form.Control>
                            </Form.Group>
                        </>}

                        {data.permissionforUk && <>
                            <br/><hr/><br/>
                            <b>What immigration permissions do you currently hold in the UK?</b><br/><br/>
                            <Form.Label className="form-notice">
                            Providing proof of settled status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided you will be deemed ineligible for funding and not permitted to access your chosen course.
                                <br/><br/>Please provide one of the below proof documents. Acceptable proof documents are:
                                <br/>
                                1- Picture of your residence card (BRP Card)
                                <br/>
                                2- Screenshot of your ‘share code’ from the government website below.
                                <br/><br/><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                <br/><br/><b>You will also need to provide proof of address dated within the last 3 months.</b>

                                </Form.Label>
                        
                            <Form.Group controlId='id3'>
                            <Form.Label>
                            <b>Proof of residency status</b>
                            </Form.Label>
                            <Form.Control
                                type='file'
                                accept='image/*,application/pdf'
                                required
                                onChange={async(e) => {
                                    setID3(await uploadImage(e.target.files[0]))
                                }}
                                ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='id4'>
                            <Form.Label>
                            <b>Proof of address dated within last 3 months</b>
                            </Form.Label>
                            <Form.Control
                                type='file'
                                accept='image/*,application/pdf'
                                required
                                onChange={async(e) => {
                                    setID4(await uploadImage(e.target.files[0]))
                                }}></Form.Control>
                        </Form.Group>
                        </>}

                            {data.proof && <>
                            <br/><hr/><br/>
                            <b>Upload an ID proof?</b><br/><br/>
                                <Form.Group  id="file-upload-laststep">
                                    <Form.Control
                                        type='file'
                                        required
                                        accept='image/*,application/pdf'
                                        onChange={async(e) => {
                                            setID(await uploadImage(e.target.files[0]))
                                        }}
                                    ></Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Please fill in this required field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>}

                            {data.otherProof && <>
                            <br/><hr/><br/>
                                <Form.Group controlId='evidence'>
									<Form.Label><b>Other Proof</b></Form.Label>
									<Form.Control
										type='file'
                                        accept='image/*,application/pdf'
										required 
                                        onChange={async(e) => {
                                            setOtherProof(await uploadImage(e.target.files[0]))
                                        }}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>
										Please upload required file.
									</Form.Control.Feedback>
								</Form.Group>
                                <br/><br/><br/>
                            </>}

                        <Button
                            type='submit'
                            // onClick={submitHandler}
                            className='next-Button-form'
                            style={{
                                width: '300px',
                                color: 'white',
                            }}
                        >
                            Submit
                        </Button>
                    </Form>
			    </>}
            </FormContainer>
            

			{loading && <div id='spinner-finalstep' style={{ zIndex: '999' }}>
				<center>
					<Spinner animation='border' />
				</center>
			</div>}
		</>
	)
}

export default AttachmentLinkForUser