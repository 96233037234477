import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from './Form/FormContainer'
import { useParams } from 'react-router-dom'
import { saveTwinsLeadsForm } from '../actions/formActions'

const LeadsTwins = ({ history }) => {
    const { course } = useParams();
	const [title, setTitle] = useState()
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [dob, setDob] = useState()
	const [mobile, setMobile] = useState()
	const [address, setAddress] = useState()
	const [email, setEmail] = useState()
	const [postCode, setPostCode] = useState()
	const [preferredContactMethod, setPreferredContactMethod] = useState()
	const [preferredContactTime, setPreferredContactTime] = useState()
	const [nationalInsurance, setNationalInsurance] = useState()
	const [validated, setValidated] = useState(false)
    const [disabilities, setDisabilities] = useState();
    const [learningDifficulties, setLearningDifficulties] = useState();
    const [appliedCourse, setCourse] = useState(course);


	const dispatch = useDispatch()

	const submitHandler = (e) => {
		const form = e.currentTarget
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
            e.preventDefault()
            dispatch(
                saveTwinsLeadsForm({            
                    title,
                    firstName,
                    lastName,
                    dob,
                    mobile,
                    address,
                    email,
                    postCode,
                    preferredContactMethod,
                    preferredContactTime,
                    nationalInsurance,
                    disabilities,
                    learningDifficulties,
                    appliedCourse
                })
            )
            history.push('/thank-you')
		}
		setValidated(true)
	}

	return (
		<>
			<div className='form-jumbotron'>
				<h3>LEARNER APPLICATION FORM</h3>
			</div>
			<FormContainer>
				<div className='text-left mt-5 mb-3 form-notice'>
					<h1>Register your details for this course.</h1>
				</div>
				<Form noValidate validated={validated} onSubmit={submitHandler}>
                <Form.Group controlId='appliedCourse'>
						<Form.Label>
							<b>Which course are you applying for?</b>{' '}
						</Form.Label>
						<Form.Control
							as='select'
							required
							value={appliedCourse}
							onChange={(e) => setCourse(e.target.value)}
						>
							<option disabled value=''>[Please Select One]</option>
							<option value='Level 3 Certificate in Make-Up Artistry'>Level 3 Certificate in Make-Up Artistry</option>
							<option value='Level 3 Certificate in Bridal'>Level 3 Certificate in Bridal</option>
							<option value='Level 3 Certificate in Beauty Therapy Treatment'>Level 3 Certificate in Beauty Therapy Treatment</option>
                            </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                <Form.Group controlId='title'>
						<Form.Label>Title </Form.Label>
						<Form.Control
							as='select'
							required
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Mr'>Mr</option>
							<option value='Miss'>Miss</option>
							<option value='Ms'>Ms</option>
							<option value='Mx'>Mx</option>
							<option value='Mrs'>Mrs</option>
							<option value='other'>Other</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId='firstName'>
						<Form.Label>First Name </Form.Label>
						<Form.Control
							type='text'
							required
							placeholder='Enter First Name'
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId='lastName'>
						<Form.Label>Last Name </Form.Label>
						<Form.Control
							type='text'
							required
							placeholder='Enter Last Name'
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group controlId='dob'>
						<Form.Label>Date Of Birth</Form.Label>
						<Form.Control
							type='date'
							required
							value={dob}
							onChange={(e) => setDob(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='email'>
						<Form.Label>Email </Form.Label>
						<Form.Control
							type='email'
							required
							placeholder='Enter Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='mobile'>
						<Form.Label>Mobile </Form.Label>
						<Form.Control
							required
							type='number'
							placeholder='Enter Mobile No'
							value={mobile}
							onChange={(e) => setMobile(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
					<Form.Group controlId='address'>
						<Form.Label>Address</Form.Label>
						<Form.Control
							type='text'
							required
							value={address}
							onChange={(e) => setAddress(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='PostId'>
						<Form.Label>Post Code</Form.Label>
						<Form.Control
							type='text'
							required
							placeholder='Enter PostCode'
							value={postCode}
							onChange={(e) => setPostCode(e.target.value.toUpperCase())}
						></Form.Control>
						<Form.Text className='text-muted'>
							Please add a space between your postcode.
						</Form.Text>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group controlId='preferredContactMethod'>
						<Form.Label>Preferred Contact Method </Form.Label>
						<Form.Control
							as='select'
							required
							value={preferredContactMethod}
							onChange={(e) => setPreferredContactMethod(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='email'>Email</option>
							<option value='phone'>Phone</option>
							<option value='either phone or email'>Either</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <Form.Group controlId='preferredContactTime'>
						<Form.Label>Preferred Contact time </Form.Label>
						<Form.Control
							as='select'
							required
							value={preferredContactTime}
							onChange={(e) => setPreferredContactTime(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Morning'>Morning</option>
							<option value='Afternoon'>Afternoon</option>
							<option value='Evening'>Evening</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

                    <Form.Group controlId='nationalInsNo'>
						<Form.Label>National Insurance Number</Form.Label>
						<Form.Control
							type='text'
							required
							pattern='^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-dA-D]{1}?\s*$'
							value={nationalInsurance}
							onChange={(e) => setNationalInsurance(e.target.value.toUpperCase().trim())}
						></Form.Control>
                        <Form.Text className='text-muted'>
                        Your NI number is needed to make sure you are a genuine UK/EU citizen enabling you to qualify for course funding.
						</Form.Text>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field / Please enter valid national insurance
							number
						</Form.Control.Feedback>
					</Form.Group>

                    <Form.Group controlId='disabilities'>
						<Form.Label>Any Disabilities </Form.Label>
						<Form.Control
							as='select'
							required
							value={disabilities}
							onChange={(e) => setDisabilities(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Yes'>Yes</option>
							<option value='No'>No</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

                    
                    <Form.Group controlId='learningDifficulties'>
						<Form.Label>Any Learning Disabilities </Form.Label>
						<Form.Control
							as='select'
							required
							value={learningDifficulties}
							onChange={(e) => setLearningDifficulties(e.target.value)}
						>
							<option selected disabled value=''>
								[Please select one]
							</option>
							<option value='Yes'>Yes</option>
							<option value='No'>No</option>
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>

					<Button
						type='submit'
						variant='primary'
						style={{
							backgroundColor: 'green',
							borderColor: 'green',
							color: 'white',
						}}
					>
						Register
					</Button>
				</Form>
			</FormContainer>
		</>
	)
}

export default LeadsTwins
