import React, {  useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Bar , Line ,Pie} from 'react-chartjs-2'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const TITLE = 'Dashboard'

const style = {
	width: '20rem',
	margin: '5px',
	marginBottom: '30px',
    minHeight: '350px' 
}

const AdminLandingPage = () => {
	const [forms, setForms] = useState()
	const [incForms, setIncForms] = useState([])
	const [college, setCollege] = useState()
	const [perCollegeData, setCollegePerData] = useState(null)
	const [completedByAllRev, setCompletedByAllRev] = useState(null)
	const [dailyBarData, setDailyBarData] = useState()
    const [lineBar,setLineBar] = useState()
	const [incompleteBarData, setIncompleteBarData] = useState()
    const [incompleteStepsPie, setIncompleteStepsPie] = useState()
    const [collegeLabels,setCollegeLabels] = useState([])
    const [collegeCount,setCollegeCount] = useState([])
	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		},
	}

    const sumUp = (array) =>{
        let sum = 0
        for (let i=0; i < array.length;i++){
            sum = sum + array[i].count
        }
        return sum
      } 
    

	useEffect(async () => {
		// get data
		let formsData = await axios.get(process.env.REACT_APP_API_URL + '/form/')
		setForms(formsData.data)
        // console.log(formsData.data) 
		/*
			Daily Bar code comes here	
		*/
		let count = 0
		let arr = []
		const mappedData = formsData.data.data.map((d) => d.date)
		const result = [...new Set(mappedData)]
		for (let i = 0; i < result.length; i++) {
			for (let j = 0; j < formsData.data.data.length; j++) {
				if (result[i] === formsData.data.data[j].date) {
					count++
				}
			}
			arr.push(count)
			count = 0
		}
		const data = {
			labels: result,
			datasets: [
				{
					label: 'Daily Form Submissions',
					backgroundColor: '#36A2EB',
					borderColor: '#36A2EB',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(63,142,191,0.4)',
					hoverBorderColor: '#fff',
					barPercentage: 0.5,
					barThickness: 50,
					maxBarThickness: 100,
					data: arr,
				},
			],
		}
		setDailyBarData(data)

		let collegeData = await axios.get(process.env.REACT_APP_API_URL + '/contracts/all-for-studentsList')
		setCollege(collegeData.data)

		let incData = await axios.get(process.env.REACT_APP_API_URL + '/form/incompleteForms/')

		setIncForms(incData.data)
        let countData =[]
        let LabelData =[]
        let collegePieData = incData.data.CollegeAllocation
        collegePieData.sort((a,b)=>b.count-a.count)
        collegePieData.map(x=> {
            countData.push(x.count)
            LabelData.push(x._id)
        })
        setCollegeCount(countData)
        setCollegeLabels(LabelData)


        
        // console.log(incData.data)
        // Incomplete Steps code comes here

        const incompleteStepsData = {
            labels: ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', "Step 6", 'Step 7'],
            datasets: [
                {
                    label: 'Incomplete forms steps Yesterday',
                    backgroundColor: '#36A2EB',
                    data: [
                        (incData.data.yesterday).find((x)=> x._id==='Step 1') ?(incData.data.yesterday).find((x)=> x._id==='Step 1').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 2')?(incData.data.yesterday).find((x)=> x._id==='Step 2').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 3')?(incData.data.yesterday).find((x)=> x._id==='Step 3').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 4')?(incData.data.yesterday).find((x)=> x._id==='Step 4').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 5')?(incData.data.yesterday).find((x)=> x._id==='Step 5').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 6')?(incData.data.yesterday).find((x)=> x._id==='Step 6').count : 0,
                        (incData.data.yesterday).find((x)=> x._id==='Step 7')?(incData.data.yesterday).find((x)=> x._id==='Step 7').count : 0
                    ],
                  },
              {
                label: 'Incomplete forms steps Today ',
                backgroundColor: '#f87979',
                data: [
                    (incData.data.today).find((x)=> x._id==='Step 1') ?(incData.data.today).find((x)=> x._id==='Step 1').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 2')?(incData.data.today).find((x)=> x._id==='Step 2').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 3')?(incData.data.today).find((x)=> x._id==='Step 3').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 4')?(incData.data.today).find((x)=> x._id==='Step 4').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 5')?(incData.data.today).find((x)=> x._id==='Step 5').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 6')?(incData.data.today).find((x)=> x._id==='Step 6').count : 0,
                    (incData.data.today).find((x)=> x._id==='Step 7')?(incData.data.today).find((x)=> x._id==='Step 7').count : 0
                ],
              }
            ],
          }
          
          setIncompleteStepsPie(incompleteStepsData)

          

        // Incomplete Steps code ends here




		/*
			Incomplete Bar code comes here	
		*/
		let incCount = 0
		let incArr = []
		const incMappedData = incData.data.data.map((d) => d.dateCreated)
		const incFormResult = [...new Set(incMappedData)]
		for (let i = 0; i < incFormResult.length; i++) {
			for (let j = 0; j < incData.data.data.length; j++) {
				if (incFormResult[i] ===  incData.data.data[j].dateCreated) {
					incCount++
				}
			}
			incArr.push(incCount)
			incCount = 0
		}
        // console.log(incFormResult)
		const incompleteFormData = {
			labels: incFormResult,
			datasets: [
				{
					label: 'Incomplete Submissions',
					backgroundColor: '#f87979',
					borderColor: '#f87979',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(171,35,70,0.4)',
					hoverBorderColor: 'rgba(171,35,70,1)',
					barPercentage: 0.5,
					barThickness: 50,
					maxBarThickness: 100,

					data: incArr,
				},
			],
		}
		setIncompleteBarData(incompleteFormData)
        setLineBar({
            labels: incFormResult,
            datasets:[
                {
					label: 'Incomplete Submissions',
					// backgroundColor: '#f87979',
					borderColor: '#f87979',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(171,35,70,0.4)',
					hoverBorderColor: 'rgba(171,35,70,1)',
					barPercentage: 0.5,
					barThickness: 50,
					maxBarThickness: 100,

					data: incArr,
				}, {
					label: 'Daily Form Submissions',
					backgroundColor: '#36A2EB',
					borderColor: '#36A2EB',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(63,142,191,0.4)',
					hoverBorderColor: '#fff',
					barPercentage: 0.5,
					barThickness: 50,
					maxBarThickness: 100,
					data: arr,
				}
            ]
        })
        const activeCollege = (collegeData.data.data).filter((x)=> x.reports === true || x.isActive === true )
        console.log("activeCollege",activeCollege)
		var collegeMapper = []
		for (var i = 0; i < activeCollege.length; i++) {
			collegeMapper.push({
				id: activeCollege[i]._id,
				name: activeCollege[i].name,
				pricePerApp: activeCollege[i].pricePerApp,
				byDay: 0,
				byDayRev: 0,
                byDayExRev:0,
                
                byYesterday: 0,
				byYesterdayRev: 0,
                byYesterdayExRev:0,
                
				byWeek: 0,
				byWeekRev: 0,
                byWeekExRev:0,
                
				byLastWeek: 0,
				byLastWeekRev: 0,
                byLastWeekExRev:0,
                
				byLastMonth: 0,
				byLastMonthRev: 0,
                byLastMonthExRev:0,
                
				byMonth: 0,
				byMonthRev: 0,
                byMonthExRev:0,
                
				byAllTime: 0,
				byAllTimeRev: 0,
                byAllTimeExRev:0,
                
			})
		}
        console.log("asd",collegeMapper)
		for (var i = 0; i < collegeMapper.length; i++) {
			let data = await axios.post(
				process.env.REACT_APP_API_URL + '/contracts/get_forms_by_day',
				{
					collegeID: collegeMapper[i].id,
				}
			)
            console.log("data",data)
			collegeMapper[i].byDay = data.data.byDay
			collegeMapper[i].byDayTotal = data.data.byDayTotal
			collegeMapper[i].byDayRev = data.data.byDay * collegeMapper[i].pricePerApp
			collegeMapper[i].byDayExRev = data.data.byDayTotal * collegeMapper[i].pricePerApp
            
			collegeMapper[i].byYesterday = data.data.byYesterday
			collegeMapper[i].byYesterdayTotal = data.data.byYesterdayTotal
			collegeMapper[i].byYesterdayRev = data.data.byYesterday * collegeMapper[i].pricePerApp
			collegeMapper[i].byYesterdayExRev = data.data.byYesterdayTotal * collegeMapper[i].pricePerApp

			collegeMapper[i].byWeek = data.data.byWeek
			collegeMapper[i].byWeekTotal = data.data.byWeekTotal
			collegeMapper[i].byWeekRev = data.data.byWeek * collegeMapper[i].pricePerApp
			collegeMapper[i].byWeekExRev = data.data.byWeekTotal * collegeMapper[i].pricePerApp

			collegeMapper[i].byLastWeek = data.data.byLastWeek
			collegeMapper[i].byLastWeekTotal = data.data.byLastWeekTotal
			collegeMapper[i].byLastWeekRev = data.data.byLastWeek * collegeMapper[i].pricePerApp
			collegeMapper[i].byLastWeekExRev = data.data.byLastWeekTotal * collegeMapper[i].pricePerApp

			collegeMapper[i].byLastMonth = data.data.byLastMonth
			collegeMapper[i].byLastMonthTotal = data.data.byLastMonthTotal
			collegeMapper[i].byLastMonthRev = data.data.byLastMonth * collegeMapper[i].pricePerApp
			collegeMapper[i].byLastMonthExRev = data.data.byLastMonthTotal * collegeMapper[i].pricePerApp

			collegeMapper[i].byMonth = data.data.byMonth
			collegeMapper[i].byMonthTotal = data.data.byMonthTotal
			collegeMapper[i].byMonthRev = data.data.byMonth * collegeMapper[i].pricePerApp
			collegeMapper[i].byMonthExRev = data.data.byMonthTotal * collegeMapper[i].pricePerApp

			collegeMapper[i].byAllTime = data.data.byAllTime
			collegeMapper[i].byAllTimeTotal = data.data.byAllTimeTotal
			collegeMapper[i].byAllTimeRev = data.data.byAllTime * collegeMapper[i].pricePerApp
			collegeMapper[i].byAllTimeExRev = data.data.byAllTimeTotal * collegeMapper[i].pricePerApp
		}
		setCollegePerData(collegeMapper)
        // console.log("das",collegeMapper)

		var completedByAllRevTemp = {
			byDayAllRev: 0,
			byYesterdayAllRev: 0,
			byWeekAllRev: 0,
            byLastWeekAllRev: 0,
			byLastMonthAllRev: 0,
			byMonthAllRev: 0,
			byAllTimeRev: 0,
            
			byDayAllExRev: 0,
			byYesterdayAllExRev: 0,
			byWeekAllExRev: 0,
            byLastWeekAllExRev: 0,
			byLastMonthAllExRev: 0,
			byMonthAllExRev: 0,
			byAllTimeExRev: 0,
		}

		for (var i = 0; i < collegeMapper.length; i++) {
			completedByAllRevTemp.byDayAllRev += collegeMapper[i].byDayRev
			completedByAllRevTemp.byYesterdayAllRev += collegeMapper[i].byYesterdayRev
			completedByAllRevTemp.byWeekAllRev += collegeMapper[i].byWeekRev
			completedByAllRevTemp.byLastWeekAllRev += collegeMapper[i].byLastWeekRev
			completedByAllRevTemp.byLastMonthAllRev += collegeMapper[i].byLastMonthRev
			completedByAllRevTemp.byMonthAllRev += collegeMapper[i].byMonthRev
			completedByAllRevTemp.byAllTimeRev += collegeMapper[i].byAllTimeRev

            
			completedByAllRevTemp.byDayAllExRev += collegeMapper[i].byDayExRev
			completedByAllRevTemp.byYesterdayAllExRev += collegeMapper[i].byYesterdayExRev
			completedByAllRevTemp.byWeekAllExRev += collegeMapper[i].byWeekExRev
			completedByAllRevTemp.byLastWeekAllExRev += collegeMapper[i].byLastWeekExRev
			completedByAllRevTemp.byLastMonthAllExRev += collegeMapper[i].byLastMonthExRev
			completedByAllRevTemp.byMonthAllExRev += collegeMapper[i].byMonthExRev
			completedByAllRevTemp.byAllTimeExRev += collegeMapper[i].byAllTimeExRev
		}

		setCompletedByAllRev(completedByAllRevTemp)
	    }, [])

	if (!forms || forms.length === 0) return <p>We are waiting for the data to load</p>
	if (!incForms || incForms.length === 0) return <p>We are waiting for the data to load</p>
	if (!college || college.length === 0) return <p>We are waiting for the data to load</p>
	if (!completedByAllRev) return <p>We are waiting for the data to load</p>

	return (
		<div className='container'>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>

			<h3 className='text-center'>Welcome Admin!</h3>
			<br />
			<div className='row'>
				<div className='col-md-4 '>
					<Card style={style}>
						<Card.Body
							style={{
								boxShadow: 'none',
								display: 'flex',
								justifyContent: 'space-around',
								flexDirection: 'column',
							}}
						>
							<Card.Title> Colleges</Card.Title>
							<Card.Subtitle className='mb-2 text-muted'>
								View submitions of each college
							</Card.Subtitle>
							<Card.Text>
								Active College: {college.data.filter(x=>x.isActive === true).length}
							</Card.Text>
							<Card.Link
								href='/colleges'
								style={{
									padding: '8px 2px',
									background: 'green',
									color: 'white',
									borderRadius: '6px',
									textAlign: 'center',
								}}
							>
								Click here to View data
							</Card.Link>
						</Card.Body>
					</Card>
				</div>
				<div className='col-md-4 '>
					<Card style={style} >
						<Card.Body
							style={{
								boxShadow: 'none',
								display: 'flex',
								justifyContent: 'space-around',
								flexDirection: 'column',
							}}
						>
							<Card.Title> Incomplete Applications</Card.Title>
							<br />

							<Card.Subtitle className='mb-2 text-muted'>
								Count: {incForms.data.length}
							</Card.Subtitle>

							<br />
							<Card.Link
								href='/incompleteForms'
								style={{
									padding: '8px 2px',
									background: 'green',
									color: 'white',
									borderRadius: '6px',
									textAlign: 'center',
								}}
							>
								Click here to View List
							</Card.Link>
						</Card.Body>
					</Card>
				</div>
				<div className='col-md-4 '>
					<Card style={style}>
						<Card.Body
							style={{
								boxShadow: 'none',
								display: 'flex',
								justifyContent: 'space-around',
								flexDirection: 'column',
							}}
						>
							<Card.Title>Students Listing</Card.Title>
							<Card.Subtitle className='mb-2 text-muted'>
								View List of Students who have submitted their form
							</Card.Subtitle>
							<Card.Text>Total Forms: {forms.data.length}</Card.Text>
							<Card.Link
								href='/studentslist'
								style={{
									padding: '8px 2px',
									background: 'green',
									color: 'white',
									borderRadius: '6px',
									textAlign: 'center',
								}}
							>
								Click to view Students Data
							</Card.Link>
						</Card.Body>
					</Card>
				</div>
			</div>
            <div className='reports-dashboard'>    

                <h4 className='text-center mt-5'>Daily Form Submissions</h4>
                <Bar data={dailyBarData} width={80} height={30} options={options} />

                <h4 className='text-center mt-5'>Incomplete Form Submissions</h4>
                <Bar data={incompleteBarData} width={80} height={30} options={options} />
                <br/>
                
                <h4 className='text-center mt-5'>Daily Form comparison</h4>
                <Line data={lineBar}  />
            </div>
            <br/><br/>
            <div className='row'>
                <div className='col-md-6'>
                    <h4 className='text-center mt-5'>Approved Form submissions Details</h4>
					<table className='table' style={{ border: '2px solid #eaeaea' }}>
							<thead>
                                <th>By</th>
    							<th>Approved</th>
    							<th>%</th>
                                <th>Revenue</th>
                            </thead>
						<tbody>
                            
							<tr>
								<td>Day</td>
								<td>{forms.approvedByDay}</td>
                                <td>{((forms.approvedByDay/forms.byDay)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byDayAllRev}</td>
							</tr>
							<tr>
								<td>Yesterday</td>
								<td>{forms.approvedByYesterday}</td>
                                <td>{((forms.approvedByYesterday/forms.byYesterday)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byYesterdayAllRev}</td>
							</tr>
							<tr>
								<td>Week</td>
								<td>{forms.approvedByWeek}</td>
                                <td>{((forms.approvedByWeek/forms.byWeek)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byWeekAllRev}</td>
							</tr>
							<tr>
								<td>Last Week</td>
								<td>{forms.approvedByLastWeek}</td>
                                <td>{((forms.approvedByLastWeek/forms.byLastWeek)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byLastWeekAllRev}</td>
							</tr>
							<tr>
								<td>This Month</td>
								<td>{forms.approvedByMonth}</td>
                                <td>{((forms.approvedByMonth/forms.byMonth)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byMonthAllRev}</td>
							</tr>
							<tr>
								<td>Last Month</td>
								<td>{forms.approvedByLastMonth}</td>
                                <td>{((forms.approvedByLastMonth/forms.byLastMonth)*100).toFixed(1)}%</td>
                                <td>{completedByAllRev.byLastMonthAllRev}</td>
							</tr>
						</tbody>
					</table>
				</div>
                    <div className='col-md-6'>
                    <h4 className='text-center mt-5'>Incomplete Comaprison</h4>
                        <Bar
                            // type="bar"
                            data={incompleteStepsPie}
                            />
                    </div>


            </div> 
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                    <Pie data={{
                        labels: [incForms.proofsDetails[0]._id,incForms.proofsDetails[1]._id,incForms.proofsDetails[2]._id,incForms.proofsDetails[3]._id,incForms.proofsDetails[4]._id],
                        datasets: [
                            {
                            data: [incForms.proofsDetails[0].count,incForms.proofsDetails[1].count,incForms.proofsDetails[2].count,incForms.proofsDetails[3].count,incForms.proofsDetails[4].count],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                            },
                        ],
                        }} />
                </div>
                <div className='col-md-6'>
                    <Pie data={{
                        labels: collegeLabels,
                        datasets: [
                            {
                            data: collegeCount,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                            },
                        ],
                        }} />
                </div>
            </div>               
            
			<hr className='mt-5 mb-5' style={{ border: '2px solid #eaeaea' }} />
			<div className='row'>
				<div className='col-md-7'>
					<h4 className='text-center mt-5'>Incomplete form submissions</h4>
					<table className='table' style={{ border: '2px solid #eaeaea' }}>
							<thead>
                                <th>By</th>
    							<th>Total</th>
	    						<th> 1</th>
	    						<th> 2</th>
	    						<th> 3</th>
	    						<th> 4</th>
	    						<th> 5</th>
	    						<th> 6</th>
	    						<th> 7</th>
                            </thead>
						<tbody>
                            
							<tr>
								<td>Today</td>
								<td>{sumUp(incForms.today)}</td>
								<td>{(incForms.today).find((x)=> x._id==='Step 1') ?(incForms.today).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 2') ?(incForms.today).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 3') ?(incForms.today).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 4') ?(incForms.today).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 5') ?(incForms.today).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 6') ?(incForms.today).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.today).find((x)=> x._id==='Step 7') ?(incForms.today).find((x)=> x._id==='Step 7').count : 0}</td>
                                
							</tr>
							<tr>
								<td>Yesterday</td>
								<td>{sumUp(incForms.yesterday)}</td>
								<td>{(incForms.yesterday).find((x)=> x._id==='Step 1') ?(incForms.yesterday).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 2') ?(incForms.yesterday).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 3') ?(incForms.yesterday).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 4') ?(incForms.yesterday).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 5') ?(incForms.yesterday).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 6') ?(incForms.yesterday).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.yesterday).find((x)=> x._id==='Step 7') ?(incForms.yesterday).find((x)=> x._id==='Step 7').count : 0}</td>
                                
							</tr>
							<tr>
								<td>Week</td>
								<td>{sumUp(incForms.week)}</td>
								<td>{(incForms.week).find((x)=> x._id==='Step 1') ?(incForms.week).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 2') ?(incForms.week).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 3') ?(incForms.week).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 4') ?(incForms.week).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 5') ?(incForms.week).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 6') ?(incForms.week).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.week).find((x)=> x._id==='Step 7') ?(incForms.week).find((x)=> x._id==='Step 7').count : 0}</td>
                            </tr>
                            
							<tr>
								<td>Last Week</td>
								<td>{sumUp(incForms.lastWeek)}</td>
								<td>{(incForms.lastWeek).find((x)=> x._id==='Step 1') ?(incForms.lastWeek).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 2') ?(incForms.lastWeek).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 3') ?(incForms.lastWeek).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 4') ?(incForms.lastWeek).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 5') ?(incForms.lastWeek).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 6') ?(incForms.lastWeek).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.lastWeek).find((x)=> x._id==='Step 7') ?(incForms.lastWeek).find((x)=> x._id==='Step 7').count : 0}</td>
                            </tr>
                            
							<tr>
								<td>This Month</td>
                                <td>{sumUp(incForms.month)}</td>
								<td>{(incForms.month).find((x)=> x._id==='Step 1') ?(incForms.month).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 2') ?(incForms.month).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 3') ?(incForms.month).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 4') ?(incForms.month).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 5') ?(incForms.month).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 6') ?(incForms.month).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.month).find((x)=> x._id==='Step 7') ?(incForms.month).find((x)=> x._id==='Step 7').count : 0}</td>
                            </tr>
							<tr>
								<td>Last Month</td>
                                <td>{sumUp(incForms.lastMonth)}</td>
								<td>{(incForms.lastMonth).find((x)=> x._id==='Step 1') ?(incForms.lastMonth).find((x)=> x._id==='Step 1').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 2') ?(incForms.lastMonth).find((x)=> x._id==='Step 2').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 3') ?(incForms.lastMonth).find((x)=> x._id==='Step 3').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 4') ?(incForms.lastMonth).find((x)=> x._id==='Step 4').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 5') ?(incForms.lastMonth).find((x)=> x._id==='Step 5').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 6') ?(incForms.lastMonth).find((x)=> x._id==='Step 6').count : 0}</td>
                                <td>{(incForms.lastMonth).find((x)=> x._id==='Step 7') ?(incForms.lastMonth).find((x)=> x._id==='Step 7').count : 0}</td>
                            </tr>
						</tbody>
					</table>
				</div>
				<div className='col-md-5'>
					<h4 className='text-center mt-5'>Completed applications</h4>
					<table className='table' style={{ border: '2px solid #eaeaea' }}>
						<thead>
							<th>By</th>
							<th>Forms</th>
							<th>%</th>
							<th>Revenue</th>
						</thead>
						<tbody>
							<tr>
								<td>Today</td>
								<td>{forms.byDay}</td>
                                <td>{((forms.byDay/(forms.byDay+sumUp(incForms.today)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byDayAllExRev}</td>
							</tr>
							<tr>
								<td>Yesterday</td>
								<td>{forms.byYesterday}</td>
                                <td>{((forms.byYesterday/(forms.byYesterday+sumUp(incForms.yesterday)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byYesterdayAllExRev}</td>
							</tr>
							<tr>
								<td>Week</td>
								<td>{forms.byWeek}</td>
                                <td>{((forms.byWeek/(forms.byWeek+sumUp(incForms.week)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byWeekAllExRev}</td>
							</tr>
							<tr>
								<td>Last Week</td>
								<td>{forms.byLastWeek}</td>
                                <td>{((forms.byLastWeek/(forms.byLastWeek+sumUp(incForms.lastWeek)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byLastWeekAllExRev}</td>
							</tr>
							<tr>
								<td>This Month</td>
								<td>{forms.byMonth}</td>
                                <td>{((forms.byMonth/(forms.byMonth+sumUp(incForms.month)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byMonthAllExRev}</td>
							</tr>
							<tr>
								<td>Last Month</td>
								<td>{forms.byLastMonth}</td>
                                <td>{((forms.byLastMonth/(forms.byLastMonth+sumUp(incForms.lastMonth)))*100).toFixed(1)}%</td>
								<td>{completedByAllRev.byLastMonthAllExRev}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<hr className='mt-5 mb-5' style={{ border: '2px solid #eaeaea' }} />
            <h4  className='text-center'>Relative Reports (last 2 months data)</h4>
            <Tabs
            defaultActiveKey="Incomplete Location vise"
            id="uncontrolled-tab-example"
            className="mb-3"
            >
                <Tab eventKey="Incomplete Location vise" title="Incomplete Location vise">
                    <br/>
                    {incForms && <div className='row'>
                        {(incForms.reports).map((step)=> <>
                            <div className='col-md-4'>
                                <h5 className='text-center'>{step.step}</h5>
                                    <table
                                        className='table'
                                        style={{ border: '2px solid #eaeaea' }}
                                    >
                                        <thead>
                                            <th>City</th>
                                            <th>Count</th>
                                        </thead>
                                        <tbody>
                                            {(step.steps).map(x=> <>
                                                <tr>
                                                    <td>
                                                        {x.city}
                                                    </td>
                                                    <td>
                                                        {x.count}
                                                    </td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                    </table>
                                                    
                            </div>
                            <br/>
                        </>)}   
                        
                        </div>}
                </Tab>
                <Tab eventKey="Incomplete Course vise" title="Incomplete Course vise">
                    <br/>
                    {incForms && <div className='row'>
                        {(incForms.reportsCourse).map((step)=> <>
                            <div className='col-md-4'>
                                <h5 className='text-center'>{step.step}</h5>
                                    <table
                                        className='table'
                                        style={{ border: '2px solid #eaeaea' }}
                                    >
                                        <thead>
                                            <th>City</th>
                                            <th>Count</th>
                                        </thead>
                                        <tbody>
                                            {(step.steps).map(x=> <>
                                                <tr>
                                                    <td>
                                                        {x.appliedCourse}
                                                    </td>
                                                    <td>
                                                        {x.count}
                                                    </td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                    </table>
                                                    
                            </div>
                            <br/>
                        </>)}   
                        
                        </div>}
                </Tab>
                <Tab eventKey="Complete Location vise" title="Complete Location vise" >
                <br/>
                    {incForms && <div className='row'>
                            <div className='col-md-4'>
                                <h5 className='text-center'>Completed Forms</h5>
                                    <table
                                        className='table'
                                        style={{ border: '2px solid #eaeaea' }}
                                    >
                                        <thead>
                                            <th>City</th>
                                            <th>Count</th>
                                        </thead>
                                        <tbody>
                                            {(incForms.reportsComplete).map(x=> <>
                                                <tr>
                                                    <td>
                                                        {x.city}
                                                    </td>
                                                    <td>
                                                        {x.count}
                                                    </td>
                                                </tr>
                                            </>)}
                                        </tbody>
                                    </table>
                                                    
                            </div>
                            <br/>
                        
                        </div>}
                </Tab>
                
                <Tab eventKey="Complete Course vise" title="Complete Course vise" >
                <br/>
                    {incForms && <div className='row'>
                            <div className='col-md-4'>
                                <h5 className='text-center'>Completed Forms</h5>
                                    <table
                                        className='table'
                                        style={{ border: '2px solid #eaeaea' }}
                                    >
                                        <thead>
                                            <th>City</th>
                                            <th>Count</th>
                                        </thead>
                                        <tbody>
                                            {(incForms.subjectComplete).map(x=> <>
                                                <tr>
                                                    <td>
                                                        {x._id}
                                                    </td>
                                                    <td>
                                                        {x.count}
                                                    </td>
                                                </tr>
                                            </>)}
                                            </tbody>
                                    </table>
                                                    
                            </div>
                            <br/>
                        
                        </div>}
                </Tab>
            </Tabs>
           
			<hr className='mt-5 mb-5' style={{ border: '2px solid #eaeaea' }} />

			{perCollegeData && (
				<>
					<div className='row'>
						{perCollegeData.map((clg) => {
							return (
								<>
									<div className='col-md-6'>
										<h5 className='text-center'>{clg.name}</h5>
										<table
											className='table'
											style={{ border: '2px solid #eaeaea' }}
										>
											<thead>
												<th>By</th>
												<th>Total</th>
												<th>Approved</th>
												<th>Revenue</th>
											</thead>
											<tbody>
												<tr>
													<td>Day</td>
													<td>{clg.byDayTotal}</td>
													<td>{clg.byDay}</td>
													<td>{clg.byDayRev}</td>
												</tr>
												<tr>
													<td>Yesterday</td>
													<td>{clg.byYesterdayTotal}</td>
													<td>{clg.byYesterday}</td>
													<td>{clg.byYesterdayRev}</td>
												</tr>
												<tr>
													<td>Week</td>
													<td>{clg.byWeekTotal}</td>
													<td>{clg.byWeek}</td>
													<td>{clg.byWeekRev}</td>
												</tr>
												<tr>
													<td>Last Week</td>
													<td>{clg.byLastWeekTotal}</td>
													<td>{clg.byLastWeek}</td>
													<td>{clg.byLastWeekRev}</td>
												</tr>
												<tr>
													<td>Last Month</td>
													<td>{clg.byLastMonthTotal}</td>
													<td>{clg.byLastMonth}</td>
													<td>{clg.byLastMonthRev}</td>
												</tr>
												<tr>
													<td>This Month</td>
													<td>{clg.byMonthTotal}</td>
													<td>{clg.byMonth}</td>
													<td>{clg.byMonthRev}</td>
												</tr>
												<tr>
													<td>All Time</td>
													<td>{clg.byAllTimeTotal}</td>
													<td>{clg.byAllTime}</td>
													<td>{clg.byAllTimeRev}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</>
							)
						})}
					</div>
				</>
			)}

		</div>
	)
}

export default AdminLandingPage
