import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { Card, Button } from 'react-bootstrap';


import { Helmet } from 'react-helmet'

const TITLE = 'FAQs'

const faqs = () => {
    return(
        <div>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>


            <div className="text-center splash">
                <h1 className="heading-jumbotron">F.A.Qs</h1>
            </div>
            <div className="container-sm course-details-p">
                <div className="row">
                    <div className="col-md-6 col-xs-12 col-sm-12 ">
                        <Accordion>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                        Are the courses really free?</Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                Yes. The courses are funded by the UK Government’s Adult Education Budget (AEB) via the Education and Skills Funding Agency (ESFA).
                                                <br/> 
                                                If you don't qualify for full funding we work with colleges that will waive all course fees. So regardless, you can study a course for FREE.
                                                </Card.Body>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">How do I know if I qualify </Accordion.Toggle>
                                 <Accordion.Collapse eventKey="1">
                                 <Card.Body>
                                Typically the qualification criteria is below. In some cases colleges may choose to only accept students over the age of 24.
                                <br/>As long as you are:
                                <br/>
                                <br/>19+
                                <br/>Live in England
                                <br/>Have lived in the UK/EU for the last 3 years
                                <br/>Haven't completed the course before 
                                <br/>
                                <br/>
                                <br/>Please click the apply button on the course page to be take to the latest eligibility check form.
                                                            </Card.Body>
                                </Accordion.Collapse>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">Do you need all the information on the application form?     </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body><b>The simple answer is yes.</b>
    <br/>
    The exact details requested on the online form are what’s required on the European Union Social fund learner application form.
    <br/>
    The information is required to allow the college to claim funding on your behalf from the government so you don’t have to pay £100’s for the course.
                                </Card.Body>
                                </Accordion.Collapse>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">How soon will I be contacted by the college?      </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>Usually within 7 working days you will receive email confirmation from the college and contact directly from your allocated tutor.
                                                            </Card.Body>
                                </Accordion.Collapse>
                                <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">Are the courses accredited?  </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>Yes, the Level 2 courses are accredited by TQUK and are nationally recognised qualifications.
                                </Card.Body>
                                </Accordion.Collapse>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="5">How long are the courses?</Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                            <Card.Body>Typically the courses are 8-12 weeks. However you do receive a dedicated tutor to support you in the completion of the course.</Card.Body>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 ">
                        <Accordion>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="6">Do I get a certificate at the end? </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                        <Card.Body>Usually within 7 working days you will receive email confirmation from the college and contact directly from your allocated tutor.</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="7">How am I assessed?</Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                        <Card.Body>You will be assessed throughout your study by a series of assessment questions which are built into the course. Your answers are automatically saved and are available for you to revise and edit at any point before submission. You submit your assessment answers to your Tutor for marking, who will give you feedback on each question.</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="8">Do I have to meet with a Tutor or Learning Support Advisor?</Accordion.Toggle>

                        <Accordion.Collapse eventKey="8">
                        <Card.Body>No. The communication you and your staff have with a Tutor or Learning Support Advisor will be via telephone, email or through the colleges online learning system.</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="9">What happens if I do not pass an assessment?</Accordion.Toggle>
                        <Accordion.Collapse eventKey="9">
                        <Card.Body>If you do not pass an assessment, don’t worry. Once your Tutor has marked your work, you will receive an email instructing you to log into the system. If your result is ‘Refer’ this means your Tutor has highlighted some areas which need more attention in order for you to pass. You will then be able to re-attempt and re-submit the questions you didn’t pass first time round.</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="10">How long will it take for my certificate to arrive? </Accordion.Toggle>
                        <Accordion.Collapse eventKey="10">
                        <Card.Body>Your certificate will take between eight and twelve weeks to arrive. Once you have completed the course and submitted your final piece of work, your assessments will be marked and your work will go through an external quality assurance process. Once this process is complete, the college will send your certificate to you as soon as possible. If you require a confirmation of your achievement while you await your certificate, please contact the college and they can send you a formal letter of achievement.</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">Can I apply for more than one course at a time? </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>No, you can only apply for one funded course at a time. Once you have completed your course and gone through moderation you can do another course. </Card.Body>
                        </Accordion.Collapse>
                        </Accordion>
                        <br />
                    </div>
                </div>
            </div>

            </div>
    );
} 
export default faqs;