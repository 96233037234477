import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialTable from 'material-table';
import { alpha } from '@material-ui/core/styles'

const Step1FailedForms = () => {
	const dispatch = useDispatch()
	const [forms, setForms] = React.useState([])

    useEffect(async() => {
		const data = await axios.get('/users/allstep1failedforms')
        console.log(data.data.data)
        setForms(data.data.data)
	}, [])


	return (
		<div className='container incomplete-container'>
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
                    <MaterialTable
                        title="Deleted Forms"
                        columns={[
                            { title: 'First Name', field: 'firstName',editable: 'never' },
                            { title: 'Middle Name', field: 'middleName',editable: 'never' },
                            { title: 'Last Name', field: 'lastName',editable: 'never' },
                            { title: 'Email', field: 'email',editable: 'never' },
                            { title: 'Applied Course', field: 'appliedCourse',editable: 'never'},
                            { title: 'Mobile', field: 'mobile',editable: 'never' },
                            { title: 'Postcode', field: 'PostCodeBL',editable: 'never' },
                            { title: 'Date', field: 'createdAt',editable: 'never'},
                            ]}
                        data={forms.reverse()} 
                        options={{
                            pageSize:50,
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        />
				</div>
			)}
		</div>
	)
}

export default Step1FailedForms
