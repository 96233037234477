import axios from 'axios'

const customAxios = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
})

class SmsService {
	sendSms = (to, body) =>
		customAxios.post('/sms/send', { to, body })

}

export default new SmsService()
