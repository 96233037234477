import React from "react";
import { Button, Grid, Container } from "@material-ui/core/";
import Badge from 'react-bootstrap/Badge';


const Posts = (props) => {
    const { posts } = props;
    //const classes = useStyles();
    console.log(posts)
    if (!posts || posts.length === 0)
        return <p className="text-center">Cannot find any posts</p>;
    posts.data = posts.data.filter(x => x.status !== "disabled")
    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {posts.data.map((post) => {
                        return (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={post.id} xs={12} md={12}>
                                <div className="card ">
                                    <div className="row">
                                        <div className="col-md-2 hidden-xs">
                                            <img
                                                style={{ objectFit: "contain" }}
                                                className="card-img-top"
                                                src={post.img}
                                                alt="Card image cap"
                                                height="300px"
                                                width="300px"
                                            />
                                        </div>
                                        <div className="col-md-8 col-xs-12">
                                            <div>
                                                <h5 className="card-title">{post.courseTitle}</h5>
                                                <p className="card-text disp ">{post.funding}</p>
                                                <p className="desc pbor">{post.shortDescription}</p>
                                                <p className="card-text  dispp">{post.funding}</p>
                                            </div>
                                        </div>

                                        <div className="col cbutton" style={{ border: "0px" }}>
                                            <Button
                                                className="co-button"
                                                onClick={(e) => {
                                                    window.location.href =
                                                        "/single?id=" + post.courseTitle;
                                                }}
                                            >
                                                View Course
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                    {/* New static course template */}
                    <Grid item xs={12} md={12}>
                        <div className="card ">
                            <div className="row">
                                <div className="col-md-2 hidden-xs">
                                    <img
                                        style={{ objectFit: "contain" }}
                                        className="card-img-top"
                                        src="https://res.cloudinary.com/my-free-course/image/upload/v1715171108/Courses/FREE_Courses_buvv14.jpg"
                                        alt="Card image cap"
                                        height="300px"
                                        width="300px"
                                    />
                                </div>
                                <div className="col-md-8 col-xs-12">
                                    <div>
                                        <h5 className="card-title">Green Skills Employability Program</h5>
                                        <p className="card-text disp ">Funded</p>
                                        <p className="desc pbor">
                                            An employability program available to those in London who are interested in gaining 2 qualifications, getting a guaranteed job interview within the green sector and receiving 1 to 1 employment support.
                                        </p>
                                        <p className="card-text  dispp">Funded</p>
                                    </div>
                                </div>

                                <div className="col cbutton" style={{ border: "0px" }}>
                                    <Button
                                        className="co-button"
                                        onClick={(e) => {
                                            window.location.href =
                                                "/employment-skills-and-climate-change"
                                        }}
                                    >
                                        View Course
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {/* DEMO DATA ENDS */}


                    {/* New static Contruction course template */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665492418/Courses/Makeup_Artist_cvmycl.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Make-Up Artistry</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Make-up Artistry is to prepare you to enter the specific sector of beauty therapy as a make-up artist. All the units in this qualification relate directly to the specific job role of a make-up artist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/makeup-artistry-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665489863/Courses/Bridal_Hair_yzdryg.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Bridal</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The Level 3 Diploma in Bridal Hair and Make-up will provide you with advanced practical skills and knowledge in bridal hair and make-up.

                        The diploma is aimed at people currently working in the hair and beauty sector who wish to specialise in bridal hair and make-up.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-certificate"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                <div className="card ">
                  <div className="row">
                    <div className="col-md-2 hidden-xs">
                      <img
                        style={{ objectFit: "contain" }}
                        className="card-img-top"
                        src="https://res.cloudinary.com/my-free-course/image/upload/v1661175867/Courses/Ixion_Beauty_Therapy_rghn9i.jpg"
                        alt="Card image cap"
                        height="300px"
                        width="300px"
                      />
                    </div>
                    <div className="col-md-8 col-xs-12">
                        <Badge variant="info">Only for London </Badge><br/>
                      <div> 
                        <h5 className="card-title">Level 3 Certificate in Beauty Therapy Treatment</h5>
                        <p className="card-text disp ">Funded</p>
                        <p className="desc pbor">The primary outcome of the Level 3 Diploma in Beauty Therapy Treatment is to prepare you to enter the specific sector of beauty therapy as a nail technician. All the units in this qualification relate directly to the specific job role of a beauty therapist.</p>
                        <p className="card-text  dispp">Funded</p>
                      </div>
                    </div>

                    <div className="col cbutton" style={{ border: "0px" }}>
                      <Button
                        className="co-button"
                        onClick={(e) => {
                          window.location.href =
                            "/beauty-therapy-general"
                        }}
                      >
                        View Course
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid> */}
                    {/* DEMO DATA ENDS */}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Posts;
