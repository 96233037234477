import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import { newDeclarationNSC } from '../../actions/formActions'
import axios from 'axios'

const NewSwindonDec = ({ history }) => {
	const urlParams = new URLSearchParams(history.location.search)
	const formId = urlParams.get('form_id')
	const [products, setProducts] = React.useState()
    
    const getData = async () => {
		try {
			let form = await axios.get(process.env.REACT_APP_API_URL + `/form/get_form_by_id/${formId}`)
			setProducts(form.data.form)
		} catch (err) {
			console.log(err)
		}
	}
	React.useEffect(getData, [])

    const submitHandler = (e) => {
       
        
		const form = e.currentTarget
        if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
			e.preventDefault()
			const response = newDeclarationNSC(formId).then(x=>{
                console.log(x)
                if(x.data.success){
                    history.push('/thank-you')
                }
            })
			
		}
    }
	return (
		<>
			<FormContainer >
				{/* <ScrollToMount /> */}
                
                <div className='container'>  
                  <img id="logo-europe" src="https://res.cloudinary.com/my-free-course/image/upload/v1669004056/Site-pics/EU_Social_Fund_jr2cez.png" style={{maxWidth:"120px"}} />
                </div>
                   
				<h1>
					<b>Policy Update</b>
				</h1>
                <br/>
				<Form onSubmit={submitHandler} className="policy">
                    {products && <>
                    <b>Name: </b> {products.personalDetails.firstName + ' '+ products.personalDetails.lastName}<br/>
                        <b>Applied Course: </b> {products.detailsFormData.appliedCourse}<br/>
                        <b>Date Applied: </b> {products.date}<br/>
                        </>
                    }
                    
                    <br/><br/> 
                    <b> There has been a policy notice update in line with the funding regulations as well an update to the declaration statement. 
                    <br/>
                    Please see the below.
                    <br/>
                    Confirmation that you have read the below is needed to make sure you are still able to access your funded
                    course.</b><br/>

                    <br/>
                    <p>
                    <b>1- Privacy Policy Update Statement</b>
                <br/>
                    This privacy notice is issued by the Education and Skills Funding Agency (ESFA) on behalf of the Secretary of State for
                    the Department of Education (DfE) to inform learners about the Individualised Learner Record (ILR) and how their
                    personal information is used in the ILR. Your personal information is used by the DfE to exercise our functions under
                    article 6(1)(e) of the UK GDPR and to meet our statutory responsibilities, including under the Apprenticeships, Skills,
                    Children and Learning Act 2009.<br/>
                <br/>
                    The ILR collects data about learners and learning undertaken. Publicly funded colleges, training organisations, local
                    authorities, and employers (FE providers) must collect and return the data to the ESFA each year under the terms of a
                    funding agreement, contract or grant agreement. It helps ensure that public money distributed through the ESFA is being
                    spent in line with government targets. It is also used for education, training, employment, and well being purposes,
                    including research. We retain ILR learner data for 3 years for operational purposes and 66 years for research purposes.
                    For more information about the ILR and the data collected, please see the ILR specification at
                    https://www.gov.uk/government/collections/individualised-learner-record-ilr
                <br/>
                <br/>
                    ILR data is shared with third parties where it complies with DfE data sharing procedures and where the law allows it. The
                    DfE and the English European Social Fund (ESF) Managing Authority (or agents acting on their behalf) may contact
                    learners to carry out research and evaluation to inform the effectiveness of training.<br/>
                <br/>
                    For more information about how your personal data is used and your individual rights, please see the DfE Personal
                    Information Charter (https://www.gov.uk/government/organisations/department-for-education/about/personal-information-
                    charter) and the ESFA Privacy Notice (https://www.gov.uk/government/publications/esfa-privacy-notice)
                    If you would like to get in touch with us or request a copy of the personal information DfE holds about you, you can
                    contact the DfE in the following ways:
                <br/>
                <br/>
                    Using our online contact form https://www.gov.uk/government/organisations/department-for-education/about/personal-
                    information-charter
                <br/>
                    By telephoning the DfE Helpline on 0370 000 2288
                <br/>
                    Or in writing to – Data Protection Officer, Ministerial and Public Communications Division, Department for Education,
                    Piccadilly Gate, Store Street, Manchester, M1 2WD
<br/>
                    If you are unhappy with how we have used your personal data, you can complain to the Information Commissioner’s
                    Office (ICO) at:
                <br/>
                    Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF. You can also call their helpline on 0303 123 1113 or visit
                    https://www.ico.org.uk
                    <br/>
                <br/>
                    <b>2- Learner Declaration update statement</b>
                <br/>
                    I am aware that the EU/ESF may financially support my course.
                    </p>
                    <br/><br/>
                    <p>Please confirm that you have read and understood these updated statements.</p>
                    <Button
						type='submit'
						className='next-Button-form'
						style={{
							width: '300px',
							color: 'white',
						}}
					>
						{' '}
						Confirm
					</Button>
				</Form>
			</FormContainer>

			<div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
				<center>
					<Spinner animation='border' />
				</center>
			</div>
		</>
	)
}

export default NewSwindonDec