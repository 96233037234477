import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

const style = {
    width: '18rem',
    margin: '5px',
    marginBottom: '130px',
}

const CollegeLandingPage = () => {
    const history = useHistory()
    const [forms, setForms] = useState(null)
    const [collegeName, setCollegeName] = useState()
    const [contracts, setContracts] = useState([])

    useEffect(() => {
        axios
            .post(process.env.REACT_APP_API_URL + '/college/get_college_by_id', {
                collegeID: localStorage.getItem('collegeID'),
            })
            .then((res) => {
                setCollegeName(res.data.data.collegeName)
                axios
                    .post(process.env.REACT_APP_API_URL + '/college/get_forms_by_day', {
                        collegeID: localStorage.getItem('collegeID'),
                    })
                    .then((res) => {
                        setForms(res.data)
                    })
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(process.env.REACT_APP_API_URL + '/contracts/single-college/' + localStorage.getItem('collegeID')).then(res => {
            if (res.data.data) {
                if (res.data.data[0].signupTemporary === false || !res.data.data[0].signupTemporary) {
                    setContracts(res.data.data.reverse() || [])
                } else {
                    history.push('/college-complete-signup')
                }
            }
        })
    }, [])

    return (
        <>
            {forms !== null && (
                <div className='container'>
                    <h3 className='text-center mt-5'>Welcome {collegeName}!</h3>
                    <br />
                    <div className='row'>
                        {(contracts || []).map(x => <>
                            <div className='col-md-4 '>
                                <Card style={{ ...style, background: x.isActive === true ? "white" : "#ffcdd2" }}>
                                    <Card.Body
                                        style={{
                                            boxShadow: 'none',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            flexDirection: 'column',
                                            background: x.isActive === true ? "white" : "#ffcdd2"
                                        }}
                                    >
                                        <Card.Title> Contract: {x.name}</Card.Title>
                                        <Card.Subtitle className='mb-2 text-muted'>
                                            Applications
                                        </Card.Subtitle>
                                        <Card.Text>

                                            {x.formsDelievered}
                                        </Card.Text>
                                        <Card.Link
                                            onClick={() => history.push('/studentslist', { id: x._id })}
                                            className="pointer-cursor"
                                            style={{
                                                padding: '8px 2px',
                                                background: 'green',
                                                color: 'white',
                                                borderRadius: '6px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            View All
                                        </Card.Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>)}
                    </div>
                    <br />
                    <table className='table mt-5' style={{ border: '2px solid black' }}>
                        <thead>
                            <th>By</th>
                            <th>Submissions</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Day</td>
                                <td>{forms.byDay}</td>
                            </tr>
                            <tr>
                                <td>Week</td>
                                <td>{forms.byWeek}</td>
                            </tr>
                            <tr>
                                <td>Last Month</td>
                                <td>{forms.byLastMonth}</td>
                            </tr>
                            <tr>
                                <td>This Month</td>
                                <td>{forms.byMonth}</td>
                            </tr>
                            <tr>
                                <td>All Time</td>
                                <td>{forms.byAllTime}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default CollegeLandingPage
