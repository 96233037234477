import React from "react";

import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const DetailedCourse = ({ product }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Benefits = (props) => {
    const text = props.text;
    const newText = text.split("•").map((str) => (
        <li>{str}</li>
    ));
    return newText;
  };
  let Content = (props) => {
    let text = props.text;
    const res = String(text);
    // console.log(res);
    let newText = res.split("•").map((str) => (
      <li>{str} </li>
    )); 
    return newText;
  };
  function demo (arr1,arr2){
    let Farray=[];
    for(let i=0;i<arr1.length-1;i++){
      let object = {
        heading: arr1[i],
        desc: arr2[i]
      }
      Farray.push(object);
    }
    return Farray;
  }

  let arraysCourse = demo(product.heading,product.courseContentarr);
//   console.log(product);
  return (
    <div>
      <Helmet>
          <title>{product.courseTitle}</title>
      </Helmet>

    <div className="jumbotron text-center splash only-on-desktop">
        <h3 className="heading-jumbotron">{product.courseTitle}</h3>
      </div>
      <div className="only-on-mobile">
        <img
            src={product.img}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
    <div className="container-sm course-details-p" xs={12}>
      <div className="row">
        <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
          <div className="row" style={{display: "flex !important"}}>
            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
              <img
                src={product.img}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
              <h3 className="coursedetailh2" style={{margin:"auto"}}>{product.courseTitle}</h3>
            </div>
          </div>

          <br />
          
          <h1 className="coursedetailh2 only-on-mobile" style={{margin:"auto",fontSize: "22px",color: "#222222"}}>{product.courseTitle}</h1>
          <br/>
          <HashLink
                className="btn btn-primary co-button vv only-on-mobile"
                to={'/form/'+product.courseCode+ '#top'}
              >Apply Now
              </HashLink>
          <br/>
          <p >{product.courseDescription}</p>
          <br />
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="Content">
              <br/>
                {arraysCourse.map(
                  function(item){
                    return <div>
                      <br/>
                    <b>{item.heading}</b>
                    <ul className="course-details-p-ul2">
                      <Content
                        style={{ padding: "0.5rem 1rem" }}
                        text={item.desc}
                      />
                    </ul>
                  </div>
                  }
                )}
            </Tab>
            <Tab eventKey="benefits" title="Benefits">
              <p>The benefits of this course includes:</p>
              <ul className="course-details-p-ul">
              <Benefits
                style={{ padding: "0.5rem 1rem" }}
                text={product.courseBenefits}
              />
              </ul>
            </Tab>
          </Tabs>
        </div>
        <div className="col-md-4 info-box-courses">
          <Card style={{ width: "18rem", color: "black" }} className="wd">
            <Card.Body style={{ boxShadow: "none" }}>
              <Card.Title>KEY INFORMATION</Card.Title>
              <Table striped bordered>
                  <tbody>
                    <tr>
                      <td>Course Length</td>
                      <td>{product.courseLength}</td>
                    </tr>
                    <tr>
                      <td>Awarding Body</td>
                      <td>{product.awardingBody}</td>
                    </tr>
                    <tr>
                      <td>Course Level</td>
                      <td>{product.courseLevel}</td>
                    </tr>
                    <tr>
                      <td>Funding (subject to availability)</td>
                      <td>{product.funding}</td>
                    </tr>
                    <tr>
                      <td>Learning Method(s)</td>
                      <td>{product.learningMethods}</td>
                    </tr>
                  </tbody>
              </Table>
              <HashLink
                className="btn btn-primary co-button vv "
                to={'/form/'+product.courseCode+ '#top'}
              >
                  Apply Now
              </HashLink>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DetailedCourse;
