import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import { saveEnglishAssessment } from '../../actions/formActions'
import { toast } from 'react-toastify'

const EnglishTestSingleQuestion = ({ history }) => {
	const urlParams = new URLSearchParams(history.location.search)
	const formId = urlParams.get('form_id')
	const [words, setWords] = useState()
    const [assessment, setAssessment] = useState()
    const wordCounter = (string) => {
        return (string.split(" ").length - 1);
    } 

    const submitHandler = (e) => {
        
        let data ={
            formId: formId,
            data: {english: assessment}
        }
        
		const form = e.currentTarget
        if (wordCounter(assessment)<100){
            toast.error('Minimum 100 words required')
			e.preventDefault()
			e.stopPropagation()
        }
		else if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
			e.preventDefault()
			saveEnglishAssessment({ data })
			history.push('/thank-you')
		}
    }
    const changeValue = (value) =>{
        setAssessment(value);
        setWords(wordCounter(value));
    }
	return (
		<>
			<FormContainer>
				{/* <ScrollToMount /> */}
				<h1>
					<b>English Assessment</b>
				</h1>
				<Form onSubmit={submitHandler}>

                    <Form.Group controlId='assessment'>
                        <Form.Label>Please give an explanation as to why you wish to study this course, what you are hoping to achieve by completing it and how it will benefit you in either your work or personal life.
<br/><br/>
Please do not include any personal data in this section.
<br/><br/>
This section will be used to assess your basic writing skills to ensure that you are able to complete your workbooks, therefore, please thoroughly check your answer for spelling and grammar mistakes before moving onto the next section.
A minimum of 100 words is required. *</Form.Label>
                        <Form.Control
                            as='textarea'
                            required
                            rows={5}
                            value={assessment}
                            onChange={(e) => changeValue(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <p style={{float: "right",fontSize: "12px"}}>{words || "0"} Words</p><br/>
					<Button
						type='submit'
						className='next-Button-form'
						style={{
							width: '300px',
							color: 'white',
						}}
					>
						{' '}
						Submit
					</Button>
				</Form>
			</FormContainer>

			<div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
				<center>
					<Spinner animation='border' />
				</center>
			</div>
		</>
	)
}

export default EnglishTestSingleQuestion