import React, { useEffect, useState } from "react";
import * as auth from "../services/adminService";
import { toast } from "react-toastify";
import { Form, Button } from 'react-bootstrap'
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CButton } from "@coreui/react";
toast.configure();

const EditContract = ({ history }) => {
    const location = useLocation();
	const querystring = window.location.search;
	const URLParams = new URLSearchParams(querystring);
	const id = URLParams.get("id");
    let contractInfo = location.state
	const [validated, setValidated] = useState(false)
    const [contractName,setContractName] = React.useState(contractInfo.name)
    const [notes,setNotes] = React.useState(contractInfo.notes)
    const [coursesList,setCoursesList] = React.useState(contractInfo.coursesList)
    const [allCourses,setAllCourses] = React.useState([])
    const [whitelist,setWhitelist] = React.useState(contractInfo.whitelist)
	const [contractAmt, setConAmt] = React.useState(contractInfo.Amount);
	const [price, setPrice] = React.useState(contractInfo.pricePerApp);
    const [startDate,setStartDate] = React.useState(contractInfo.startDate)
    const [endDate,setEndDate] = React.useState(contractInfo.endDate)
	const [priority, setPriority] = React.useState(contractInfo.priority);
	const [isActive, setIsActive] = useState(contractInfo.isActive? 'Yes':'No');
	const [reports, setReports] = useState(contractInfo.reports? 'Yes':'No');
	const [collegeId, setCollegeId] = useState(contractInfo.collegeID);
	const [collegeData, setCollegeData] = useState([]);

    let allAreas = ['Normal','Wmca','Weca','Tvca','Scrca','Ntca','Wyca','London','Lcra','Gmca','Cpca','Luton_codes','Basingstoke','dcaList','d2n2'] 
    useEffect(async()=>{
        let response = await axios.get(process.env.REACT_APP_API_URL + /course/)
        setAllCourses(response.data.data)

        let collegeData = await axios.get(process.env.REACT_APP_API_URL + '/admin/collegedata')
		setCollegeData(collegeData.data.data.filter(x=> x.isActive === true))
    },[])

    const handleAreas = (string) =>{
        if(whitelist.includes(string)){
            let temp = whitelist.filter(function (item) {
				return item !== string
			})
            setWhitelist(temp)
        } else {
            let array = [...whitelist]
            array.push(string)
            setWhitelist(array)
        }
        
    }
    
    
    const handleCoursesList = (string) =>{
        if(coursesList.includes(string)){
            let temp = coursesList.filter(function (item) {
				return item !== string
			})
            setCoursesList(temp)
        } else {
            let array = [...coursesList]
            array.push(string)
            setCoursesList(array)
        }
        
    }

	const handleSubmit = async(e) => {
		const form = e.currentTarget
        if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
            
            const contractAmount = parseInt(contractAmt);
            const pricePerApp = parseInt(price);

            const response = await auth.updateContract(
                id,
                contractName,
                coursesList, 
                whitelist,
                contractAmount,
                pricePerApp,
                isActive,
                reports,
                notes,
                priority,
                startDate,
                endDate,
                collegeId
            );
            if (response.status === 200) {
                toast.success("Update Successully");
				history.push('/contracts')
            }   
        }
		setValidated(true)

	};

	return (
		<div className="container">
            <h2>Add New Contract</h2>
			<br/>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    {/* <Form.Group>
						<Form.Label>Select College</Form.Label>
						<Form.Control
							as='select'
							value={collegeId}
							onChange={(e) => setCollegeId(e.target.value)}
						>
                            <option selected disabled>Choose one</option>
                            {collegeData.map(x=><option value={x._id}>{x.collegeName}</option>)}
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group> */}
                    <Form.Group>
						<Form.Label>Contract Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter Contract Name'
							value={contractName}
							onChange={(e) => setContractName(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <Form.Group>
						<Form.Label>Notes</Form.Label>
						<Form.Control
							as='textarea'
                            rows={3}
							placeholder='Enter notes about this contract'
							value={notes}
							onChange={(e) => setNotes(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group>
						<Form.Label>Contract Amount</Form.Label>
						<Form.Control
							type='number'
							placeholder='Enter Contract Amount'
							value={contractAmt}
							onChange={(e) => setConAmt(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group>
						<Form.Label>Price per Application</Form.Label>
						<Form.Control
							type='number'
							placeholder='Enter price'
							value={price}
							onChange={(e) => setPrice(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group>
						<Form.Label>Priority</Form.Label>
						<Form.Control
							as='select'
							value={priority}
							onChange={(e) => setPriority(e.target.value)}
						>
                            
                            <option selected disabled>Choose one</option>
                            <option value='1'>Normal</option>
                            <option value='2'>High</option>
                            <option value='3'>Very High</option>
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group>
						<Form.Label>Is Active</Form.Label>
						<Form.Control
							as='select'
							value={isActive}
							onChange={(e) => setIsActive(e.target.value)}
						>
                            <option selected disabled>Choose one</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group>
						<Form.Label>Show reports on Dashboard</Form.Label>
						<Form.Control
							as='select'
							value={reports}
							onChange={(e) => setReports(e.target.value)}
						>
                            <option selected disabled>Choose one</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
					<Form.Group controlId='dob'>
						<Form.Label>Start Date </Form.Label>
						<Form.Control
							type='date'
							required
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId='dob'>
						<Form.Label>End Date </Form.Label>
						<Form.Control
							type='date'
							required
							value={endDate}
							onChange={(e) => setEndDate (e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill the required field.
						</Form.Control.Feedback>
					</Form.Group>
                    <hr/>
                     <b>Select all the Areas accepted by this Contract</b>
                     <p>(Selected are in Green)</p>
                    {allAreas.map(x=>
                        <CButton
                        color={
                        whitelist.includes(x) ? 'success' : 'primary'
                        }
                        variant='outline'
                        style={{width:"30%", marginBottom:"5px",marginLeft:"5px"}}
                        shape='square'
                        size='sm'
                        onClick={() => {
                        handleAreas(x)
                        }}
                     >
                        {x}
                     </CButton>
                     )}
                    
                </div>
                <div className="col-md-6 p-5">
                    <b>Select all the courses accepted by this contract</b>
                    <p>(Selected are in Green)</p>
                    {allCourses.map(x=>
                         <CButton
                         color={
                             coursesList.includes(x.courseCode) ? 'success' : 'primary'
                         }
                         variant='outline'
                         style={{width:"100%", marginBottom:"5px"}}
                         shape='square'
                         size='sm'
                         onClick={() => {
                             handleCoursesList(x.courseCode)
                         }}
                     >
                         {x.courseTitle}
                     </CButton>
                     )}
                     
                </div>
            </div>
            </Form>
            
            
			<div>
				<Button variant="contained" color="primary"	className='mr-5 next-Button-form' onClick={handleSubmit}>
					Save
				</Button>
			</div>
            <br/><br/>
		</div>
	);
};

export default EditContract;
