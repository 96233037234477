import React, { useEffect, useState } from "react";
import * as auth from "../services/adminService";
import { toast } from "react-toastify";
import { Form, Button } from 'react-bootstrap'
import axios from "axios";
import { useLocation } from "react-router-dom";
import { CButton } from "@coreui/react";
toast.configure();

const AddNewCollege = ({ history }) => {

    const [validated, setValidated] = useState(false)
    const [collegeName, setCollegeName] = React.useState()
    const [email, setEmail] = React.useState()
    const [pdf, setPdf] = React.useState()
    const [password, setPassword] = React.useState()
    // const [coursesList,setCoursesList] = React.useState([])
    const [allCourses, setAllCourses] = React.useState([])
    const [employmentdetailsNecessary, setEmploymentdetailsNecessary] = React.useState('No')
    // const [whitelist,setWhitelist] = React.useState([])
    const [proofs, setProofs] = React.useState([])
    const [contractAmt, setConAmt] = React.useState();
    const [price, setPrice] = React.useState();
    // const [priority, setPriority] = React.useState('1');
    const [isActive, setIsActive] = useState('No');
    const [softEnglishTest, setSoftEnglishTest] = useState('No');
    const [ifEmploymentType, setIfEmploymentType] = useState('No');
    const [ifAnualSalary, setIfAnualSalary] = useState('No');
    const [inWhichCountryDoYouLive, setInWhichCountryDoYouLive] = useState('No');
    const [countryOfBirth, setCountryOfBirth] = useState('No');
    const [startDateEmployment, setStartDateEmployment] = useState('No');
    const [fullTimeEducationElse, setFullTimeEducationElse] = useState('No');
    const [englistAssessmentActive, setEnglistAssessmentActive] = useState('No');
    const [reports, setReports] = useState('No');

    let allProofs = ["Passport", "Birth certificate", "Drivers licence", "Utility Bill", "Bank statement"]
    // let allAreas = ['Normal','Wmca','Weca','Tvca','Scrca','Ntca','Wyca','London','Lcra','Gmca','Cpca','Luton_codes','Basingstoke','dcaList','d2n2'] 
    useEffect(async () => {
        let response = await axios.get(process.env.REACT_APP_API_URL + /course/)
        setAllCourses(response.data.data)
    }, [])

    // const handleAreas = (string) =>{
    //     if(whitelist.includes(string)){
    //         let temp = whitelist.filter(function (item) {
    // 			return item !== string
    // 		})
    //         setWhitelist(temp)
    //     } else {
    //         let array = [...whitelist]
    //         array.push(string)
    //         setWhitelist(array)
    //     }

    // }

    const handleProofsList = (string) => {
        if (proofs.includes(string)) {
            let temp = proofs.filter(function (item) {
                return item !== string
            })
            setProofs(temp)
        } else {
            let array = [...proofs]
            array.push(string)
            setProofs(array)
        }

    }
    // const handleCoursesList = (string) =>{
    //     if(coursesList.includes(string)){
    //         let temp = coursesList.filter(function (item) {
    // 			return item !== string
    // 		})
    //         setCoursesList(temp)
    //     } else {
    //         let array = [...coursesList]
    //         array.push(string)
    //         setCoursesList(array)
    //     }

    // }

    const handleSubmit = async (e) => {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {

            const contractAmount = parseInt(contractAmt);
            const pricePerApp = parseInt(price);

            const response = await auth.AddNewCollegeData(
                collegeName,
                email,
                password,
                employmentdetailsNecessary,
                proofs,
                isActive,
                softEnglishTest,
                inWhichCountryDoYouLive,
                fullTimeEducationElse,
                englistAssessmentActive,
                reports,
                pdf,
                startDateEmployment,
                countryOfBirth,
                ifEmploymentType,
                ifAnualSalary
            );
            if (response.status === 200) {
                toast.success("Added Successully");
                history.push('/colleges')
            }
        }
        setValidated(true)

    };

    return (
        //editCOllege 
        <div className="container">
            <h2>Add New  College</h2>
            <br />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>College Name</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter College Name'
                                value={collegeName}
                                onChange={(e) => setCollegeName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group>
						<Form.Label>Contract Amount</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter Contract Amount'
							value={contractAmt}
							onChange={(e) => setConAmt(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group> */}
                        {/* <Form.Group>
						<Form.Label>Price per Application</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter price'
							value={price}
							onChange={(e) => setPrice(e.target.value)}
						></Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group> */}
                        <Form.Group>
                            <Form.Label>Choose Pdf</Form.Label>
                            <Form.Control
                                as='select'
                                value={pdf}
                                onChange={(e) => setPdf(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Generic">Generic</option>
                                <option value="DcGroupNational">DcGroupNational</option>
                                <option value="newSwindonPDF">newSwindonPDF</option>
                                <option value="EastleighPDF">EastleighPDF</option>
                                <option value="rnnPDF">rnnPDF</option>
                                <option value="VisionWest">VisionWest</option>
                                <option value="bristolPdf">BristolPdf</option>
                                <option value="tecPartnerPdf">TecPartnerPdf</option>
                                <option value="lincolnCollegePDF">LincolnCollegePDF</option>
                                <option value="handleNCGPdf">NCG Pdf</option>
                                <option value="HandlePDFNWSLC">NWSLC PDF</option>
                                <option value="wightCollege">Isle of Whight PDF</option>
                                <option value="SuttonColdField">Sutton ColdField</option>
                                <option value="sccuGroup">SCCU Group</option>
                                <option value="Hertfordshire">Hertfordshire</option>
                                <option value="PortsMouth">PortsMouth</option>
                                <option value="wiltshire">Wiltshire</option>
                                <option value="basingstoke">Basingstoke</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group>
						<Form.Label>Priority</Form.Label>
						<Form.Control
							as='select'
							value={priority}
							onChange={(e) => setPriority(e.target.value)}
						>
                            
                            <option selected disabled>Choose one</option>
                            <option value='1'>Normal</option>
                            <option value='2'>High</option>
                            <option value='3'>Very High</option>
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group> */}
                        <Form.Group>
                            <Form.Label>Is Active</Form.Label>
                            <Form.Control
                                as='select'
                                value={isActive}
                                onChange={(e) => setIsActive(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Long English Assessment is Active</Form.Label>
                            <Form.Control
                                as='select'
                                value={englistAssessmentActive}
                                onChange={(e) => setEnglistAssessmentActive(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Soft English Assessment (English Assessment in Form) is Active</Form.Label>
                            <Form.Control
                                as='select'
                                value={softEnglishTest}
                                onChange={(e) => setSoftEnglishTest(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ask for Emlpoyment Type? (Self or Emlpoyed)</Form.Label>
                            <Form.Control
                                as='select'
                                value={ifEmploymentType}
                                onChange={(e) => setIfEmploymentType(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Ask for Anual Salary?</Form.Label>
                            <Form.Control
                                as='select'
                                value={ifAnualSalary}
                                onChange={(e) => setIfAnualSalary(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ask "In Which Country Do you live?" </Form.Label>
                            <Form.Control
                                as='select'
                                value={inWhichCountryDoYouLive}
                                onChange={(e) => setInWhichCountryDoYouLive(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ask "start Date Employment" </Form.Label>
                            <Form.Control
                                as='select'
                                value={startDateEmployment}
                                onChange={(e) => setStartDateEmployment(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ask "Country Of Birth" </Form.Label>
                            <Form.Control
                                as='select'
                                value={countryOfBirth}
                                onChange={(e) => setCountryOfBirth(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ask "Full Time Education elsewhere"</Form.Label>
                            <Form.Control
                                as='select'
                                value={fullTimeEducationElse}
                                onChange={(e) => setFullTimeEducationElse(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Make Employer details necessary if employed for this college?</Form.Label>
                            <Form.Control
                                as='select'
                                value={employmentdetailsNecessary}
                                onChange={(e) => setEmploymentdetailsNecessary(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Show reports on Dashboard</Form.Label>
                            <Form.Control
                                as='select'
                                value={reports}
                                onChange={(e) => setReports(e.target.value)}
                            >
                                <option selected disabled>Choose one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 p-5">
                        <hr />
                        <b>Select all the Proofs accepted by this college</b>
                        <p>(Selected are in Green)</p>
                        {allProofs.map(x =>
                            <CButton
                                color={
                                    proofs.includes(x) ? 'success' : 'primary'
                                }
                                variant='outline'
                                style={{ width: "30%", marginBottom: "5px", marginLeft: "5px" }}
                                shape='square'
                                size='sm'
                                onClick={() => {
                                    handleProofsList(x)
                                }}
                            >
                                {x}
                            </CButton>
                        )}
                    </div>
                </div>
            </Form>


            <div>
                <Button variant="contained" color="primary" className='mr-5 next-Button-form' onClick={handleSubmit}>
                    Add
                </Button>
            </div>
        </div>
    );
};

export default AddNewCollege;
