import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import * as auth from "../services/adminService";
import { toast } from "react-toastify";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";

toast.configure();

const EditCourse = ({ history }) => {
    const location = useLocation();
    let courseInfo = location.state
    const [title, setTitle] = useState(courseInfo.courseTitle);
    const [courseCode, setCourseCode] = useState(courseInfo.courseCode);    
    const [description, setDesc] = useState(courseInfo.courseDescription);
    const [shortDescription, setShortDescription] = useState(courseInfo.shortDescription);
    const [benefits, setBenefits] = useState(courseInfo.courseBenefits);
    const [headings, setHeadings] = useState(courseInfo.heading);
    const [courseContentarr, setCourseContentarr] = useState(courseInfo.courseContentarr);
    const [counter, setCounter] = useState(courseInfo.courseContentarr.length);
    const [status, setStatus] = useState(courseInfo.status);
    const [length, setLength] = useState(courseInfo.courseLength);
    const [awardingBody, setAwardingBody] = useState(courseInfo.awardingBody);
    const [courseLevel, setLevel] = useState(courseInfo.courseLevel);
    const [funding, setFunding] = useState(courseInfo.funding);
    const [learningMethods, setLearning] = useState(courseInfo.learningMethods);
    const [img, setImage] = useState(courseInfo.img);

    const [validated, setValidated] = useState(false);
    
	const uploadImage = (file) => {
		const data = new FormData()
		data.append('file', file)
		data.append('upload_preset', 'evidence')
		data.append('cloud_name', 'my-free-course')
		return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
			method: 'POST',
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('URL FIles', res.url)
				return res.url
			})
	}

    const submitHandler = async (e) => {
        const form = e.currentTarget;
        let array = headings
        array.push('')
        setHeadings(array)
        array = courseContentarr
        array.push('')
        setCourseContentarr(array)

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            let images = [img]
            let promises = []
            images.forEach((image) => promises.push(uploadImage(image)))
            Promise.all([...promises])
                .then(async(values) =>{
                            
                    const response = await auth.editCourse(
                        courseInfo._id,
                        title,
                        description,
                        shortDescription,
                        benefits,
                        headings,
                        courseContentarr,
                        courseCode,
                        status,
                        length,
                        awardingBody,
                        courseLevel,
                        funding,
                        learningMethods,
                        values[0]
                    );
                    console.log(response)
                    if (response.status === 200) {
                        toast.success("Added Successully");
                        history.push('/all-courses')
                    }   
                })
           
        }
        setValidated(true);
    };
    const setHeading = (value,counter) => {
        let array = [...headings]
        array[counter] = value
        setHeadings(array)
    }

    
    const setContent = (value,counter) => {
        let array = [...courseContentarr]
        array[counter] = value
        setCourseContentarr(array)
    }

    return (
        <div className="container">
            <h2>Edit  College</h2>
			<br/>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
            <div className="row">
                <div className="col-md-6">
                <Form.Group controlId="title">
                    <Form.Label>Title </Form.Label>
                    <Form.Control
                        types="text"
                        name="title"
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group >
                    <Form.Label>Course Code </Form.Label>
                    <Form.Control
                        types="text"
                        name="code"
                        required
                        value={courseCode}
                        onChange={(e) => setCourseCode(e.target.value)}
                    ></Form.Control>
                    <Form.Text className='text-muted'>
                        Its what appears in Dropdowns everywhere on the site. Its like a short version of title
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="description">
                    <Form.Label>Description </Form.Label>
                    <Form.Control
                        required
                        name="description"
                        as="textarea"
                        rows={7}
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDesc(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                

                <Form.Group controlId="content">
                    <Form.Label>Short Description </Form.Label>
                    <Form.Control
                        as="textarea"
                        name="content"
                        rows={3}
                        required
                        placeholder="Enter Short Description"
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group>
						<Form.Label>Status</Form.Label>
						<Form.Control
							as='select'
							value={status}
							onChange={(e) => setStatus(e.target.value)}
						>
                            <option selected disabled>Choose one</option>
                            <option value="enabled">Enabled</option>
                            <option value="disabled">Disabled</option>
                        </Form.Control>
						<Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <Form.Group >
						<Form.Control
							type='file'
							accept='image/*'
							onChange={(e) => {
									setImage(e.target.files[0])
							}}
						></Form.Control>
                        <Form.Control.Feedback type='invalid'>
							Please fill in this required field.
						</Form.Control.Feedback>
					</Form.Group>
                    {img && <img src={img} />}



                </div>
                <div className="col-md-6">
                    

                <Form.Group controlId="length">
                    <Form.Label>Length</Form.Label>
                    <Form.Control
                        type="text"
                        name="length"
                        required
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="awardingBody">
                    <Form.Label>Awarding Body</Form.Label>
                    <Form.Control
                        type="text"
                        name="awardingBody"
                        required
                        value={awardingBody}
                        onChange={(e) => setAwardingBody(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="courseLevel">
                    <Form.Label>Level</Form.Label>
                    <Form.Control
                        type="text"
                        name="courseLevel"
                        required
                        value={courseLevel}
                        onChange={(e) => setLevel(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="funding">
                    <Form.Label>Funding</Form.Label>
                    <Form.Control
                        type="text"
                        name="funding"
                        required
                        value={funding}
                        onChange={(e) => setFunding(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="learningMethods">
                    <Form.Label>Learning Methods</Form.Label>
                    <Form.Control
                        type="text"
                        name="learningMethods"
                        required
                        value={learningMethods}
                        onChange={(e) => setLearning(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please fill the required field.
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group controlId="benefits">
                    <Form.Label>Benefits </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        name="benefits"
                        required
                        value={benefits}
                        onChange={(e) => setBenefits(e.target.value)}
                    ></Form.Control>
                    <Form.Text className='text-muted'>
                        For Bullets use "•" before each line and it would be converted into bullets
                    </Form.Text>
                </Form.Group>

                </div>
            </div>
                <br/>
                <hr/>
                <div>
                    <h5>Add Content</h5>
                    {[...Array(counter-1)].map((i,x) => <>
                        <Form.Group >
                            <Form.Label>Heading {x + 1}</Form.Label>
                            <Form.Control
                                type="text"
                                value={headings[x]}
                                onChange={(e) => setHeading(e.target.value,x)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Content {x + 1}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Description"
                                value={courseContentarr[x]}
                                onChange={(e) => setContent(e.target.value,x)}
                            ></Form.Control>
                            <Form.Text className='text-muted'>
                                For Bullets use "•" before each line and it would be converted into bullets
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>)}
                    <AiFillPlusCircle className="pointer-cursor" style={{fontSize:"28px",float:"right"}} onClick={()=>setCounter(counter+1)}/>
                    <AiFillMinusCircle className="pointer-cursor" style={{fontSize:"28px",float:"right"}} onClick={()=>setCounter(counter-1)}/>
                    
                </div><br/>
                <hr/>   
				<Button variant="contained" color="primary"	className='mr-5 next-Button-form' onClick={submitHandler}>
					Update Course
				</Button>
                <br/><br/>
            </Form>
        </div>
     
    );
};

export default EditCourse;
