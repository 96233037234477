import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
const makeupArtistry = () => {
    let product = {
        "courseTitle": "Level 3 Certificate in Make-Up Artistry",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665492418/Courses/Makeup_Artist_cvmycl.jpg",
        } 
    return(
        <div>
        <Helmet>
            <title>{product.courseTitle}</title>
        </Helmet>
  
      <div className="jumbotron text-center splash only-on-desktop">
          <h3 className="heading-jumbotron">{product.courseTitle}</h3>
        </div>
        <div className="only-on-mobile">
          <img
              src={product.img}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
      <div className="container-sm course-details-p" xs={12}>
        <div className="row">
          <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
            <div className="row" style={{display: "flex !important"}}>
              <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                <img
                  src={product.img}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                <h3 className="coursedetailh2" style={{margin:"auto"}}>{product.courseTitle}</h3>
              </div>
            </div>
  
            <br />
            
            <h1 className="coursedetailh2 only-on-mobile" style={{margin:"auto",fontSize: "22px",color: "#222222"}}>{product.courseTitle}</h1>
            <br/>
            <HashLink
                  className="btn btn-primary co-button vv only-on-mobile"
                  to={'/leadsTwin/'+product.courseTitle+'/#top'}
                >
                  Learn More
                </HashLink>
            <br/>
            <p >The primary outcome of the Level 3 Diploma in Make-up Artistry is to prepare you to enter
the specific sector of beauty therapy as a make-up artist. All the units in this qualification
relate directly to the specific job role of a make-up artist.</p>
            <br/>
            <p>The Level 3 Diploma in Make-up Artistry is a substantial vocational qualification that will
prepare you for a career as a make-up artist. This qualification will support you to gain
employment as a make-up artist, as the units contained in this qualification cover all the
skills and knowledge required for this role.
            </p>
            <br/>
            <p>
            This is a vocational qualification and includes all the required elements to work effectively
as a make-up artist including mandatory units covering the following: applying make-up,
instruction on make-up, fashion and photographic make-up, media make-up, camouflage
make-up and promoting products and services.
            </p>
            <br/>
            <p>This qualification will prepare you for employment as a make-up artist. You will also be
eligible to join a professional association and gain insurance as a make-up artist.</p>
            <h6><b>Who it's for:</b></h6>
            <p>For those looking to start a career in the beauty industry, specialising in make-up artistry. If
you know your gloss from your matt, have found yourself trying out your make-up skills on
your friends, or dream of working in media or salons demonstrating your skills – it’s time to
get qualified!</p>
            
            <h6><b>Progression/Career Path:</b></h6>
            <p>Career opportunities as a make-up artist exist in a variety of contexts, including:</p>
            <ul>
                <li>Working in commercial beauty salons.</li>
                <li>Working in a retail concession in a department store.</li>
                <li>Working independently/self-employed/mobile/home-based settings.</li>
             </ul>
            <br />
            {/* <h5><b>What are the costs</b></h5>
            <p>The course is funded by the Student Finance England. It is there help individuals 19+ gain
a Level 3-6 qualification without paying a lump sum upfront.</p> 
            <br/>
            <p>There are no credit checks required and you won’t have top pay anything back until you
have finished your course and only when you are earning over the threshold. The course
enrolment team will go through all the details and answer any questions you may have.</p>
            
            <h5><b>If you go on to higher education</b></h5>
            <p>If you go into higher education, Student Finance England will ‘write off’ any outstanding
Advanced Learner Loan balances you owe for an Access to HE course once you complete
a higher education course. This means you don’t have to repay it. The higher education
course must be eligible for student finance.</p>  */}
            <br/>
            <br/>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
              <Tab eventKey="home" title="Content">
                <br/>
                <b>Units Covered:</b>
                <p>You will need to cover a minimum of nine components and earn a minimum of 47 credits:</p>
                <ul>
                    <li>Apply make-up.</li>
                    <li>Instruction on make-up application.</li>
                    <li>Promote products and services to clients in a salon.</li>
                    <li>Camouflage make-up.</li>
                    <li>Media make-up.</li>
                    <li>Design and apply face and body art.</li>
                    <li>Fashion and photographic make-up.</li>
                    <li>Client care and communication in beauty-related industries.</li>
                    <li>Monitor and maintain health and safety practice in the salon.</li>
                    <li>Relevant Level 2 Award in Infection Prevention (COVID-19).</li>
                    
                </ul>
                <p>From the group of optional units, you’ll need to cover a minimum of one component and
earn a minimum of three credits.</p>
                <br/>
                </Tab>
              <Tab eventKey="benefits" title="Benefits">
              <h6><b>Benefits of this course</b></h6>
            <p>Progress into employment in a wide variety of environments from salons to day spa’s or
become self-employed
Achieve a nationally recognised level 3 qualification
Move onto further study and/or access higher education
Develop skills and knowledge through a highly specialist work-related programme of study.
Dedicated tutor support</p>
            
              </Tab>
            </Tabs>

          </div>
          <div className="col-md-4 info-box-courses">
            <Card style={{ width: "18rem", color: "black" }} className="wd">
              <Card.Body style={{ boxShadow: "none" }}>
                <Card.Title>KEY INFORMATION</Card.Title>
                <Table striped bordered>
                  <thead>
                    <tbody>
                      <tr>
                        <td>Course Length</td>
                        <td>Up to 28 weeks</td>
                      </tr>
                      <tr>
                        <td>Awarding Body</td>
                        <td>VTCT</td>
                      </tr>
                      <tr>
                        <td>Course Level</td>
                        <td>Level 3</td>
                      </tr>
                      <tr>
                        <td>Course Cost </td>
                        <td>Multiple options available</td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>London (Lewisham)</td>
                      </tr>
                    </tbody>
                  </thead>
                </Table>
                <HashLink
                  className="btn btn-primary co-button vv "
                  to={'/leadsTwin/'+product.courseTitle+'/#top'}
                >
                  Learn More
                </HashLink>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <section className="services section-bg" id="partners" style={{borderTop: "1px solid #eaeaea",marginTop: "30px",paddingTop: "0px"}}>
  <div className="container">

  <div className="row social-logo-section1" >
        <div className="col">
          <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{width:"100%"}}  alt="" />
        </div>
      </div>
      <center>
      <div className="row social-logo-section only-on-mobile" >
        <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
      </div>
      </center>
    </div>

    </section>
      </div>
    )
}

export default makeupArtistry        