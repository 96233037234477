import React, { useState, useEffect } from 'react'
import { getIncUserDetails } from '../actions/formActions'
import { CButton, CDataTable } from '@coreui/react'
import emailConfirmation from '../services/EmailService'
import sms from '../services/SmsService'
import { toast } from 'react-toastify'
import axios from 'axios'
import MaterialTable from 'material-table';

const FormsCallCenter = () => {
    
	const [forms, setForms] = React.useState([])
	const [allColleges, setAllColleges] = useState([])
    console.log(localStorage.getItem('callCenterID'))
    useEffect(async() => {
        const data = await axios.get('/college/get_all_colleges_details')
        let array = []
        let array2
        for (let i=0;i<data.data.data.length;i++){
            array.push([
                data.data.data[i]._id,data.data.data[i].collegeName
            ])
            let obj = Object.fromEntries(array)
            if (i === data.data.data.length - 1){
                array2=obj
            }
        }
        // console.log(array2)
        setAllColleges(array2)
	}, [])
    useEffect(async ()=>{
        let response = await axios.get(process.env.REACT_APP_API_URL +'/form/incompleteForCallCenter/'+localStorage.getItem('callCenterID'))
        let data = (response.data.data)
        setForms(data)
        // console.log(response)
    },[])

	const onSendProofLink = (formId, email, number) => {
emailConfirmation.sendProofEmail(
			email,
			'Last Step',
			`
            Thanks for your interest in the funded Level 2 courses.<br />
            Funded places are limited so please use the below link to complete your application.<br/>
            <br />
            <b>Complete your application in 3 simple steps.</b><br />
            1- Click the following link:<br />
            <a href="${window.location.origin}/email_completion_step_8?form_id=${formId}">${window.location.origin}/email_completion_step_8?form_id=${formId}</a> <br/>
            2- Upload your proofs and sign online<br />
            3- Press Submit to send you application<br />
            <br/> 
            Once your application is received it will be passed to the college enrolment team to complete your enrolment and give you access to your chosen course.<br />
            We hope you enjoy your course and if you need any further help, please email our support team - support@myfreecourse.co.uk.<br />
            <br />Thanks,<br />
            The My Free Course Team<br />
            Website: www.myfreecourse.co.uk<br />
        `
		)
        console.log(number)
        sms.sendSms(
			number,
            `
            Thanks for your interest in the funded course. Places on your chosen course are limited so please complete your application to avoid disappointment.
            1- Click the following link: ${window.location.origin}/email_completion_step_8?form_id=${formId}
            2- Complete the online questionnaire 
            3- Upload your proofs and submit
            The application takes minutes and you could start your course within a few days.
            Thanks,
            The My Free Course Team
        `
		)
		toast('Mail and Text Sent')
	}

    const handleStatus = async(newStatus,formId) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL + '/users/change_status', {
            formId: formId,
            status: newStatus,
        })
        if(response.status === 200){
            toast('Status Changed')
            return true
        } else {
            return false
        }       
    }



	return (
		<div className='container incomplete-container'>
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
                    <MaterialTable
                        title="Incomplete Forms"
                        columns={[
                            { title: 'First Name', field: 'firstName',editable: 'never' },
                            { title: 'Last Name', field: 'lastName',editable: 'never' },
                            { title: 'Email', field: 'email',editable: 'never' },
                            { title: 'Applied Course', field: 'appliedCourse',editable: 'never'},
                            { title: 'Mobile', field: 'mobile',editable: 'never' },
                            { title: 'Date', field: 'dateCreated',editable: 'never'},
                            { title: 'Time', field: 'timeCreated',editable: 'never'},
                            { title: 'College', field: 'collegeID',lookup: allColleges },
                            { title: 'Step', field: 'completeTill',editable: 'never'},
                            { title: 'Status', field: 'status',lookup: { "Pending": 'Pending', "Called (CB)": 'Called (CB)', "Will complete": 'Will complete', "Sent link": 'Sent link', "Not interested": 'Not interested', "Wrong number": 'Wrong number',"Last Chance":"Last Chance","Considering":"Considering","Edited":"Edited"  }}
                        ]}
                        data={forms} 
                        options={{
                            pageSize:50,
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        cellEditable={{
                            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                              return new Promise((resolve, reject) => {
                                if(columnDef.field==="status"){
                                    rowData.status = newValue;
                                    handleStatus(newValue,rowData._id)
                                }
                                setTimeout(resolve, 1000);
                              });
                            }
                          }}
                        actions={[{
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: async(event, rowData) => {
                                    event.preventDefault()
                                    await getIncUserDetails(rowData._id)
                                    window.location.href = '/update/personal'
                            }
                        },rowData => ({
                            icon: 'difference',
                            tooltip: 'Copy Link',
                            onClick: (event, rowData) => {navigator.clipboard.writeText("myfreecourse.co.uk/email_completion_step_8?form_id="+rowData._id)},
                            disabled: rowData.completeTill !== "Step 7"
                        })
                        ,rowData => ({
                            icon: 'mail',
                            tooltip: 'Send Link',
                            onClick: async(event, rowData) => {
                                event.preventDefault()
                                onSendProofLink(rowData._id, rowData.email, rowData.mobile)
                            },
                            disabled: rowData.completeTill !== "Step 7"
                        })
                          ,

                        ]}
                        />
					
				</div>
			)}
		</div>
	)
}




export default FormsCallCenter
