import React, { useEffect } from 'react'
import { deleteinFormEntry , getCompleteUserFormDetails  } from '../../actions/formActions'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialTable from 'material-table';
import { alpha } from '@material-ui/core/styles'

const DeletedInCompleteForms = () => {
	const dispatch = useDispatch()
	const [forms, setForms] = React.useState([])

    useEffect(async() => {
		const data = await axios.get('/form/deletedIncompleteForms')
        let dataa = data.data.data.sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        setForms(dataa)
	}, [])

	const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to permanently delete this application?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: () => {
                    dispatch(deleteinFormEntry(id))
                    let prevForms = forms
                    setForms(prevForms.filter(x=> x._id !== id))
                }
            }
            ]
          });
		
	}

    const handleStatus = async(formId) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL + '/users/change_status', {
            formId: formId,
            status: "Pending",
        })
        if(response.status === 200){
            toast('Application Restored')
            let prevForms = forms
            setForms(prevForms.filter(x=> x._id !== formId))
            setTimeout(function () {
                window.location.reload()
            }, 3000)
            return true
        } else {
            return false
        }       
    }

	return (
		<div className='container incomplete-container'>
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
                    <MaterialTable
                        title="Deleted Incomplete Forms"
                        columns={[
                            { title: 'First Name', field: 'firstName',editable: 'never' },
                            { title: 'Last Name', field: 'lastName',editable: 'never' },
                            { title: 'Email', field: 'email',editable: 'never' },
                            { title: 'completed Till', field: 'completeTill',editable: 'never' },
                            { title: 'Applied Course', field: 'appliedCourse',editable: 'never'},
                            { title: 'Mobile', field: 'mobile',editable: 'never' },
                            { title: 'Date', field: 'createdAt',editable: 'never'},
                            ]}
                        data={forms} 
                        options={{
                            pageSize:50,
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: 'restore',
                                tooltip: 'Restore Application',
                                onClick: (event, rowData) => {
                                    handleStatus(rowData._id)
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete Application',
                                onClick: (event, rowData) => {
                                    handleDelete(rowData._id)
                                }
                            }
                        ]}
                        />
				</div>
			)}
		</div>
	)
}

export default DeletedInCompleteForms
