import axios from 'axios'

const customAxios = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
})

class EmailService {
	sendEmail = (email, subject, text) =>
		customAxios.post('/email/sendEmail', { email, subject, text })
	sendConfirmation = (email, subject, text) =>
		customAxios.post('/email/confirmation', { email, subject, text })
	sendProofEmail = (email, subject, text) =>
		customAxios.post('/email/sendProofEmail', { email, subject, text })

	// sendProofEmail
}

export default new EmailService()
