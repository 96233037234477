import React from "react";
import ScrollToTopOnMount from "../common/ScrollToMount";

import { Helmet } from 'react-helmet'


const TITLE = 'My Free Courses'
const NCGPolicy = () => {
    return (
        <div className="container" id="responsive" style={{ marginTop: "10px" }}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <ScrollToTopOnMount />
            
            <h2>NCG Learner Privacy Notice</h2>
            <ol>
                <li>
                    <b>Overview</b>
                    <p>We ask that you read this privacy notice carefully as it contains important
                        information on who we are, how and why we collect, store, use and share
                        your personal information, your rights in relation to your personal information
                        and how to contact us and the Information Commissioner’s Office (ICO) in the
                        event you have a complaint. NCG will always process your personal data in
                        accordance with the UK General Data Protection Regulations (UK GDPR) and
                        the Data Protection Act 2018.
                    </p>
                    <br />
                    <p>NCG are made up of the following colleges:</p>
                    <ul>
                        <li>Carlisle College</li>
                        <li>Kidderminster College</li>
                        <li>Lewisham College</li>
                        <li>Newcastle College</li>
                        <li>Newcastle Sixth Form College</li>
                        <li>Southwark College</li>
                        <li>West Lancashire College</li>
                    </ul>
                    <br />
                    <p>Our main establishment is based at:
                        <br />NCG<br />
                        <br />Rye Hill House
                        <br />Scotswood Road
                        <br />Newcastle Upon Tyne
                        <br />NE4 7SA
                        <br />We are registered as a data controller with the ICO under the registration
                        number Z6977454. </p>
                </li>

                <li>
                    <b>Types of personal data</b>
                    <p>We process the following personal data about prospective, current and past learners:</p>
                    <ul>
                        <li>Identifiers - including name, date of birth, nationality, gender, NI number, student ID number and your photograph</li>
                        <li>Contact details - including address, telephone number and e-mail address</li>
                        <li>Family details - including names and contact details of parents / guardians and next of kin</li>
                        <li>Academic attainment - including prior qualifications, examination and assessment results, details of current study including progress and attendance information</li>
                        <li>Disciplinary details - academic misconduct and reasons for mitigation.</li>
                        <li>Employer details - if your employer is paying your course fees or you are enrolled on an apprenticeship</li>
                        <li>Learning Support requirements</li>
                        <li>Safeguarding referrals or investigation details where applicable</li>
                        <li>Financial details - including bank details, fee receipts, outstanding debts,household income and details of any grants or bursaries received as applicable</li>
                        <li>Images, audio and video recordings</li>
                        <li>Records of your correspondence with NCG or one of its colleges</li>
                        <li>Records of your attendance at an event hosted by NCG or one of its colleges</li>
                    </ul>

                    <p>We may process other factual information, expressions of opinion or other
                        recorded information that identifies or relates to you and your studies. </p>
                </li>

                <li>
                    <b>Special category data and criminal convictions</b>
                    <p>Some of your data is more sensitive than other data. This is called special
                        category data under the UK GDPR. Examples of special category data
                        include information that reveals the following:
                    </p>
                    <ul>
                        <li>Racial or ethnic origin</li>
                        <li>Political opinions</li>
                        <li>Religion</li>
                        <li>Philosophical beliefs</li>
                        <li>Trade union membership</li>
                        <li>Sexual orientation</li>
                        <li>Health</li>
                        <li>The processing of genetic data</li>
                        <li>The processing of biometric data</li>
                    </ul>
                    <p>NCG will only process special category data and data that reveals
                        information about criminal convictions where you have provided consent, or
                        where there is a lawful reason to do so. The NCG Special Category Data Policy is available on our website and explains our obligations to process
                        these types of data.<br />
                        To study on certain regulated courses, you may be required to carry out a
                        Disclosure Barring Service (DBS) check that discloses criminal conviction
                        data. You will be informed during the application and enrolment process
                        whether you are required to carry out a DBS check. </p>
                </li>

                <li>
                    <b>How we obtain personal data </b>
                    <p>Most of your personal data that is processed by NCG is provided directly by
                        you for one or more of the following reasons: </p>
                    <ul>
                        <li>You have subscribed to marketing communications from us</li>
                        <li>You have submitted an enquiry or complaint to us</li>
                        <li>You have applied to study with one of our colleges</li>
                        <li>You are currently or have previously been enrolled to study at one of our colleges</li>
                    </ul>
                    <br />
                    <p>We may occasionally receive your personal data indirectly from the following sources:</p>
                    <ul>
                        <li>Your Local Authority</li>
                        <li>The Police and other Government agencies</li>
                        <li>UCAS</li>
                        <li>Your employer</li>
                        <li>Student Loans Company</li>
                        <li>International Student Recruitment Agents (international applicants only)</li>
                    </ul>
                </li>

                <li>
                    <b>Why we process personal data</b>
                    <p>NCG processes your personal data for the following purposes: </p>
                    <ul>
                        <li>To send you marketing information that may be of interest to you</li>
                        <li>To respond to your enquiries and register you for events hosted by our colleges </li>
                        <li>To assess your suitability to study on a course or apprenticeship or to attend a placement</li>
                        <li>To enrol you onto your chosen course or apprenticeship and where applicable to claim funding</li>
                        <li>To deliver your chosen course of study and monitor your progress</li>
                        <li>To personalise our support to meet your individual needs</li>
                        <li>To provide facilities such as access to our libraries and IT services</li>
                        <li>To ensure security on our premises</li>
                        <li>For our statutory reporting and statistical research purposes</li>
                        <li>For funding purposes</li>
                    </ul>
                    <br />
                    <p>Data protection legislation requires NCG to have a lawful basis to process your personal data and the lawful bases that we rely on include: </p>
                    <ul>
                        <li>Consent – When you send an enquiry or subscribe to receive marketing information from us</li>
                        <li>Contract – When you enrol onto your chosen course or apprenticeship
                            you enter into a contractual agreement (your learning agreement). If you
                            choose to use services such as learning or pastoral support this would
                            also fall under your contractual agreement.</li>
                        <li>Public Task – NCG is an education institution receiving public funding. As
                            part of our public task, we are required to use your personal data for
                            statistical analysis and research purposes as well as share your personal
                            data with a range of different organisations, public sector agencies and
                            government bodies. Some of this processing will involve sharing your
                            contact details with organisations who will ask you to complete surveys.</li>
                        <li>Legal Obligation – NCG are governed by certain legislation, regulations
                            and standards that may require us to retain your personal data as well as
                            share it with a range of different organisations and public sector agencies
                            or government bodies.</li>
                        <li>Legitimate Interest – We may process your personal data if it is necessary
                            for our legitimate interests, and you would reasonably expect us to hold
                            and use this data.</li>
                        <li>Vital Interest – In an emergency we may be required to share your
                            personal details with the Emergency Services or Local Authorities.</li>
                    </ul>
                    <br />
                    <p>For more information on our lawful basis to process your personal data,
                        please see the data retention schedule in section 8.</p>
                </li>

                <li>
                    <b>When we will share personal data </b>
                    <p>We may share your information with third parties who provide services to us,
                        or directly to you on our behalf. These organisations are known as data
                        processors. We have contracts in place with our data processors that mean
                        they should not do anything with your personal data unless we have
                        instructed them to do it. They will not share your personal data with any
                        organisation apart from us without our consent, and they will ensure it is
                        stored securely for the time period that we instruct.</p>
                    <p>When you enrol at one of our colleges you enter into a contractual agreement
                        and are responsible for the payment of any fees. Therefore, if necessary, we
                        may transfer your personal data to an agent for the purposes of debt
                        Version 2.2 - Revision date: 29
                        th June 2022 - Page 5 of 11
                        collection. On occasion we may also be required to share your information
                        with the police or other government agencies.</p>
                    <p>We may disclose your information to employers for the purposes of filling
                        placement or apprenticeship vacancies. We only disclose information to
                        employers with whom we have an agreement in place that instructs them to
                        keep your information secure and not use it other than in accordance with the
                        purposes we have agreed.</p>
                    <p>We may provide factual references about you when requested from your
                        prospective employers or your next educational setting.</p>
                    <p>For learners who are 16 to 18 years old, we will share information with your
                        parent or guardian relating to your attendance and progress. This
                        information will only be shared with the parent or guardian that you provide
                        contact details for at enrolment. For learners over the age of 18, we will
                        ensure we obtain your consent prior to contacting parents or guardians for
                        these purposes unless you have a support need that requires us to do so.</p>
                    <p>If your tuition fees are paid under a sponsorship, scholarship or loan
                        arrangement by an external organisation (e.g. your employer), we may share
                        information relating to your attendance and academic progress upon request.</p>
                    <p>For the purposes of tracking NEET (Not in Education, Employment or
                        Training) learners, upon request from your previous educational setting or the
                        local authority, we may confirm to them that you are now studying with one of
                        our colleges or that you have withdrawn if you do not complete your course.</p>
                    <p>At no time will your personal data be sold to other organisations for marketing
                        or sales purposes.</p>
                    <p>As an education institution receiving public funding, we are required to share
                        your personal data with a range of different organisations, public sector
                        agencies and government bodies including, but not limited to:
                    </p>
                    <b>All Learners</b>
                    <ul>
                        <li>Department for Education (DfE)</li>
                        <li>Education Skills Funding Agency (ESFA)</li>
                        <li>Ofsted</li>
                        <li>Local Authorities</li>
                        <li>Awarding Bodies (a full list of awarding bodies can be provided to you
                            upon request)</li>
                        <li>Department for Education (DfE)</li>
                    </ul>

                    <b>Higher Education Learners</b>
                    <p>For learners who are studying on one of our higher education courses, we will
                        also share your personal data with the following:</p>
                    <ul>
                        <li>The Office for Students (OfS)</li>
                        <li>Student Loans Company</li>
                        <li>Quality Assurance Agency for Higher Education (QAA)</li>
                        <li>If studying towards a qualification that leads to professional recognition,
                            information may be shared with the relevant professional body. A list of
                            such professional bodies is available on request.
                        </li>
                        <li>Graduation ceremonies are regarded as public events and may be
                            recorded and/or live streamed. Graduate names, programmes and
                            classifications are also printed on material that is made publicly available.</li>
                    </ul>
                    <br />
                    <b>International Learners</b>
                    <p>For international students studying with us, we will also share your personal
                        data with the following:
                    </p>
                    <ul>
                        <li>Home Office</li>
                        <li>International Student Recruitment Agents</li>
                        <li>Accommodation Providers</li>
                    </ul>

                    <br />
                    <b>Apprentices</b>
                    <p>For apprentices we will also share your personal data with the following:</p>
                    <ul>
                        <li>Your employer</li>
                        <li>End point assessment organisations</li>
                        <li>The National Apprenticeship Service</li>
                    </ul>

                    <br />
                    <b>ESFA Privacy Notice</b>
                    <p>The Education and Skills Funding Agency (ESFA) require that we provide you
                        with their privacy notice. This can be found in Appendix A. You can also use
                        the link below for the most current version.</p>
                    <br />
                    <a href="https://guidance.submit-learner-data.service.gov.uk/ilrprivacynotice/">https://guidance.submit-learner-data.service.gov.uk/ilrprivacynotice/</a>
                    <br />
                    <br />
                    <b>LRS Privacy Notice </b>
                    <p>The information you supply is used by the Learning Records Service (LRS).
                        The LRS issues Unique Learner Numbers (ULN) and creates Personal
                        Learning records across England, Wales and Northern Ireland, and is
                        Version 2.2 - Revision date: 29
                        th June 2022 - Page 7 of 11
                        operated by the Education and Skills Funding Agency, an executive agency of
                        the Department for Education (DfE). For more information about how your
                        information is processed, and to access your Personal Learning Record,
                        please refer to:</p>
                    <a href="https://www.gov.uk/government/publications/lrs-privacy-notices">https://www.gov.uk/government/publications/lrs-privacy-notices</a>
                    <br />
                    <br />
                    <b>Survey Providers </b>
                    <p>As part of our Public Task requirements, we have a statutory duty to carry out
                        statistical analysis and research in the form of surveys. You may be
                        contacted by J2Research as they conduct our surveys on our behalf. On rare
                        occasions where they have not been able to contact you, they may contact
                        your next of kin.</p>
                    <br />
                    <p>For FE Students, the ESFA also require us to provide your contact details to
                        the survey provider Ipsos Mori, to carry out the FE Choices Survey.</p>
                    <p>For HE Students, the Office for Students require us to provide your contact
                        details to the Higher Education Statistics Agency (HESA) to carry out the
                        Graduate Outcomes Survey and Ipsos Mori to carry out the National Student
                        Survey (NSS). </p>
                    <p>The privacy notices for Ipsos Mori can be found via the following links:<br />
                        NSS: https://www.thestudentsurvey.com/privacy-policy/<br />
                        FE Choices: https://www.ipsos.com/ipsos-mori/en-uk/privacy-data-protection<br />
                        The privacy notice for HESA can be found via the following link:<br />
                        https://www.hesa.ac.uk/about/regulation/data-protection/notices<br />
                    </p>

                </li>
                <li>
                    <b>Transfer of data outside of the European Economic Area (EEA)</b><br />
                    <p>NCG do not routinely transfer your personal data outside of the EEA.
                        However, on rare occasions, some organisations that process data on our
                        behalf may store data in cloud services outside of the EEA. These transfers
                        will only be made to services hosted in countries with which there are
                        appropriate safeguards.
                    </p>
                </li>
                <li>
                    <b>How long we retain your personal data</b>
                    <p>You can find our personal data retention schedule at the following link:<br />
                        https://www.ncgrp.co.uk/wp-content/uploads/2017/03/Document-RetentionSchedule.pdf</p>
                </li>
                <li>
                    <b>Your rights</b><br />
                    <u>Access to information</u>
                    <p>At any point you can contact us to request details concerning the information
                        we hold about you, why we have that information, who has access to the
                        information and where we got the information. In most cases you may be
                        entitled to copies of the information we hold concerning you. Once we have
                        received your request, we will respond within one month unless we need to
                        extend that period owing to the complexity of your request.
                    </p>
                    <u>Rectifying data</u>
                    <p>If the data we hold about you is out of date, incomplete or incorrect, you can
                        inform us, and we will ensure that it is updated.
                    </p>
                    <u>Erasing data</u>
                    <p>If you feel that we should no longer be using your data or that we are illegally
                        using your data, you can request that we erase the data we hold. When we
                        receive your request, we will confirm whether the data has been deleted or
                        tell you the reason why it cannot be deleted.

                    </p>
                    <u>Restricting processing</u>
                    <p>In certain circumstances, you have the right to request that NCG stops
                        processing your data. Upon receiving the request, we will contact you to tell
                        you if we are able to comply or if we have legitimate grounds to continue. If
                        the data is no longer processed, we may continue to hold your data to comply
                        with your other rights.

                    </p>
                    <u>Data portability</u>
                    <p>You have the right to request that we transfer your data to another
                        organisation or to receive a copy of your data in a common format. Once we
                        have received your request, we will comply where it is feasible to do so.
                    </p>
                    <u>Object to direct marketing</u>
                    <p>All communication with you, including in relation to updates to this privacy
                        notice, will be made via the preferred method of communication that you
                        have registered with us, and you will be able to select the type of
                        correspondence you receive. If, at any stage, you are concerned about the
                        content (e.g. unwanted marketing), frequency or method of these
                        communications, you can unsubscribe or notify us of your decision at any
                        time.</p>
                    <u>The right to object to automated decision making / profiling</u>
                    <p>You have the right not to be subject to automated decision making (making a
                        decision solely based on automated means without any human involvement)
                        and profiling (automated processing of personal data to evaluate certain
                        things about the individual). Where this is the case, you should inform us of
                        this wish.</p>
                    <u>Withdrawing consent</u>
                    <p>In instances where we need your consent to process your information, we will
                        ask you to make a positive indication e.g. to tick a box or insert your contact
                        details on the relevant form or web page. By actively providing us with your
                        consent, you are stating that you have been informed of the type of personal
                        information that will be processed, the reasons for such processing, how it
                        will be used, for how long it will be kept, who else will have access to it and
                        what your rights are as a data subject and that you have read and
                        understood this privacy policy. Where processing is based on consent, you
                        have the right to withdraw consent at any time, and you can request this by
                        speaking to a member of staff or contacting our Data Protection Officer at the
                        contact details below.</p>
                </li>
                <li>
                    <b>Complaints to the Information Commissioner’s Office</b>
                    <p>Should you be dissatisfied with NCG’s processing of your personal data, you
                        have the right to complain to the Information Commissioner’s Office. For
                        more information, please see the Information Commissioner’s web site:
                        https://ico.org.uk/
                    </p>
                </li>
                <li>
                    <b>.Data Protection Officer</b>
                    <p>If you would like to exercise any of the rights above, have any questions that
                        you feel have not been covered by this Privacy Notice, or if you have
                        concerns or a complaint in relation to NCG processing your personal data,
                        please do not hesitate to contact us at:

                    </p>
                    <br />

                    dpo@ncgrp.co.uk<br />
                    or in writing to:<br />
                    Data Protection Officer (NCG)<br />
                    Rye Hill House<br />
                    Scotswood Road<br />
                    Newcastle Upon Tyne<br />
                    NE4 7SA<br /><br />
                    Copies of this privacy notice in alternative formats are available upon request.
                </li>
            </ol>
            
            <br />
            <br />
            <br />
            <br />
            <h2>ESFA PRIVACY NOTICE 2022/23</h2>
            <p>This privacy notice is issued by the Education and Skills Funding Agency (ESFA) on behalf of the Secretary of State for
                the Department of Education (DfE) to inform learners about the Individualised Learner Record (ILR) and how their personal
                information is used in the ILR. Your personal information is used by the DfE to exercise our functions under article 6(1)(e) of
                the UK GDPR and to meet our statutory responsibilities, including under the Apprenticeships, Skills, Children and Learning
                Act 2009.</p>

            <p>The ILR collects data about learners and learning undertaken. Publicly funded colleges, training organisations, local
                authorities, and employers (FE providers) must collect and return the data to the ESFA each year under the terms of
                a funding agreement, contract or grant agreement. It helps ensure that public money distributed through the ESFA is
                being spent in line with government targets. It is also used for education, training, employment, and well being purposes,
                including research. We retain ILR learner data for 3 years for operational purposes and 66 years for research purposes. For
                more information about the ILR and the data collected, please see the ILR specification at </p>
            <a href="https://www.gov.uk/government/collections/individualised-learner-record-ilr">https://www.gov.uk/government/collections/individualised-learner-record-ilr</a>

            <p>ILR data is shared with third parties where it complies with DfE data sharing procedures and where the law allows it.
                The DfE and the English European Social Fund (ESF) Managing Authority (or agents acting on their behalf) may contact
                learners to carry out research and evaluation to inform the effectiveness of training.
                For more information about how your personal data is used and your individual rights, please see the DfE Personal
                Information Charter (https://www.gov.uk/government/organisations/department-for-education/about/personal-informationcharter) and the ESFA Privacy Notice (https://www.gov.uk/government/publications/esfa-privacy-notice)</p>

            <p>If you would like to get in touch with us or request a copy of the personal information DfE holds about you, you can contact
                the DfE in the following ways:</p>

            <p>
                You, the student, in signing this agreement agree:
            </p>
            <ul>
                <li>Using our online contact form https://www.gov.uk/government/organisations/department-for-education/about/
                    personal-information-charter</li>
                <li>By telephoning the DfE Helpline on 0370 000 2288</li>
                <li>Or in writing to - Data Protection Officer, Ministerial and Public Communications Division, Department for Education,
                    Piccadilly Gate, Store Street, Manchester, M1 2WD</li>

            </ul>
            <br />
            <p>If you are unhappy with how we have used your personal data, you can complain to the Information Commissioner’s Office
                (ICO) at: Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF. You can also call their helpline on 0303 123 1113 or
                visit https://www.ico.org.uk</p>


        </div>
    );
};

export default NCGPolicy;
