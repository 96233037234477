import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import MaterialTable from 'material-table'
import { confirmAlert } from 'react-confirm-alert'
import { deleteAttachmentLinkById, getAttachmentLinks, getSupportAttachmentLinks } from '../../actions/attachmentLink'

toast.configure()
const AllAttachmentLinks = ({ history }) => {
	const [links, setLinks] = React.useState([])

    useEffect(async()=>{
        let res
        if(localStorage.getItem('user')==='Johno'){
            res = await getAttachmentLinks()
        } else {
            res = await getSupportAttachmentLinks(localStorage.getItem('supportID'))
        }
        setLinks(res)
    },[])
    const handleDelete = (id) =>{
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to delete this Link?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: async() => {
                    await deleteAttachmentLinkById(id)
                    let prevForms = links
                    setLinks((prevForms.filter(x=> x._id !== id)).reverse())
            }
            }
            ]
          });
    }
	return (
		<div className='container'>
            <br/><br/> 
			{links.length == 0 ? (
				<p>There are no Links</p>
			) : (
                <>
                <div>
                <MaterialTable
                    title="Attachment Links list"
                    data={links.reverse()}
                    columns={[
                        { title: 'Created At', field: 'createdAt',editable: 'never' },
                        { title: 'Email', field: 'formData[0].personalDetails.email' },
                        { title: 'Created By', field: 'agent[0].name',editable: 'never'},
                        { title: 'Completed On', field: 'completionDate',editable: 'never'},
                    ]} 
                    detailPanel={rowData => {
                        return (
                          <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <p><b>Note: </b>{rowData.note}</p>
                                    <p><b>Earning Less than: </b>{rowData.earningLessthan ? 'Yes':'No'}</p>
                                    <p><b>Permission for Uk residency: </b>{rowData.permissionforUk ? 'Yes':'No'}</p>
                                    <p><b>ID proof: </b>{rowData.proof ? 'Yes':'No'}</p>
                                    <p><b>Reciept Benefits: </b>{rowData.recieptBenefits ? 'Yes':'No'}</p>
                                    <p><b>Settlement Scheme: </b>{rowData.settlementScheme ? 'Yes':'No'}</p>
                                    <p><b>Other Proof: </b>{rowData.otherProof ? 'Yes':'No'}</p>
                                </div>
                            </div>
                          </div>
                        )
                      }}
                    options={{
                        pageSize:10,
                        filtering: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        
                        rowData => ({
                            icon: 'file_copy',
                            tooltip:'Copy the Link',
                            onClick: (event, rowData) => {navigator.clipboard.writeText("myfreecourse.co.uk/attachments-single?id="+rowData._id)}
                        }),
                        {
                            icon: 'delete',
                            isFreeAction: false,
                            tooltip: 'Delete Application',
                            onClick: (event, rowData) => {
                                handleDelete(rowData._id)
                            }
                        }

                    ]}
                        

                />
                </div>
				
            </>)}

		</div>
	)
}

export default AllAttachmentLinks
