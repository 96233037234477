import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import posts from '../common/formData'
import postLoading from '../common/postLoading'

const TITLE = 'Students List'
const StudentsList = ({ history }) => {
    const location = useLocation();
	const PostLoading = postLoading(posts)
	const [appState, setAppState] = useState({
		loading: true,
		posts: null,
	})
	const [isCollege, setIsCollege] = useState()
    const [contractId,setContractId] = useState(location.state?.id || '')
	useEffect(() => {
		if (localStorage.getItem('collegeID') !== null) {
			setIsCollege(true)
            axios.get(process.env.REACT_APP_API_URL + '/contracts/single-contract-forms/'+contractId).then((res)=>{
                setAppState({ loading: false, posts: res.data })
            })
		} else {
			setIsCollege(false)
			const apiURL = process.env.REACT_APP_API_URL + '/form/'

			fetch(apiURL)
				.then((data) => data.json())
				.then((posts) => {
					setAppState({ loading: false, posts: posts })
				})
		}
	}, [setAppState])
	return (
		<div className='studentLIst'>
			<Helmet>
				<title>{TITLE}</title>
			</Helmet>

			{isCollege && (
				<PostLoading
					isLoading={appState.loading}
					posts={appState.posts}
					collegeID={localStorage.getItem('collegeID')}
				/>
			)}
			{!isCollege && (
				<PostLoading isLoading={appState.loading} posts={appState.posts} collegeID={null} history= {history} />
			)}
		</div>
	)
}
export default StudentsList
