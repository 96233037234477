import React, {  useEffect, useState } from 'react'
import axios from 'axios'
import { Line } from 'react-chartjs-2'
import {  CDataTable } from '@coreui/react'



const CCLandingPage = () => {
    const [data, setData] = useState()
    const [accounts,setAccounts] = useState([])
    const [reports, setReports] = useState([])
    const [graphReports, setGraphReports] = useState([])
    useEffect(async() => {
		const res = await axios.get('/callcenter/getall')
        const activeAccounts = res.data.data.filter(x=>x.isActive)
        setAccounts(res.data.data)
        
		// get data
		let data = await axios.get(process.env.REACT_APP_API_URL + '/callcenter/getreports/'+localStorage.getItem('callCenterID'))
        setGraphReports(data.data)
		setData(data.data.data[0])
		setReports(data.data.reports.sort(function(a, b) {
            var textA = a._id.toUpperCase();
            var textB = b._id.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }))
        
    },[])

    const formatData = (data)=>{
        let completeCOunt = 0
        let approvedCOunt = 0
		let completedArray = []
		let approvedArray = []
		const incMappedData = data.completeData.map((d) => d.date)
		const incFormResult = [...new Set(incMappedData)]
		for (let i = 0; i < incFormResult.length; i++) {
			for (let j = 0; j < data.completeData.length; j++) {
				if (incFormResult[i] ===  data.completeData[j].date) {
					completeCOunt++
				}
			}
			completedArray.push(completeCOunt)
			completeCOunt = 0
		}
        const apprFormResult = [...new Set(incMappedData)]
        for (let i = 0; i < apprFormResult.length; i++) {
			for (let j = 0; j < data.approvedData.length; j++) {
				if (apprFormResult[i] ===  data.approvedData[j].date) {
					approvedCOunt++
				}
			}
			approvedArray.push(approvedCOunt)
			approvedCOunt = 0
        }
        // console.log()
        return {
            labels: incFormResult,
            datasets: [
              {
                label: 'Completed Forms',
                data: completedArray,
                borderColor: '#f87979',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(171,35,70,0.4)',
                hoverBorderColor: 'rgba(171,35,70,1)',
                },
              {
                label: 'Approved Forms',
                data: approvedArray,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ]}

    }
    const fields = [
		{ key: 'name' },
		'forms_today',
		'forms_yesterday',
		'forms_week',
		'forms_last_week',
		'forms_month',
        'forms_lastmonth'
	]    
    return(
        <>
        	<div className='container'>
			<h3 className='text-center'>Welcome!</h3>
			<br />
            <h4 className='text-center mt-5'>Incomplete forms details</h4>
            {reports.length === 0 ? (
                <p>Loading reports</p>
            ) : (<>{graphReports && <>
                <>
                    <div className=''>
                        <div >
                            <h4 className='text-center mt-5'>{graphReports.name}</h4>
					        <table className='table' style={{ border: '2px solid #eaeaea' }}>
						        <thead>
                                    <th>-</th>
                                    <th>Today</th>
                                    <th>Yesterday</th>
                                    <th>Week</th>
                                    <th>L.Week</th>
                                    <th>Month</th>
                                    <th>L.Month</th>
                                </thead>
						        <tbody>
                                    <tr>
                                        <td>Pending</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Pending') ?(graphReports.today).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Pending') ?(graphReports.yesterday).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Pending') ?(graphReports.week).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Pending') ?(graphReports.lastWeek).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Pending') ?(graphReports.month).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Pending') ?(graphReports.lastMonth).find((y)=> y._id==='Pending').number : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Called (CB)</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Called (CB)') ?(graphReports.today).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Called (CB)') ?(graphReports.yesterday).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Called (CB)') ?(graphReports.week).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Called (CB)') ?(graphReports.lastWeek).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Called (CB)') ?(graphReports.month).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Called (CB)') ?(graphReports.lastMonth).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Will complete</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Will complete') ?(graphReports.today).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Will complete') ?(graphReports.yesterday).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Will complete') ?(graphReports.week).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Will complete') ?(graphReports.lastWeek).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Will complete') ?(graphReports.month).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Will complete') ?(graphReports.lastMonth).find((y)=> y._id==='Will complete').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Sent link</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Sent link') ?(graphReports.today).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Sent link') ?(graphReports.yesterday).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Sent link') ?(graphReports.week).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Sent link') ?(graphReports.lastWeek).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Sent link') ?(graphReports.month).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Sent link') ?(graphReports.lastMonth).find((y)=> y._id==='Sent link').number : 0}</td>
                                    </tr>
                                    

                                    <tr>
                                        <td>Considering</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Considering') ?(graphReports.today).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Considering') ?(graphReports.yesterday).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Considering') ?(graphReports.week).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Considering') ?(graphReports.lastWeek).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Considering') ?(graphReports.month).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Considering') ?(graphReports.lastMonth).find((y)=> y._id==='Considering').number : 0}</td>
                                    </tr>
                                    

                                    <tr>
                                        <td>Wrong number</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Wrong number') ?(graphReports.today).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Wrong number') ?(graphReports.yesterday).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Wrong number') ?(graphReports.week).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Wrong number') ?(graphReports.lastWeek).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Wrong number') ?(graphReports.month).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Wrong number') ?(graphReports.lastMonth).find((y)=> y._id==='Wrong number').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Not interested</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Not interested') ?(graphReports.today).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Not interested') ?(graphReports.yesterday).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Not interested') ?(graphReports.week).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Not interested') ?(graphReports.lastWeek).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Not interested') ?(graphReports.month).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Not interested') ?(graphReports.lastMonth).find((y)=> y._id==='Not interested').number : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Deleted</td>
                                        <td>{(graphReports.today).find((y)=> y._id==='Deleted') ?(graphReports.today).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(graphReports.yesterday).find((y)=> y._id==='Deleted') ?(graphReports.yesterday).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(graphReports.week).find((y)=> y._id==='Deleted') ?(graphReports.week).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(graphReports.lastWeek).find((y)=> y._id==='Deleted') ?(graphReports.lastWeek).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(graphReports.month).find((y)=> y._id==='Deleted') ?(graphReports.month).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(graphReports.lastMonth).find((y)=> y._id==='Deleted') ?(graphReports.lastMonth).find((y)=> y._id==='Deleted').number : 0}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <br/>
                        <div >
                            <Line  data={formatData({completeData: graphReports.completeData, approvedData: graphReports.approvedData})} />
                        </div>
                        <hr/>
                    </div>
                </>
            </>}</>)}
                    <br/>
            <h4 className='text-center mt-5'>Complete Forms Detials</h4>
                    {data && <table className='table' style={{ border: '2px solid #eaeaea' }}>
					<tbody>
                        <tr>
                            <td>
                                Forms Today    
                            </td>
                            <td>
                                {data.forms_today}    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Forms Yesterday    
                            </td>
                            <td>
                                {data.forms_yesterday}    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Forms This week    
                            </td>
                            <td>
                                {data.forms_week}    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Forms This Month    
                            </td>
                            <td>
                                {data.forms_month}    
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Forms last Month    
                            </td>
                            <td>
                                {data.forms_lastmonth}    
                            </td>
                        </tr>
                    </tbody>    
                    </table>}


                    {accounts.length === 0 ? (

                <p>Loading Accounts</p>
            ) : (
                <div>
                <br/>
                    <br/>
                    <center><h3><b>Team</b></h3></center>
                    <br/>
                    <CDataTable
                        items={accounts}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={50}
                        hover
                        sorter
                        pagination
                            />
                    </div>
                )}
            
            </div>
        </>
    )
}

export default CCLandingPage
