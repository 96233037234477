import React from 'react'
import FormContainer from './Form/FormContainer'
const FTFormThankYou = () => {

    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <FormContainer>
                <div id="start-form" className='text-left mt-5 mb-3 form-notice'>
                    <h2>Congratulations!</h2>
                </div>
                    <center>
                        <br /><br />
                        <p>
                            {/* Congratulations!<br /> */}
                            You have passed the eligbility checks.<br />
                            A member of our team will check the information you have supplied and will be in touch to discuss the course and complete the application form with you,
                            <br /><br />
                            If you have a preference on a time to be called please email<br />
                            onlinelearning@myfreecourse.co.uk<br />
                            Reference - Application Appointment<br />
                            Please include<br />
                            Name<br />
                            Email<br />
                            Contact Number<br />
                            Prefered time<br />
                            <br /><br />
                            Please be advised our opening hours are Monday - Friday (8am - 5pm)
                        </p>
                    </center>
            </FormContainer >
        </>
    )
}

export default FTFormThankYou
