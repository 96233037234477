import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import FormContainer from './Form/FormContainer'
import { useHistory } from "react-router-dom";


const AddSupportStaffForm = () =>{
    let history = useHistory();
	const [validated, setValidated] = useState(false)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    
	const submitHandler = async(e) => {
        e.preventDefault();
		const form = e.currentTarget
        
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
        } else {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/support/add', {
                name: name,
                email: email,
                password: password
            })  
            if(response.status === 200){
                history.push("/all-support-staff");
            }
        }
		setValidated(true)
    }

    return(
        <>
        <FormContainer>
            <h1>Add a new Support Staff member</h1>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
                <Form.Group >
                    <Form.Label>Name </Form.Label>
                    <Form.Control
                        type='text'
                        required
                        placeholder='Enter Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill in this required field.
                    </Form.Control.Feedback>
                </Form.Group>

                
                <Form.Group >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type='email'
                        required
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill in this required field.
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        required
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill in this required field.
                    </Form.Control.Feedback>
                </Form.Group>
                
					<Button
						type='submit'
						variant='primary'
						style={{
							backgroundColor: 'green',
							borderColor: 'green',
							color: 'white',
						}}
					>
						Add Member
					</Button>
            </Form>
        </FormContainer>
        </>
    )

}

export default AddSupportStaffForm