import React from 'react'
import { toast } from 'react-toastify'
import * as admin from '../services/adminService'
import MaterialTable from 'material-table'
import { deleteCourse } from '../actions/formActions'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from 'react-bootstrap'

toast.configure()
const CourseManage = ({ history }) => {
	const [courses, setCourses] = React.useState([])
	const getcoursesData = () => {
		admin
			.getAllCourses()
			.then((res) => {
				setCourses(res.data.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	React.useEffect(getcoursesData, [])


    const handleDelete = async (id) =>{
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to delete this application?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: () => {
                    deleteCourse(id)
                    let prevForms = courses
                    setCourses((prevForms.filter(x=> x._id !== id)).reverse())
            }
            }
            ]
          });
    }
	return (
		<div className='container'>
            <br/>
            <Button
                className='button button-group-download'
                onClick={() => {
                    history.push('/addcourse')
                }}
            >
                Add new
            </Button>
            <br/><br/> 
			{courses.length == 0 ? (
				<p>There are no Courses</p>
			) : (
                <>
                <div>
                <MaterialTable
                    title="Courses list"
                    data={courses.reverse()}
                    columns={[
                        { title: 'Name', field: 'courseTitle',editable: 'never' },
                        { title: 'Course Code', field: 'courseCode'},
                        { title: 'Active', field: 'status',editable: 'never',lookup: {'enabled':"Yes",'disabled':"No"}},
                    ]} 
                    detailPanel={rowData => {
                        return (
                          <div className='container'>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <p><b>Description: </b>{rowData.courseDescription}</p>
                                    <p><b>Short Description: </b>{rowData.shortDescription}</p>
                                    <p><b>Course Benefits: </b>{rowData.courseBenefits}</p>
                                    <p><b>Course Length: </b>{rowData.courseLength}</p>
                                    <p><b>Awarding Body: </b>{rowData.awardingBody}</p>
                                    <p><b>Course Level: </b>{rowData.courseLevel}</p>
                                    <p><b>Funding: </b>{rowData.funding}</p>
                                    <p><b>Learning Methods: </b>{rowData.learningMethods}</p>
                                    
                                </div>
                            </div>
                          </div>
                        )
                      }}
                    options={{
                        pageSize:20,
                        filtering: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        rowData => ({
                            icon: 'edit',
                            tooltip:'Edit',
                            onClick: (event, rowData) => {history.push('/edit-course/?id=' + rowData._id, rowData)}
                        }),
                        {
                            icon: 'delete',
                            isFreeAction: false,
                            tooltip: 'Delete Application',
                            onClick: (event, rowData) => {
                                handleDelete(rowData._id)
                            }
                        }

                    ]}
                        

                />
                </div>
				
            </>)}

		</div>
	)
}

export default CourseManage
