import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from './Form/FormContainer'
import { getInquiryPOstcode, saveFTLeadsForm } from '../actions/formActions'
import { toast } from 'react-toastify'

const FTForm = ({ history }) => {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [PostCodeBL, setPCodeBL] = useState()
    const [mobile, setMobile] = useState()
    const [visaStatus, setVisaStatus] = useState('')
    const [earningLess, setEarningLess] = useState('')
    const [liveInLondon, setLiveInLondon] = useState('')
    const [currentlyStudying, setCurrentlyStudying] = useState('')
    const [commit, setCommit] = useState('')
    const [laptop, setLaptop] = useState('')
    const [step, setStep] = useState(0)
    const [validated, setValidated] = useState(false)


    const checkPhone = (phone) => {
        if (phone.toString().length === 11) {
            return true
        } else {
            return false
        }
    }
    function scrollTtoView() {
        document.getElementById('start-form').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const postCodeChecker = async (value) => {
        let postcd = await getInquiryPOstcode(value)
        if (!checkPhone(mobile)) {
            toast.error("Only 11 characters allowed in Phone number.")
            return true
        }
        if (postcd.name !== 'LONDON') {
            toast.error("This course is only for London.")
            return true
        }
        if (liveInLondon === 'No') {
            toast.error("This course is only for London.")
            return true
        }

        if (commit === 'No') {
            toast.error("Sorry, You do not qualify.")
            return true
        }

        if (currentlyStudying === 'Yes') {
            toast.error("Sorry, You do not qualify.")
            return true
        }

        if (earningLess === 'No') {
            toast.error("Only for someone whos earning less than £23k, Unemployed or is on benefits.")
            return true
        }
        if (laptop === 'No') {
            toast.error("Only for someone who has computer, laptop or tablet to access the course.")
            return true
        }

        return false
    }

    const submitHandler = async (e) => {
        const form = e.currentTarget
        e.preventDefault()

        if (form.checkValidity() === false) {
            e.stopPropagation()
            scrollTtoView()
        } else if (await postCodeChecker(PostCodeBL)) {
            setStep(1)
            scrollTtoView()
            e.stopPropagation()
        } else {
            let response = await saveFTLeadsForm({
                firstName,
                lastName,
                email,
                mobile,
                PostCodeBL,
                visaStatus: (visaStatus === "Yes") ? true : false,
                earningLess: (earningLess === "Yes") ? true : false,
                liveInLondon: (liveInLondon === "Yes") ? true : false
            })
            if (response.status === 200) {
                history.push('/ft-thankyou#start-form')
                scrollTtoView()
            }
        }
        setValidated(true)
    }

    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <FormContainer>
                <div id="start-form" className='text-left mt-5 mb-3 form-notice'>
                    <h2>Register your details for this course.</h2>
                </div>
                {step === 0 &&
                    <Form noValidate validated={validated} onSubmit={submitHandler}>

                        <Form.Group controlId='firstName'>
                            <Form.Label>First Name </Form.Label>
                            <Form.Control
                                type='text'
                                required
                                placeholder='Enter First Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='lastName'>
                            <Form.Label>Last Name </Form.Label>
                            <Form.Control
                                type='text'
                                required
                                placeholder='Enter Last Name'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='email'>
                            <Form.Label>Email </Form.Label>
                            <Form.Control
                                type='email'
                                required
                                placeholder='Enter Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='PostId'>
                            <Form.Label>Post Code</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                placeholder='Enter PostCode'
                                value={PostCodeBL}
                                onChange={(e) => {
                                    e.target.value = e.target.value.replace(/\s/g, '');
                                    setPCodeBL(e.target.value.toUpperCase())
                                }}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='mobile'>
                            <Form.Label>Phone </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Enter Phone Number'
                                value={mobile}
                                onChange={(e) => {
                                    const onlyNums = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                    setMobile(onlyNums);
                                }}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='earningLess'>
                            <Form.Label>
                                Do you earn under £23k or are unemployed and on benefits?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={earningLess}
                                onChange={(e) => setEarningLess(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='currentlyStudying'>
                            <Form.Label>
                                Are you currently studying a course elsewhere at the moment?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={currentlyStudying}
                                onChange={(e) => setCurrentlyStudying(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='commit'>
                            <Form.Label>
                                Are you able to commit to studying 4 Hours a day Monday to Friday for 2 weeks?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={commit}
                                onChange={(e) => setCommit(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Text className='text-muted'>
                                Course hours are between 09:30-13:30 or 12:30-16:30 Mon- Fri.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='liveInLondon'>
                            <Form.Label>
                                Are you currently living in London
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={liveInLondon}
                                onChange={(e) => setLiveInLondon(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='visaStatus'>
                            <Form.Label>
                                Have you lived in the UK for the past 3 years?
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={visaStatus}
                                onChange={(e) => setVisaStatus(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='visaStatus'>
                            <Form.Label>
                                All courses are online, please confirm you have a computer, laptop or tablet to access the course
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={laptop}
                                onChange={(e) => setLaptop(e.target.value)}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No"> No</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                            type='submit'
                            variant='primary'
                            style={{
                                backgroundColor: 'green',
                                borderColor: 'green',
                                color: 'white',
                            }}
                        >
                            Register
                        </Button>
                    </Form>}
                {step === 1 &&
                    <center>
                        <br /><br />
                        <p>Thank you for your enquiry, unfortunately you do not qualify to undertake this courses programe.
                            <br />
                            <br />
                            Reasons why you may be unsuccessful<br />
                            You earn over the threshold<br />
                            You have not lived in the UK for 3 years<br />
                            You do not currently live in London<br />
                            You do not have access to a laptop or computer<br />
                            <br /><br />
                            If you would like for this to be looked into please email<br />
                            Onlinelearning@myfreecourse.co.uk<br />
                            With the reference - Green Skills Eligibility</p>
                    </center>
                }
            </FormContainer >
        </>
    )
}

export default FTForm
