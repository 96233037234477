import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";

const StudentFormData = (props) => {
    let product = props.product
    const [contract,setContract] = useState(product.contractId)
    const [status,setStatus] = useState(product.status)
    const [course,setCourse] = useState(product.detailsFormData.appliedCourse)
    const formstyle= {
        'marginBottom':'0px',
        'marginTop':'0px'
    }
    const allStatus = ['pending','approved','Awaiting ID','Awaiting Benefits','Awaiting Income','Not Contactable','Cancel/Delete','Awaiting English Assessment','Awaiting Visa Proof','Awaiting Qualification Level','Awaiting Emergency Contact']
    
    const handleCourse = async (newCourse,formId)=>{
        let response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_course', {
            formId: formId,
            newCourse: newCourse,
        })    
        if(response.status === 200){
            toast('Course Changed')
            return true
        } else {
            return false
        }    
    }

    
    const handleCollege = async(newCollege, formId)=>{
        // in this page, college is basically contract
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_college', {
            formId: formId,
            newCollegeId: newCollege,
        })    
        if(response.status === 200){
            toast('College Changed')
            return true
        } else {
            return false
        }
    }
  
    const handleStatus = async(newStatus,formId) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_status', {
            formId: formId,
            status: newStatus,
        })
        if(response.data.success){
            toast('Status Changed')
            return true
        } else {
            toast.error(response.data.message)
            return false
        }       
    }
  
    return (
    <div  style={{ marginTop: "40px", color: "black" }}>
        <Table striped bordered className="table-show-data">
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{product.personalDetails.title}. {product.personalDetails.firstName} {product.personalDetails.middleName || ''} {product.personalDetails.lastName}</td>
                </tr>
                {!localStorage.getItem('collegeID') && <>
                    <tr>
                    <td>College</td>
                    <td>
                        <Form.Group style={formstyle}>
                            <Form.Control
                                as='select'
                                required
                                value={contract}
                                onChange={(e) => {
                                    setContract(e.target.value)
                                    handleCollege(e.target.value,product._id)
                                }}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                {props.college.map(x=><option value={x._id}>{x.name}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
					    </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td>Course</td>
                    <td>
                        <Form.Group  style={formstyle}   >
                            <Form.Control
                                as='select'
                                required
                                value={course}
                                onChange={(e) => {
                                    setCourse(e.target.value)
                                    handleCourse(e.target.value,product._id)
                                }}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                {props.courses.map(x=><option value={x.courseCode}>{x.courseTitle}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
					    </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>
                        <Form.Group  style={formstyle}>
                            <Form.Control
                                as='select'
                                required
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                    handleStatus(e.target.value,product._id)
                                }}
                            >
                                <option disabled selected value=''>
                                    [Please select one]
                                </option>
                                {allStatus.map(x=> <option value={x}>{x}</option>)}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
					    </Form.Group></td>
                </tr>
                </>}
                
                {(product.callTime.length>0) && (
                    <tr>
                        <td>Prefered Call Time</td>
                        <td>
                            <ul>
                                {product.callTime.map(x=><li>{x}</li>)}
                            </ul>
                        </td>
                    </tr>
                )}
                {(product.learningCurveSuccess) && (
                    <tr>
                        <td>Sending Learning Curve Application was successfull</td>
                        <td>
                            {product.learningCurveSuccess? "Yes":"No"}
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
      
      <br/>
      <div className="row">
        <div className="col-6">
            <h5>Personal Details</h5>
        <Table striped bordered className="table-show-data">
        <tbody>
          <tr>
            <td>Title</td>
            <td>{product.personalDetails.title}</td>
          </tr>
          <tr>
            <td>First Name</td>
            <td>{product.personalDetails.firstName}</td>
          </tr>
          <tr>
            <td>Middle Name</td>
            <td>{product.personalDetails.middleName}</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>{product.personalDetails.lastName}</td>
          </tr>
          <tr>
            <td>Applied Course</td>
            <td>{product.detailsFormData.appliedCourse}</td>
          </tr>
          <tr>
            <td>National Insurance Number</td>
            <td>{product.personalDetails.nationalInsNo}</td>
          </tr>
          <tr>
            <td>Years at Address</td>
            <td>{product.personalDetails.yearsAtAdd}</td>
          </tr>
          <tr>
            <td>Telephone</td>
            <td>{product.personalDetails.telephone}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{product.personalDetails.email}</td>
          </tr>
          <tr>
            <td>PostCode</td>
            <td>{product.personalDetails.postcode}</td>
          </tr>
          {product.personalDetails.oldPostcode && <tr>
            <td>Old PostCode</td>
            <td>{product.personalDetails.oldPostcode}</td>
          </tr>}
          <tr>
            <td>Gender</td>
            <td>{product.personalDetails.gender}</td>
          </tr>
          <tr>
            <td>Date Of Birth</td>
            <td>{(moment(product.personalDetails.dob)).format('DD/MM/YYYY')}</td>
          </tr>
          <tr>
            <td>Address Line</td>
            <td>{product.personalDetails.addLine1}</td>
          </tr>
          <tr>
            <td>Age</td>
            <td>{product.personalDetails.age}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{product.personalDetails.city}</td>
          </tr>
          <tr>
            <td>County</td>
            <td>{product.personalDetails.county}</td>
          </tr>
            <tr>
                <td>National ID</td>
                <td><img src={product.nationalId} alt="" style={{width: "350px"}}/>
                <br/>{product.nationalId}</td>
            </tr>
            <tr>
                <td>Other Attachment</td>
                <td><img src={product.certificateLink} alt="" style={{width: "350px"}}/>
                <br/>{product.certificateLink}</td>
            </tr>
        </tbody>
      </Table>
      
      <br/>
            <h5>Employment Details Details</h5>
            <Table striped bordered className="table-show-data">
                <tbody>
                <tr>
                    <td>Employment Status</td>
                    <td>{product.employmentDetails.employementStatus}</td>
                </tr>
                <tr>
                    <td>Employment Name</td>
                    <td>{product.employmentDetails.employerName}</td>
                </tr>
                <tr>
                    <td>Employment Address</td>
                    <td>{product.employmentDetails.employerAdd}</td>
                </tr>
                <tr>
                    <td>Employment postcode</td>
                    <td>{product.employmentDetails.postcode}</td>
                </tr>
                <tr>
                    <td>Employment Phone</td>
                    <td>{product.employmentDetails.ph}</td>
                </tr>
                <tr>
                    <td>Hours per Week</td>
                    <td>{product.employmentDetails.hoursPerWeek}</td>
                </tr>
                <tr>
                    <td>Length</td>
                    <td>{product.employmentDetails.length}</td>
                </tr>
                {product.employmentDetails.unemployedLength && <tr>
                    <td>Unemployment Length</td>
                    <td>{product.employmentDetails.unemployedLength}</td>
                    </tr>}
                <tr>
                    <td>Looking for Work</td>
                    <td>{product.employmentDetails.lookingForWork}</td>
                </tr>
                <tr>
                    <td>Benefits</td>
                    <td>{product.employmentDetails.benefits}</td>
                </tr>

                {product.employmentDetails.dValue1 && <tr>
                    <td>JSA</td>
                    <td>{product.employmentDetails.dValue1}</td>
                    </tr>}
                {product.employmentDetails.dValue2 && <tr>
                    <td>Income Support</td>
                    <td>{product.employmentDetails.dValue2}</td>
                    </tr>}
                {product.employmentDetails.dValue3 && <tr>
                    <td>Council Tax Benefit</td>
                    <td>{product.employmentDetails.dValue3}</td>
                    </tr>}
                {product.employmentDetails.dValue5 && <tr>
                    <td>ESA (Any)</td>
                    <td>{product.employmentDetails.dValue5}</td>
                    </tr>}
                {product.employmentDetails.dValue6 && <tr>
                    <td>Incapacity Benefit</td>
                    <td>{product.employmentDetails.dValue6}</td>
                    </tr>}
                {product.employmentDetails.dValue7 && <tr>
                    <td>Housing Benefit</td>
                    <td>{product.employmentDetails.dValue7}</td>
                    </tr>}
                {product.employmentDetails.dValue8 && <tr>
                    <td>Universal Credit</td>
                    <td>{product.employmentDetails.dValue8}</td>
                    </tr>}
                {product.employmentDetails.dValue9 && <tr>
                    <td>Employed but on low wage</td>
                    <td>{product.employmentDetails.dValue9}</td>
                    </tr>}
                {product.employmentDetails.dValue10 && <tr>
                    <td>Are you retired?</td>
                    <td>{product.employmentDetails.dValue10}</td>
                    </tr>}
                    <tr>
                        <td>Evidence 1</td>
                        <td><img src={product.employmentDetails.evidence} alt="" style={{width: "350px"}}/>
                        <br/>{product.employmentDetails.evidence}
                        </td>
                    </tr>
                    <tr>
                        <td>Additional Evidence 1 (if any)</td>
                        <td><img src={product.employmentDetails.evidence1} alt="" style={{width: "350px"}}/>
                        <br/>{product.employmentDetails.evidence1}
                        </td>
                    </tr>
                    <tr>
                        <td>Additional Evidence 2 (if any)</td>
                        <td><img src={product.employmentDetails.evidence2} alt="" style={{width: "350px"}}/>
                        <br/>{product.employmentDetails.evidence2}</td>
                    </tr>
                </tbody>
            </Table>
        </div>



        <div className="col-6">
            
            <h5>Emergency Contact Details</h5>
            <Table striped bordered className="table-show-data">
                <tbody>
                                
                    <tr>
                        <td>Emergency Contact Name</td>
                        <td>{product.emergencyDetails.emergencyContactName}</td>
                    </tr>
                    <tr>
                        <td>Emergency Contact Number</td>
                        <td>{product.emergencyDetails.emergencyTelephone}</td>
                    </tr>
                    <tr>
                        <td>Relation to Learner</td>
                        <td>{product.emergencyDetails.relationshipToLearner}</td>
                    </tr>
                </tbody>
            </Table>
            <br/>
            <h5>Qualification Details</h5>
            <Table striped bordered className="table-show-data">
                <tbody>
                                
                    <tr>
                        <td>Highest Qualification Level</td>
                        <td>{product.qualificationDetails.level}</td>
                    </tr>
                    <tr>
                        <td>English Grades</td>
                        <td>{product.qualificationDetails.englishGrades}</td>
                    </tr>
                    <tr>
                        <td>GCSE English above</td>
                        <td>{product.qualificationDetails.GCSEEnglishabove ? "Yes":"No"}</td>
                    </tr>
                    <tr>
                        <td>Math Grades</td>
                        <td>{product.qualificationDetails.mathGrades}</td>
                    </tr>
                    <tr>
                        <td>GCSE Maths above</td>
                        <td>{product.qualificationDetails.GCSEMathsabove ? "Yes":"No"}</td>
                    </tr>
                    <tr>
                        <td>Full time education elsewhere</td>
                        <td>{product.qualificationDetails.fullTimeEducationElse}</td>
                    </tr>
                </tbody>
            </Table>
            
            <br/>
            <h5>Oppertunities</h5>
            <Table striped bordered className="table-show-data">
                <tbody>        
                    <tr>
                        <td>Ethnic Origin</td>
                        <td>{product.oppDetails.ethnicOrigin}</td>
                    </tr>
                    <tr>
                        <td>Do you have any disabilities or learning difficulties?</td>
                        <td>{product.oppDetails.disabilities}</td>
                    </tr>
                    <tr>
                        <td>is English your first language?</td>
                        <td>{product.oppDetails.firstLang}</td>
                    </tr>
                    <tr>
                        <td>Nationality</td>
                        <td>{product.oppDetails.nationality}</td>
                    </tr>
                    <tr>
                        <td>Have you been resident in the UK for the last 3 years?</td>
                        <td>{product.oppDetails.resident}</td>
                    </tr>
                    <tr>
                        <td>Have you obtained settled status under then EU Settlement Scheme?</td>
                        <td>{product.oppDetails.statementScheme}</td>
                    </tr>
                    <tr>
                        <td>Are you a wheelchair user?</td>
                        <td>{product.oppDetails.wheelchair}</td>
                    </tr>
                    <tr>
                        <td>Are you a non EEA citizen with permission from the UK government to live in the UK?</td>
                        <td>{product.oppDetails.nonEEACitizen}</td>
                    </tr>
                    <tr>
                        <td>Do you have any criminal convictions, cautions or pending prosecutions?</td>
                        <td>{product.oppDetails.criminalConv}</td>
                    </tr>
                    <tr>
                        <td>Equal Oppertunity attachment 1 (if any)</td>
                        <td><img src={product.oppDetails.attachment1} alt="" style={{width: "350px"}}/>
                        <br/>{product.oppDetails.attachment1}
                        </td>
                    </tr>
                    <tr>
                        <td>Equal Oppertunity attachment 2 (if any)</td>
                        <td><img src={product.oppDetails.attachment2} alt="" style={{width: "350px"}}/>
                        <br/>{product.oppDetails.attachment2}
                        </td>
                    </tr>
                    <tr>
                        <td>Equal Oppertunity attachment 3 (if any)</td>
                        <td><img src={product.oppDetails.attachment3} alt="" style={{width: "350px"}}/>
                        <br/>{product.oppDetails.attachment3}
                        </td>
                    </tr>
                    <tr>
                        <td>Equal Oppertunity attachment 4 (if any)</td>
                        <td><img src={product.oppDetails.attachment4} alt="" style={{width: "350px"}}/>
                        <br/>{product.oppDetails.attachment4}
                        </td>
                    </tr>
                </tbody>
            </Table>
            
            { product.assessment && (
                <Table striped bordered className="table-show-data">
                    <tbody>
                        <tr>
                            <td>Assessment: English</td>
                            <td><p>{product.assessment.english}</p></td>
                        </tr>
                    </tbody>
                </Table>
          )} 
        </div>
      </div>
    </div>
  );
};

export default StudentFormData;
