import { useEffect, useState } from "react"
import { AddNewContractCollegeSelf, getAllCourses } from "../services/adminService"
import { Button, Form, Spinner } from "react-bootstrap"
import { CButton } from "@coreui/react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import SignaturePad from 'react-signature-canvas'
import axios from "axios"
import CollegeConditions from "./collegeTermsContent"


const MakeNewContract = () => {
    // const history = useHistory()

    const [coursesList, setCoursesList] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [whitelist, setWhitelist] = useState([])
    const [loading, setLoading] = useState(false)
    const [contractName, setContractName] = useState()
    const [nameOfRepresentative, setNameOfRepresentative] = useState()
    const [position, setPosition] = useState()
    const [invoicingEmail, setInvoicingEmail] = useState()
    const [signature, setTrimmed] = useState()
    const [sigPad, setSigPad] = useState({})
    const [notes, setNotes] = useState()
    const [monthlyApplication, setMonthlyApplication] = useState();
    const [startDate, setStartDate] = useState()
    const [contractLength, setContractLength] = useState();
    const [validated, setValidated] = useState(false)
    const [msg, setMsg] = useState()
    const [postcode, setPostcode] = useState()
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState()
    const [website, setWebsite] = useState()
    const [invoicingNumber, setInvoicingNumber] = useState()

    useEffect(() => {
        axios
            .post(process.env.REACT_APP_API_URL + '/college/get_college_by_id', {
                collegeID: localStorage.getItem('collegeID'),
            })
            .then((res) => {
                setPostcode(res.data.data.postcode)
                setPhone(res.data.data.phone)
                setAddress(res.data.data.address)
                setWebsite(res.data.data.website)
                setInvoicingNumber(res.data.data.invoicingNumber)
                setInvoicingEmail(res.data.data.invoicingEmail)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        getAllCourses().then((res) => setAllCourses(res.data.data))
    }, [])

    const allAreas = [
        { name: 'National Non Devolved', code: 'Normal' },
        { name: 'WMCA', code: 'Wmca' },
        { name: 'WECA', code: 'Weca' },
        { name: 'TVCA', code: 'Tvca' },
        { name: 'SCRCA', code: 'Scrca' },
        { name: 'NTCA', code: 'Ntca' },
        { name: 'WYCA', code: 'Wyca' },
        { name: 'GLA', code: 'London' },
        { name: 'LCRA', code: 'Lcra' },
        { name: 'GMCA', code: 'Gmca' },
        { name: 'CPCA', code: 'Cpca' },
    ]

    const trim = async () => {
        const response = sigPad.getTrimmedCanvas().toDataURL('image/png')
        const url = await uploadImage(response, 'signatures')
        console.log("function called", url)
        setTrimmed(url)
        setMsg(true)
    }


    const clearSig = () => {
        sigPad.clear()
        setMsg(false)
    }

    const uploadImage = async (file, folderName) => {
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', folderName)
        data.append('cloud_name', 'my-free-course')
        const res = await fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        });
        const res_1 = await res.json();
        setLoading(false);
        return res_1.url;
    }


    const handleAreas = (string) => {
        if (whitelist.includes(string)) {
            const temp = whitelist.filter(function (item) {
                return item !== string
            })
            setWhitelist(temp)
        } else {
            const array = [...whitelist]
            array.push(string)
            setWhitelist(array)
        }
    }


    const handleCoursesList = (string) => {
        if (coursesList.includes(string)) {
            const temp = coursesList.filter(function (item) {
                return item !== string
            })
            setCoursesList(temp)
        } else {
            const array = [...coursesList]
            array.push(string)
            setCoursesList(array)
        }
    }


    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        setLoading(true)
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setLoading(false)
        } else {
            AddNewContractCollegeSelf(
                contractName,
                notes,
                coursesList,
                whitelist,
                monthlyApplication,
                startDate,
                contractLength,
                localStorage.getItem("collegeID"),
                nameOfRepresentative,
                position,
                invoicingEmail,
                signature,
                postcode,
                address,
                website,
                invoicingNumber,
                phone
            ).then(x => {
                console.log(x)
                if (x.status === 200) {
                    setLoading(false)
                    toast("Successfully submitted.")
                    window.location.href = "/collegehome"
                } else {
                    setLoading(false)
                    toast("Error! Contact Help.")
                }
            })
        }
        setValidated(true)
    }




    return (<>

        <div className="container">
            <h2>Add New Contract</h2>
            <br />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Contract Name</Form.Label>
                    <Form.Control
                        type='text'
                        required
                        placeholder='Enter Contract Name'
                        value={contractName}
                        onChange={(e) => setContractName(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill in this required field.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Enter notes about this contract'
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Please fill in this required field.
                    </Form.Control.Feedback>
                </Form.Group>


                <div className="row">
                    <div className="col-md-4">
                        <Form.Group controlId='dob'>
                            <Form.Label>Start Date </Form.Label>
                            <Form.Control
                                type='date'
                                required
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                min={new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)} // Minimum date is 4 days from now
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group>
                            <Form.Label>Monthly Applications</Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={monthlyApplication}
                                onChange={(e) => setMonthlyApplication(e.target.value)}
                            >
                                <option selected disabled value=''>[Please Select One]</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={250}>250</option>
                                <option value={500}>500</option>
                                <option value={750}>750</option>
                                <option value={1000}>1000</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group>
                            <Form.Label>Contract Length</Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={contractLength}
                                onChange={(e) => setContractLength(e.target.value)}
                            >
                                <option selected disabled value=''>[Please Select One]</option>
                                <option value={1}>1 Month</option>
                                <option value={3}>3 Month</option>
                                <option value={6}>6 Month</option>
                                <option value={9}>9 Month</option>
                                <option value={12}>12 Month</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>
                </div>

                <h3>Contract Information</h3><br />
                <div className="row">
                    <div className="col-md-6">
                        <b>Select target areas</b>
                        <p>(Selected are in Green)</p>
                        {allAreas.map(x =>
                            <CButton
                                key={x.code}
                                variant='outline'
                                color={
                                    whitelist.includes(x.code) ? 'success' : 'primary'
                                }
                                style={{ width: "30%", marginBottom: "5px", marginLeft: "5px" }}
                                onClick={() => {
                                    handleAreas(x.code)
                                }}
                            >
                                {x.name}
                            </CButton>
                        )}
                        <br />
                        <br />
                        <Form.Group>
                            <Form.Label>Name Of Representative</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={nameOfRepresentative}
                                onChange={(e) => setNameOfRepresentative(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={position}
                                onChange={(e) => setPosition(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Invoicing Email</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={invoicingEmail}
                                onChange={(e) => setInvoicingEmail(e.target.value)}
                            ></Form.Control>

                            <Form.Text className='text-muted'>
                                Add multiple and separate by commas.
                            </Form.Text>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Please Sign The Application</Form.Label>
                            <SignaturePad
                                id='canvas'
                                required
                                penColor='black'
                                onEnd={trim}
                                ref={(ref) => {
                                    setSigPad(ref)
                                }}
                                canvasProps={{
                                    width: 500,
                                    height: 200,
                                    className: 'sigCanvas nomargin',
                                }}
                            />
                            <br />
                            {/* <Button  className='mr-5 next-Button-form' >
						Sign {' '}
					</Button> */}
                            <Button className='back-Button-form' onClick={clearSig}>
                                Clear Signature
                            </Button>
                            <br />


                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                            <br />
                            {msg && <p><b>Your signature has been accepted. Please click the ‘Send Application’ button below.</b></p>}
                            <br />
                        </Form.Group>
                        <hr />
                        <Form.Group>
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                value={postcode}
                                onChange={(e) => setPostcode(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Invoicing Number</Form.Label>
                            <Form.Control
                                type='text'
                                required
                                value={invoicingNumber}
                                onChange={(e) => setInvoicingNumber(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-md-6">
                        <b>Select provided courses</b>
                        <p>(Selected are filled Green)</p>
                        {allCourses.map(x =>
                            <CButton
                                key={x.courseCode}
                                variant='outline'
                                color={
                                    coursesList.includes(x.courseCode) ? 'success' : 'primary'
                                }
                                style={{ width: "100%", marginBottom: "5px" }}
                                onClick={() => {
                                    handleCoursesList(x.courseCode)
                                }}
                            >
                                {x.courseTitle}
                            </CButton>
                        )}
                    </div>
                </div>
                <br />
                <br />
                <div className="college-terms-small-box" >
                    <CollegeConditions />
                </div>
                <br />
                <br />

                <Button variant="contained" type="submit" className='mr-5 next-Button-form' style={{ float: "right" }}>Complete Setup</Button>

            </Form>
        </div>
        {loading && <div id='spinner-finalstep' style={{ zIndex: '999' }}>
            <center>
                <Spinner animation='border' />
            </center>
        </div>}
    </>)
}

export default MakeNewContract    