import React from "react";

import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const FTCollegeCourse = () => {
    return (
        <div>
            <Helmet>
                <title>EMPLOYABILITY SKILLS & Climate change</title>
            </Helmet>

            <div className="jumbotron text-center splash only-on-desktop">
                <h3 className="heading-jumbotron">Green Skills Employability Program</h3>
            </div>
            <div className="only-on-mobile">
                <img
                    src="https://res.cloudinary.com/my-free-course/image/upload/v1715171108/Courses/FREE_Courses_buvv14.jpg"
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
            <div className="container-sm course-details-p" xs={12}>
                <div className="row">
                    <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
                        <div className="row" style={{ display: "flex !important" }}>
                            <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                                <img
                                    src="https://res.cloudinary.com/my-free-course/image/upload/v1715171108/Courses/FREE_Courses_buvv14.jpg"
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                                <h3 className="coursedetailh2" style={{ margin: "auto" }}>Green Skills Employability Program</h3>
                            </div>
                        </div>

                        <br />

                        <h1 className="coursedetailh2 only-on-mobile" style={{ margin: "auto", fontSize: "22px", color: "#222222" }}>Green Skills Employability Program</h1>
                        <br />
                        <HashLink
                            className="btn btn-primary co-button vv only-on-mobile"
                            to={'/ft-leads/#start-form'}
                        >Apply Now
                        </HashLink>
                        <br />
                        <p>
                            We are currently offering an opportunity to people who are living in London to help them gain qualifications and find employment. The program will help people find rewarding careers within the sustainability and green sectors. This includes construction, finance
                            and business services, digital, hospitality, energy and environment services. Our program is funded by the Mayor of London’s Jobs and Skills for Londoners Fund.
                            <br />
                            <br />
                            Our Employability Skills qualifications are designed to support you in successfully gaining a job, progressing in a chosen field, preparing for further study, and supporting the development of techniques required for successful independent living.
                        </p>
                        <br />
                        <br />
                        <br />
                        <Tabs defaultActiveKey="EmploymentSkills" id="uncontrolled-tab-example">
                            <Tab eventKey="EmploymentSkills" title="Employment Skills">
                                <br />

                                This course has been designed to help people gain more confidence for when they enter the world of work. By helping to develop and enhance skills like communication and personal effectiveness which are beneficial for a productive working environment
                                <br />
                                <br />
                                <b>Units within the course</b>
                                <ul>
                                    <li>Health and wellbeing in the workplace</li>
                                    <li>Mindset</li>
                                    <li>Problem solving</li>
                                    <li>Time Management</li>
                                    <li>Assertiveness</li>
                                    <li>Business and customer Awareness</li>
                                    <li>Writing a CV</li>
                                    <li>Job and training Search Skills</li>
                                    <li>Job Application Skills</li>
                                </ul>

                            </Tab>
                            <Tab eventKey="ClimateChange" title="Climate Change & Environmental Awareness">
                                <br />
                                <p>Designed to provide learner with an introduction into the principles of climate change and environmental awareness. It will ensure learners understand the nature of climate change and the role they can play in helping to reduce carbon emissions. They will gain the knowledge and understanding to act as drivers of positive change within the workplace and at home
                                </p>
                                <br />
                                <br />
                                <b>Units within the course</b>
                                <ul>
                                    <li>Understand Climate change, Sustainability and environmental protection</li>
                                    <li>Understand industry and the environment</li>
                                    <li>Understand resource efficiency and waste management</li>
                                </ul>
                            </Tab>
                        </Tabs>
                        <br />
                        <hr />
                        <br />
                        <h3>Additional Information</h3>
                        <br />
                        <b><h4>ENTRY REQUIREMENTS</h4></b>
                        <ul>
                            <li>AGED 19+</li>
                            <li>Living in and residing in London</li>
                            <li>Are eligible to work in the UK</li>
                            <li>Are not studying a funded course already</li>
                            <li>Are unemployed and on benefits or earning under £23K</li>
                            <li>Have lived in the UK for the last 3 years</li>
                            <li>Have a computer or laptop to access the course</li>
                        </ul>

                        <br />
                        <br />

                        <b>Initial Assessments</b>
                        <ul>
                            <li>
                                1 Attempt
                            </li>
                            <li>
                                Requires an Entry level 3 or above for ICT to work remotely.
                            </li>
                            <li>
                                Requires a level 1 for English
                            </li>
                        </ul>
                        <br />
                        <b>Course Start Dates</b>
                        <p>Once you have completed your enrolment call, you will be given a start date. The course takes 2 weeks to complete and requires 4 hours between 9:30-13:30 or 12:30 -16:30 Monday - Friday.</p>

                    </div>

                    <div className="col-md-4 info-box-courses">
                        <Card style={{ width: "18rem", color: "black" }} className="wd">
                            <Card.Body style={{ boxShadow: "none" }}>
                                <Card.Title>KEY INFORMATION</Card.Title>
                                <Table striped bordered>
                                    <tbody>
                                        <tr>
                                            <td>Course Length</td>
                                            <td>15 Days</td>
                                        </tr>
                                        <tr>
                                            <td>Awarding Body</td>
                                            <td>NCFE</td>
                                        </tr>
                                        <tr>
                                            <td>Course Level</td>
                                            <td>Combined Level 1 and 2</td>
                                        </tr>
                                        <tr>
                                            <td>Funding (subject to availability)</td>
                                            <td>Funded</td>
                                        </tr>
                                        <tr>
                                            <td>Learning Method(s)</td>
                                            <td>Online</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <HashLink
                                    className="btn btn-primary co-button vv "
                                    to={'/ft-leads/#start-form'}
                                >
                                    Apply Now
                                </HashLink>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FTCollegeCourse;
