import axios from "axios"
import { useEffect, useState } from "react"
import { getAllCourses } from "../services/adminService"
import { Button, Form, Spinner } from "react-bootstrap"
import { CButton } from "@coreui/react"
import { completeSignupContract } from "../actions/formActions"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

const CollegeCompleteSignup = () => {
    const history = useHistory()

    const [coursesList, setCoursesList] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [whitelist, setWhitelist] = useState([])
    const [pdf, setPDF] = useState()
    const [loading, setLoading] = useState(false)
    const [proofs, setProofs] = useState([])
    const [contractID, setContractID] = useState('')
    const [validated, setValidated] = useState(false)


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/contracts/single-college/' + localStorage.getItem('collegeID')).then(res => {
            if (res.data.data[0].signupTemporary === false || !res.data.data[0].signupTemporary) {
                window.location.href = "/collegehome"
            } else {
                setContractID(res.data.data[0]._id)
            }
        })
    }, [])

    useEffect(() => {
        getAllCourses().then((res) => setAllCourses(res.data.data))
    }, [])

    const uploadImage = (file) => {
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'evidence')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                console.log('URL FIles', res.url)
                setLoading(false);
                return res.url
            })
    }

    let allProofs = ["Passport", "Birth certificate", "Drivers licence", "Utility Bill", "Bank statement"]

    const handleProofsList = (string) => {
        if (proofs.includes(string)) {
            let temp = proofs.filter(function (item) {
                return item !== string
            })
            setProofs(temp)
        } else {
            let array = [...proofs]
            array.push(string)
            setProofs(array)
        }
    }

    const allAreas = [
        { name: 'National Non Devolved', code: 'Normal' },
        { name: 'WMCA', code: 'Wmca' },
        { name: 'WECA', code: 'Weca' },
        { name: 'TVCA', code: 'Tvca' },
        { name: 'SCRCA', code: 'Scrca' },
        { name: 'NTCA', code: 'Ntca' },
        { name: 'WYCA', code: 'Wyca' },
        { name: 'GLA', code: 'London' },
        { name: 'LCRA', code: 'Lcra' },
        { name: 'GMCA', code: 'Gmca' },
        { name: 'CPCA', code: 'Cpca' },
    ]


    const handleAreas = (string) => {
        if (whitelist.includes(string)) {
            const temp = whitelist.filter(function (item) {
                return item !== string
            })
            setWhitelist(temp)
        } else {
            const array = [...whitelist]
            array.push(string)
            setWhitelist(array)
        }
    }


    const handleCoursesList = (string) => {
        if (coursesList.includes(string)) {
            const temp = coursesList.filter(function (item) {
                return item !== string
            })
            setCoursesList(temp)
        } else {
            const array = [...coursesList]
            array.push(string)
            setCoursesList(array)
        }
    }

   
    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        setLoading(true)
        if (form.checkValidity() === false) {
            event.stopPropagation()
        setLoading(false)
        } else {
            completeSignupContract({
                coursesList,
                whitelist,
                pdf,
                proofs,
                collegeID: localStorage.getItem("collegeID"),
                contractID: contractID,
            }).then(x => {
                if (x.success) {
                    setLoading(false)
                    toast("Successfully submitted.")
                    window.location.href = "/collegehome"
                } else {
                    setLoading(false)
                    toast("Error! Contact Help.")
                }
            })
        }
        setValidated(true)
    }




    return (<>

        <div className="container">
            <h2>Add New  College</h2>
            <br />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="title">
                    <Form.Label><b>Please upload your Enrolment Form PDF</b>  </Form.Label>
                    <br />
                    <input
                        accept='application/pdf'
                        type="file"
                        required
                        onChange={async (e) => {
                            setPDF(await uploadImage(e.target.files[0], 'evidence'))
                        }}
                    />
                </Form.Group>
                <br />
                <br />
                <b>Please choose acceptable ID proofs</b>
                <p>(Selected are Green)</p>
                {allProofs.map(x =>
                    <CButton
                        key={x}
                        color={
                            proofs.includes(x) ? 'success' : 'primary'
                        }
                        variant='outline'
                        style={{ width: "30%", marginBottom: "5px", marginLeft: "5px" }}
                        onClick={() => {
                            handleProofsList(x)
                        }}
                    >
                        {x}
                    </CButton>
                )}

                <br />
                <br />

                <h3>Contract Information</h3><br />
                <div className="row">
                    <div className="col-md-6">
                        <b>Select target areas</b>
                        <p>(Selected are in Green)</p>
                        {allAreas.map(x =>
                            <CButton
                                key={x.code}
                                variant='outline'
                                color={
                                    whitelist.includes(x.code) ? 'success' : 'primary'
                                }
                                style={{ width: "30%", marginBottom: "5px", marginLeft: "5px" }}
                                onClick={() => {
                                    handleAreas(x.code)
                                }}
                            >
                                {x.name}
                            </CButton>
                        )}
                        <br />

                    </div>
                    <div className="col-md-6">
                        <b>Select provided courses</b>
                        <p>(Selected are filled Green)</p>
                        {allCourses.map(x =>
                            <CButton
                                key={x.courseCode}
                                variant='outline'
                                color={
                                    coursesList.includes(x.courseCode) ? 'success' : 'primary'
                                }
                                style={{ width: "100%", marginBottom: "5px" }}
                                onClick={() => {
                                    handleCoursesList(x.courseCode)
                                }}
                            >
                                {x.courseTitle}
                            </CButton>
                        )}
                    </div>
                </div>
                <br />
                <br />
                <Button variant="contained" type="submit" className='mr-5 next-Button-form' style={{ float: "right" }}>Complete Setup</Button>

            </Form>
        </div>
        {loading && <div id='spinner-finalstep' style={{ zIndex: '999' }}>
            <center>
                <Spinner animation='border' />
            </center>
        </div>}
    </>)
}

export default CollegeCompleteSignup    