import React from "react";
import Courses from "../components/Courses";

const CoursesCards = () => {
  return (
    <div>
      <div
        className="col-md-12 section-header"
        style={{ padding: "50px", marginTop: "30px" }}
      >
        <h3 style={{ textAlign: "center" }}>Course Listing</h3>
       
      </div>
      <Courses />
    </div>
  );
};

export default CoursesCards;
