import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container" style={{marginTop:"50px"}}>
      <footer id="footer" style={{ backgroundColor: "rgb(37 37 37)" }}>
        <div className="row" style={{paddingTop:"20px"}}>
            <div className="col-md-3 p-3">
                <img src="https://res.cloudinary.com/my-free-course/image/upload/v1681465029/Site-pics/white_m4mt1i.png" style={{width:"220px",paddingBottom:"10px"}}/>
                <p style={{color:'white',fontSize:"14px"}}>My free course is a trading style of Starch UK Ltd, 71-75 Shelton Street Covent Garden London WC2H 9JQ</p>
            </div>
            <div className="col-md-6">

            </div>
            <div className="col-md-3 p-3">
                <p style={{color:'white',fontSize:"14px"}}>All NCFE and TQUK qualifications are delivered by our college partners.
                <br/><br/> 
                    0808 188 9161
                    <br/>
                    support@myfreecourse.co.uk
                </p>
            </div>
        </div>
        <hr style={{backgroundColor:"rgb(234 234 234 / 17%)",border:"none",height:"1px"}}/>
        <div className="row">
          <div className="text-left p-3 text-white col-md-6 copyright">
            &copy; {new Date().getFullYear()} All rights reserved Myfreecourse
          </div>

         <div className="col-md-6 p-3 footer-text ">
            <NavLink to="/privacy"> Privacy Policy</NavLink>
            <NavLink to="/terms-conditions">Terms and Conditions</NavLink>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
