import React from "react";
import { HashLink } from "react-router-hash-link";
import { Tabs, Tab, Card, Table, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
const bridalCertifical = () => {
    let product = {
        "courseTitle": "Level 3 Certificate in Bridal",
        "img": "https://res.cloudinary.com/my-free-course/image/upload/w_300,h_300,c_fill/v1665489863/Courses/Bridal_Hair_yzdryg.jpg",
        } 
  
  
    return(
        <div>
        <Helmet>
            <title>{product.courseTitle}</title>
        </Helmet>
  
      <div className="jumbotron text-center splash only-on-desktop">
          <h3 className="heading-jumbotron">{product.courseTitle}</h3>
        </div>
        <div className="only-on-mobile">
          <img
              src={product.img}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
      <div className="container-sm course-details-p" xs={12}>
        <div className="row">
          <div className="col-md-8 col-xs-12 col-sm-12 " style={{ color: "black" }}>
            <div className="row" style={{display: "flex !important"}}>
              <div className="col-md-3 col-sm-12 col-xs-12 only-on-desktop">
                <img
                  src={product.img}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <div className="col-md-9 col-sm-12 col-xs-12 padding only-on-desktop">
                <h3 className="coursedetailh2" style={{margin:"auto"}}>{product.courseTitle}</h3>
              </div>
            </div>
  
            <br />
            
            <h1 className="coursedetailh2 only-on-mobile" style={{margin:"auto",fontSize: "22px",color: "#222222"}}>{product.courseTitle}</h1>
            <br/>
            <HashLink
                  className="btn btn-primary co-button vv only-on-mobile"
                  to={'/leadsTwin/'+product.courseTitle+'/#top'}
                >
                  Learn More
                </HashLink>
            <br/>
            <p >The Level 3 Diploma in Bridal Hair and Make-up will provide you with advanced practical
                skills and knowledge in bridal hair and make-up.
            </p>
            <br/>
            <p>Upon successful achievement you will have gained the technical skills to create a range of
            bridal hairstyles and provide bridal make-up services to clients. You will also gain the
            knowledge and skills required for applying false eyelashes and styling special occasion
            hair.
            </p>
            <h6><b>Who it's for:</b></h6>
            <p>The diploma is aimed at people currently working in the hair and beauty sector who wish to
specialise in bridal hair and make-up.</p>
            
            <h6><b>Progression/Career Path:</b></h6>
            <p>Learners wishing to progress from this qualification can undertake the following qualifications:</p>
            <ul>
                <li>Level 3 Diploma in Beauty Therapy.</li>
                <li>Level 3 Diploma in Make-up Artistry.</li>
             </ul>
            <br />
            {/* <h5><b>What are the costs</b></h5>
            <p>The course is funded by the Student Finance England. It is there help individuals 19+ gain
a Level 3-6 qualification without paying a lump sum upfront.</p> 
            <br/>
            <p>There are no credit checks required and you won’t have top pay anything back until you
have finished your course and only when you are earning over the threshold. The course
enrolment team will go through all the details and answer any questions you may have.</p>
            
            <h5><b>If you go on to higher education</b></h5>
            <p>If you go into higher education, Student Finance England will ‘write off’ any outstanding
Advanced Learner Loan balances you owe for an Access to HE course once you complete
a higher education course. This means you don’t have to repay it. The higher education
course must be eligible for student finance.</p>  */}
            <br/>
            <br/>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
              <Tab eventKey="home" title="Content">
                <br/>
                <b>Units Covered:</b>
                <p>You will need to achieve a total of 63 credits from the mandatory units:</p>
                <ul>
                    <li>Monitor and maintain health and safety practice in the salon.</li>
                    <li>Maintaining personal health and wellbeing.</li>
                    <li>Working in the hair industry.</li>
                    <li>Explore technological developments within the hair beauty and associated areas.</li>
                    <li>Client consultation for hair services.</li>
                    <li>The art of dressing hair.</li>
                    <li>Styling bridal hair.</li>
                    <li>Styling and dressing hair using a range of techniques.</li>
                    <li>Special occasion hair styles.</li>
                    <li>Apply make-up.</li>
                    <li>Bridal make-up.</li>
                    <li>Asian bridal make-up.</li>
                    <li>False eyelash application.</li>
                    <li>Relevant level 2 award in infection prevention (COVID-19).</li>
                
                </ul>
                <br/>
                </Tab>
              <Tab eventKey="benefits" title="Benefits">
              <h6><b>Benefits of this course</b></h6>
            <p>Progress into employment in a wide variety of environments from salons to day spa’s or
become self-employed
Achieve a nationally recognised level 3 qualification
Move onto further study and/or access higher education
Develop skills and knowledge through a highly specialist work-related programme of study.
Dedicated tutor support</p>
            
              </Tab>
            </Tabs>

          </div>
          <div className="col-md-4 info-box-courses">
            <Card style={{ width: "18rem", color: "black" }} className="wd">
              <Card.Body style={{ boxShadow: "none" }}>
                <Card.Title>KEY INFORMATION</Card.Title>
                <Table striped bordered>
                  <thead>
                    <tbody>
                      <tr>
                        <td>Course Length</td>
                        <td>Up to 28 weeks</td>
                      </tr>
                      <tr>
                        <td>Awarding Body</td>
                        <td>VTCT</td>
                      </tr>
                      <tr>
                        <td>Course Level</td>
                        <td>Level 3</td>
                      </tr>
                      <tr>
                        <td>Course Cost </td>
                        <td>Multiple options available</td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>London (Lewisham)</td>
                      </tr>
                    </tbody>
                  </thead>
                </Table>
                <HashLink
                  className="btn btn-primary co-button vv "
                  to={'/leadsTwin/'+product.courseTitle+'/#top'}
                >
                  Learn More
                </HashLink>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <section className="services section-bg" id="partners" style={{borderTop: "1px solid #eaeaea",marginTop: "30px",paddingTop: "0px"}}>
  <div className="container">

  <div className="row social-logo-section1" >
        <div className="col">
          <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{width:"100%"}}  alt="" />
        </div>
      </div>
      <center>
      <div className="row social-logo-section only-on-mobile" >
        <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
      </div>
      </center>
    </div>

    </section>
      </div>
    )
}

export default bridalCertifical        