import React, { useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel'
import Dropdown from 'react-bootstrap/Dropdown';

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
}
ReactPixel.init('1149252932226423', options)

ReactPixel.pageView() // For tracking page view

const getUserType = () => {
    if (!localStorage.getItem('collegeID') && !localStorage.getItem('callCenterID') && !localStorage.getItem('supportID')) {
        return 'admin'
    } else if (localStorage.getItem('collegeID')) {
        return 'college'
    } else if (localStorage.getItem('callCenterID')) {
        return 'callcenter'
    } else if (localStorage.getItem('supportID')) {
        return 'support'
    }
}

const NavBars = ({ user }) => {
    const [userType, setUserType] = useState(getUserType() || null)
    return (
        <div style={{ background: 'white' }}>
            <Navbar collapseOnSelect expand='lg' id='header'>
                <div id='header-inside'>
                    <Navbar.Brand style={{ color: 'orange' }} href='/home'>
                        <img
                            style={{ width: '225px' }}
                            src='https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_40,w_225//v1627684312/Site-pics/image_2021-07-31_033149_ivphsj.png'
                            className='header-logo'
                        />
                    </Navbar.Brand>
                    <Nav className=''>
                        {user && (
                            <React.Fragment>
                                {userType === 'college' && (<>
                                    <Nav.Link href='/collegehome'>Dashboard</Nav.Link>
                                    <Nav.Link href='/new-contract'>New Contract</Nav.Link>
                                </>
                                )}
                                {userType === 'callcenter' && (<>
                                    <Nav.Link href='/callcenterhome'>Dashboard</Nav.Link>
                                    <Nav.Link href='/incompleteForms-cc'>Incomplete</Nav.Link>
                                    <Nav.Link href='/add-form-callcenter'>New Form</Nav.Link>
                                    <Nav.Link href='/all-forms-callcenter'>CC Forms</Nav.Link>
                                </>
                                )}
                                {(localStorage.getItem('callCenterID') === "6332fe9546a8ac00160bbf80") && (<>
                                    <Nav.Link href='/all-callcenter-staff'>Team</Nav.Link>
                                </>)}
                                {userType === 'admin' && (<>
                                    <Nav.Link href='/adminhome'>Dashboard</Nav.Link>
                                </>
                                )}
                                {(userType === 'admin') && (
                                    <Nav.Link href='/studentsList'>Students List</Nav.Link>
                                )}

                                {(userType === 'callcenter' || userType === 'college') && (
                                    <Nav.Link href='/logout'>Logout</Nav.Link>
                                )}

                            </React.Fragment>
                        )}
                    </Nav>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' id='toggle' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='mr-auto only-on-mobile'></Nav>
                        <React.Fragment>

                            {(userType !== 'college' && userType !== 'callcenter' && userType !== 'support') && <>

                                <Nav.Link href='/home/#courses' className='mobile-menu-sub'>
                                    Courses
                                </Nav.Link>
                                <Nav.Link href='/home/#services' className='mobile-menu-sub'>
                                    How it works
                                </Nav.Link>
                                <Nav.Link href='/faqs' className='mobile-menu-sub'>
                                    FAQ's
                                </Nav.Link>
                            </>
                            }
                        </React.Fragment>
                    </Navbar.Collapse>
                    {user && <>
                        {userType === 'admin' && <>
                            <Dropdown>
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    Admin
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/studentsList">Students List</Dropdown.Item>
                                    <Dropdown.Item href="/approvedForms">Approved Forms</Dropdown.Item>
                                    {/* <Dropdown.Item href="/leadsList">Leads List</Dropdown.Item> */}
                                    <Dropdown.Item href="/twinleadsList">Twins Leads List</Dropdown.Item>
                                    <Dropdown.Item href='/all-forms-callcenter'>Call Center Forms</Dropdown.Item>
                                    <Dropdown.Item href="/colleges">Colleges</Dropdown.Item>
                                    <Dropdown.Item href="/contracts">Contracts</Dropdown.Item>
                                    <Dropdown.Item href="/all-attachment-links">Attachments Links</Dropdown.Item>
                                    <Dropdown.Item href="/all-courses">Courses</Dropdown.Item>
                                    <Dropdown.Item href='/failedStep1'>Failed Step 1</Dropdown.Item>
                                    <Dropdown.Item href='/all-support-staff'>Support Staff</Dropdown.Item>
                                    <Dropdown.Item href="/all-callcenter-staff">CallCenter Staff</Dropdown.Item>
                                    <Dropdown.Item href="/incompleteForms">Incomplete List</Dropdown.Item>
                                    <Dropdown.Item href="/trash">Deleted Forms</Dropdown.Item>
                                    <Dropdown.Item href="/incomplete-trash">Deleted Incompleted</Dropdown.Item>
                                    <Dropdown.Item href="/allstudents">Database</Dropdown.Item>
                                    <Dropdown.Item href="/database-incompleteForms">Incomplete database</Dropdown.Item>
                                    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>}

                        {userType === 'support' && <>
                            <Dropdown>
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    Menu
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/studentsList">Students List</Dropdown.Item>
                                    {localStorage.getItem('supportID') === '633a9edd7392990016b86705' && <>
                                        <Dropdown.Item href="/all-courses">All Courses</Dropdown.Item>
                                    </>}

                                    {(localStorage.getItem('supportID') === '633a9edd7392990016b86705' ||
                                        localStorage.getItem('supportID') === '63d109a60ac9600016ea1dfc') && <>
                                            <Dropdown.Item href="/failedStep1">Failed Step 1</Dropdown.Item>
                                            <Dropdown.Item href="/database-incompleteForms">Incomplete database</Dropdown.Item>
                                        </>}
                                    {(localStorage.getItem('supportID') === '633a9edd7392990016b86705' ||
                                        localStorage.getItem('supportID') === '63d109a60ac9600016ea1dfc' ||
                                        localStorage.getItem('supportID') === '633a9e437392990016b866e4' ||
                                        localStorage.getItem('supportID') === '63e4ddfcf641f4001487d494' ||
                                        localStorage.getItem('supportID') === '63a19820a8bc820016c30336') &&
                                        <Dropdown.Item href="/approvedForms">Approved Forms</Dropdown.Item>}
                                    <Dropdown.Item href="/all-attachment-links">Attachments Links</Dropdown.Item>
                                    <Dropdown.Item href="/incompleteForms">Incomplete List</Dropdown.Item>
                                    <Dropdown.Item href="/trash">Deleted Forms</Dropdown.Item>
                                    <Dropdown.Item href="/incomplete-trash">Deleted Incompleted</Dropdown.Item>
                                    <Dropdown.Item href="/allstudents">Database</Dropdown.Item>
                                    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>}
                    </>}
                </div>
            </Navbar>
        </div>
    )
}

export default NavBars
