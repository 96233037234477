import http from './httpService'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import jwtDecode from 'jwt-decode'

const apiPoint = '/users/login/'
const apiPointCollege = '/college/login/'
const apiPointCallCenter = '/callcenter/login/'
const apiPointSupport = '/support/login/'
const tokenKey = 'token'

export async function login(email, password) {
	logout()
	const { data: jwt } = await http.post(apiPoint, { email, password })
	localStorage.setItem(tokenKey, jwt.token)
	localStorage.setItem('user', jwt.data[0].name)
}

export async function collegeLogin(email, password) {
	logout()
	const { data: jwt } = await http.post(apiPointCollege, { email, password })
	localStorage.setItem(tokenKey, jwt.token)
	localStorage.setItem('user', jwt.data[0].collegeName)
	localStorage.setItem('collegeID', jwt.data[0]._id)
}

export async function callCenterLogin(email, password) {
	logout()
	const { data: jwt } = await http.post(apiPointCallCenter, { email, password })
	localStorage.setItem(tokenKey, jwt.token)
	localStorage.setItem('user', jwt.data[0].name)
	localStorage.setItem('callCenterID', jwt.data[0]._id)
}
export async function supportLogin(email, password) {
	logout()
	const { data: jwt } = await http.post(apiPointSupport, { email, password })
	localStorage.setItem(tokenKey, jwt.token)
	localStorage.setItem('user', jwt.data[0].name)
	localStorage.setItem('supportID', jwt.data[0]._id)
}

const apiURL = '/users/registerDetails'
export async function regDetails(firstName, lastName, email, mobile) {
	return http.post(apiURL, { firstName, lastName, email, mobile })
}

const apiPoint1 = '/form/submit'
export function submitForm(data) {
	return http.post(apiPoint1, data)
}

const apiPoint2 = '/form/ids'
export function uploadID(data) {
	return http.post(apiPoint2, data)
}

export function logout() {
	localStorage.removeItem(tokenKey)
	localStorage.removeItem('user')
	localStorage.removeItem('collegeID')
	localStorage.removeItem('callCenterID')
	localStorage.removeItem('supportID')
	localStorage.removeItem('completedBy')
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem(tokenKey)

		return jwtDecode(jwt)
	} catch (ex) {
		return null
	}
}

const coursePoint = '/course/'
export function getCourse() {
	return http.get(coursePoint)
}

const forms = '/form/'
export function getForm() {
	return http.get(forms)
}

const idCard = '/id-card/'
export function getIdDetails(nationalInsNo) {
	return http.get(idCard, {
		params: {
			nationalInsNo: nationalInsNo,
		},
	})
}

// get orm specified by id

export function getTestForm(id) {
	return http.get('/form/' + id)
}

const incForm = '/form/incompleteForms/'
export function getIncompleteForms() {
	return http.get(incForm)
}

export function deleteFormEntry(id) {
	const api = 'form/deleteform/' + id
	return http.delete(api)
}

export function deleteinFormEntry(id) {
	const api = 'form/deleteincompleteform/' + id
	return http.delete(api)
}

export default {
	login,
	logout,
	getCurrentUser,
	submitForm,
	uploadID,
	regDetails,
	getCourse,
	getForm,
	getIncompleteForms,
	getTestForm,
	deleteFormEntry,
	deleteinFormEntry,
	collegeLogin,
	callCenterLogin,
    supportLogin
}
