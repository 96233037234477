import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import App from "./../Reports";
const FormCompletetionSteps = ({
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
}) => {
  return (
    <>
      <Nav className="mb-4 mobile-nav-s">
        <Nav.Item>
          {step1 ? (
            <LinkContainer to="/form">
              <Nav.Link>Eligibility</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Eligibility</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step2 ? (
            <LinkContainer to="/personal">
              <Nav.Link>Personal Details</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Personal</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step3 ? (
            <LinkContainer to="/emergencydetails">
              <Nav.Link>Emergency Details</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Emergency Details</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step4 ? (
            <LinkContainer to="/employment">
              <Nav.Link>Employment</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Employment</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step5 ? (
            <LinkContainer to="/qualification">
              <Nav.Link>Qualifications</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Qualifications</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step6 ? (
            <LinkContainer to="/oppurtunities">
              <Nav.Link>Equal Opportunities</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Equal Opportunities</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step7 ? (
            <LinkContainer to="/declaration">
              <Nav.Link>Declaration</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>Declaration</Nav.Link>
          )}
        </Nav.Item>
        <Nav.Item>
          {step8 ? (
            <LinkContainer to="/proof">
              <Nav.Link>ID Proof</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>ID Proof</Nav.Link>
          )}
        </Nav.Item>
      </Nav>
    </>
  );
};

export default FormCompletetionSteps;
