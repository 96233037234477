import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { CButton, CDataTable } from '@coreui/react'
import { Link } from 'react-router-dom'
import { Line } from 'react-chartjs-2'

const TITLE = 'Callcenter List'

const CallCenterList = () => {
    const [accounts,setAccounts] = useState([])
    const [reports,setReports] = useState([])

    useEffect(async() => {
		const data = await axios.get('/callcenter/getall')
        const activeAccounts = data.data.data.filter(x=>x.isActive)
        
        setAccounts(data.data.data)
        let accountsReports= []
        for(let i=0;i<activeAccounts.length;i++){
            let response = await axios.get(process.env.REACT_APP_API_URL + '/callcenter/getreports/'+activeAccounts[i]._id)
            accountsReports.push(response.data)
        }
        
        setReports(accountsReports)
        
	}, [])

    const fields = [
		{ key: 'name' },
		'email',
		(localStorage.getItem('callCenterID')==="6332fe9546a8ac00160bbf80") ? 'Password': 'password',
		// 'forms_today',
		// 'forms_yesterday',
		// 'forms_week',
		// 'forms_last_week',
		// 'forms_month',
        // 'forms_lastmonth',
		{
			key: 'Status',
			label: 'Status',
			_style: { width: '10%' },
			sorter: false,
			filter: false,
		},
		{
			key: 'Allocation',
			label: 'Allocation',
			_style: { width: '10%' },
			sorter: false,
			filter: false,
		},
		{
			key: 'Delete',
			label: '',
			_style: { width: '10%' },
			sorter: false,
			filter: false,
		}
	]

    const changeStatus = async (id) => {
        await axios.post(process.env.REACT_APP_API_URL + '/callcenter/change-status', {
            id: id
        })
        setTimeout(function () {
			window.location.reload()
		}, 1500)
    }
    const changeAllocationStatus = async (id) => {
        await axios.post(process.env.REACT_APP_API_URL + '/callcenter/change-allocation', {
            id: id
        })
        setTimeout(function () {
			window.location.reload()
		}, 1500)
    }

    const handleDelete = async(accountid) => {
        // console.log(accountid)
        await axios.post(process.env.REACT_APP_API_URL + '/callcenter/delete', {
            id: accountid
        })
        setTimeout(function () {
			window.location.reload()
		}, 1500)
    }
  
    const formatData = (data)=>{
        let completeCOunt = 0
        let approvedCOunt = 0
		let completedArray = []
		let approvedArray = []
		const incMappedData = data.completeData.map((d) => d.date)
		const incFormResult = [...new Set(incMappedData)]
		for (let i = 0; i < incFormResult.length; i++) {
			for (let j = 0; j < data.completeData.length; j++) {
				if (incFormResult[i] ===  data.completeData[j].date) {
					completeCOunt++
				}
			}
			completedArray.push(completeCOunt)
			completeCOunt = 0
		}
        const apprFormResult = [...new Set(incMappedData)]
        for (let i = 0; i < apprFormResult.length; i++) {
			for (let j = 0; j < data.approvedData.length; j++) {
				if (apprFormResult[i] ===  data.approvedData[j].date) {
					approvedCOunt++
				}
			}
			approvedArray.push(approvedCOunt)
			approvedCOunt = 0
        }
        // console.log()
        return {
            labels: incFormResult,
            datasets: [
              {
                label: 'Completed Forms',
                data: completedArray,
                borderColor: '#f87979',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(171,35,70,0.4)',
                hoverBorderColor: 'rgba(171,35,70,1)',
                },
              {
                label: 'Approved Forms',
                data: approvedArray,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ]}

    }
    return (
        <>
            <div className='studentLIst'>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
            </div>
            <div className='container'>
                <h1>{TITLE}</h1>
            <div>
                {(localStorage.getItem('callCenterID')!=="6332fe9546a8ac00160bbf80") && <Link to="/add-callcenter-staff">                
                    <Button
                        className='button button-group-download'
                        style={{ maxHeight: 50 }}
                    >
                        Add Member
                    </Button>
                </Link>}
                
                <br/><br/>

            </div>

            {accounts.length === 0 ? (
                <p>Loading Accounts</p>
            ) : (
                <div>
                    <CDataTable
                        items={accounts}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={50}
                        hover
                        sorter
                        pagination
                        scopedSlots={{
                            Allocation: (item, index) => {
                                return (
                                    <td
                                    className='py-2'
                                >
                                    <CButton
                                        color='primary'
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            changeAllocationStatus(item._id)
                                        }}
                                    >
                                        {item.isWorking ? "Pause":"Resume"}
                                    </CButton>
                                </td>
                                )
                            },
                            Status: (item, index) => {
                                return (
                                    <td
                                    className='py-2'
                                >
                                    {(localStorage.getItem('callCenterID')!=="6332fe9546a8ac00160bbf80")&&<CButton
                                        color='primary'
                                        variant='outline'
                                        shape='square'
                                        size='sm'
                                        onClick={() => {
                                            changeStatus(item._id)
                                        }}
                                    >
                                        {item.isActive ? "Deactivate":"Activate"}
                                    </CButton>}
                                </td>
                                )
                            },
                                Delete: (item, index) => {
                                    return (
                                        <td
										className='py-2'
									>
										{(localStorage.getItem('callCenterID')!=="6332fe9546a8ac00160bbf80") && <CButton
											color='primary'
											variant='outline'
											shape='square'
											size='sm'
											onClick={() => {
												handleDelete(item._id)
											}}
										>
											Delete
										</CButton>}
									</td>
                                    )
                                },
                            }}
                            />

                    <div>
                        <hr/>
                        <br/>
                        <br/>
                        <center><h3>Reports</h3><span className='approved-count-table'>(Approved)</span></center>
                        <table className='table' style={{ border: '2px solid #eaeaea' }}>
                            <thead>
                                
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Today
                                    </th>
                                    <th>
                                        Yesterday
                                    </th>
                                    <th>
                                        Week
                                    </th>
                                    <th>
                                        Last Week
                                    </th>
                                    <th>
                                        Month
                                    </th>
                                    <th>
                                        Last Month
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {accounts.map(x=>
                                    <tr>
                                        <td>
                                            {x.name}
                                        </td>
                                        <td>
                                            {x.forms_today}<span className='approved-count-table'>({x.APP_forms_today})</span>
                                        </td>
                                        <td>
                                            {x.forms_yesterday}<span className='approved-count-table'>({x.APP_forms_yesterday})</span>
                                        </td>
                                        <td>
                                            {x.forms_week}<span className='approved-count-table'>({x.APP_forms_week})</span>
                                        </td>
                                        <td>
                                            {x.forms_last_week}<span className='approved-count-table'>({x.APP_forms_last_week})</span>
                                        </td>
                                        <td>
                                            {x.forms_month}<span className='approved-count-table'>({x.APP_forms_month})</span>
                                        </td>
                                        <td>
                                            {x.forms_lastmonth}<span className='approved-count-table'>({x.APP_forms_lastmonth})</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            </table>     
                         </div>  
                    </div>
                )}
            </div>
            <br/>
            <div className='container'>
            {reports.length === 0 ? (
                <p>Loading reports</p>
            ) : (<>{reports && <>
                {reports.map(x=>(<>
                    <div className='row'>
                        <div className='col-md-8'>
                            <h4 className='text-center mt-5'>{x.name}</h4>
					        <table className='table' style={{ border: '2px solid #eaeaea' }}>
						        <thead>
                                    <th>-</th>
                                    <th>Today</th>
                                    <th>Yesterday</th>
                                    <th>Week</th>
                                    <th>L.Week</th>
                                    <th>Month</th>
                                    <th>L.Month</th>
                                </thead>
						        <tbody>
                                    <tr>
                                        <td>Pending</td>
                                        <td>{(x.today).find((y)=> y._id==='Pending') ?(x.today).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Pending') ?(x.yesterday).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Pending') ?(x.week).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Pending') ?(x.lastWeek).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Pending') ?(x.month).find((y)=> y._id==='Pending').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Pending') ?(x.lastMonth).find((y)=> y._id==='Pending').number : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Called (CB)</td>
                                        <td>{(x.today).find((y)=> y._id==='Called (CB)') ?(x.today).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Called (CB)') ?(x.yesterday).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Called (CB)') ?(x.week).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Called (CB)') ?(x.lastWeek).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Called (CB)') ?(x.month).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Called (CB)') ?(x.lastMonth).find((y)=> y._id==='Called (CB)').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Will complete</td>
                                        <td>{(x.today).find((y)=> y._id==='Will complete') ?(x.today).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Will complete') ?(x.yesterday).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Will complete') ?(x.week).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Will complete') ?(x.lastWeek).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Will complete') ?(x.month).find((y)=> y._id==='Will complete').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Will complete') ?(x.lastMonth).find((y)=> y._id==='Will complete').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Sent link</td>
                                        <td>{(x.today).find((y)=> y._id==='Sent link') ?(x.today).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Sent link') ?(x.yesterday).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Sent link') ?(x.week).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Sent link') ?(x.lastWeek).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Sent link') ?(x.month).find((y)=> y._id==='Sent link').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Sent link') ?(x.lastMonth).find((y)=> y._id==='Sent link').number : 0}</td>
                                    </tr>
                                    

                                    <tr>
                                        <td>Considering</td>
                                        <td>{(x.today).find((y)=> y._id==='Considering') ?(x.today).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Considering') ?(x.yesterday).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Considering') ?(x.week).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Considering') ?(x.lastWeek).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Considering') ?(x.month).find((y)=> y._id==='Considering').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Considering') ?(x.lastMonth).find((y)=> y._id==='Considering').number : 0}</td>
                                    </tr>
                                    

                                    <tr>
                                        <td>Wrong number</td>
                                        <td>{(x.today).find((y)=> y._id==='Wrong number') ?(x.today).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Wrong number') ?(x.yesterday).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Wrong number') ?(x.week).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Wrong number') ?(x.lastWeek).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Wrong number') ?(x.month).find((y)=> y._id==='Wrong number').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Wrong number') ?(x.lastMonth).find((y)=> y._id==='Wrong number').number : 0}</td>
                                    </tr>

                                    <tr>
                                        <td>Not interested</td>
                                        <td>{(x.today).find((y)=> y._id==='Not interested') ?(x.today).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Not interested') ?(x.yesterday).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Not interested') ?(x.week).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Not interested') ?(x.lastWeek).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Not interested') ?(x.month).find((y)=> y._id==='Not interested').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Not interested') ?(x.lastMonth).find((y)=> y._id==='Not interested').number : 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Deleted</td>
                                        <td>{(x.today).find((y)=> y._id==='Deleted') ?(x.today).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(x.yesterday).find((y)=> y._id==='Deleted') ?(x.yesterday).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(x.week).find((y)=> y._id==='Deleted') ?(x.week).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(x.lastWeek).find((y)=> y._id==='Deleted') ?(x.lastWeek).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(x.month).find((y)=> y._id==='Deleted') ?(x.month).find((y)=> y._id==='Deleted').number : 0}</td>
                                        <td>{(x.lastMonth).find((y)=> y._id==='Deleted') ?(x.lastMonth).find((y)=> y._id==='Deleted').number : 0}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className='col-md-4'>
                        <br/><br/><br/><br/>
                            <table  className='table' style={{ border: '2px solid #eaeaea' }}>
                                <tbody>
                                    <tr>
                                        <td>Average Call Time</td>
                                        <td>{x.twilio.Average.toFixed(2)}s</td>
                                    </tr>
                                    <tr>
                                        <td>Total Call Time</td>
                                        <td>{x.twilio.totalCallTime}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Calls</td>
                                        <td>{x.twilio.number}</td>
                                    </tr>
                                    <tr>
                                        <td>Completed Calls</td>
                                        <td>{x.twilio.completed}</td>
                                    </tr>
                                    <tr>
                                        <td>Missed Calls</td>
                                        <td>{x.twilio.missed}</td>
                                    </tr>
                                    <tr>
                                        <td>Forms Completed</td>
                                        <td>{x.data[0].forms_today}</td>
                                    </tr>
                                    <tr>
                                        <td>Forms Approved</td>
                                        <td>{x.data[0].APP_forms_today}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        <br/>
                        <br/>
                        <hr/>
                    </div>
                    
                    <div >
                            <Line  data={formatData({completeData: x.completeData, approvedData: x.approvedData})} />
                        </div>
                        <hr/>
                </>))}
            </>}</>)}
            </div>
        </>
    )
}


export default CallCenterList