import React, { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'
import { CDataTable } from '@coreui/react'
import { saveAs } from 'file-saver'
import { Button } from 'react-bootstrap'



const { io } = require('socket.io-client')



const LeadsList = () => {
	const socket = io(process.env.REACT_APP_API_URL)
	socket.connect()
	const dispatch = useDispatch()
	const [forms, setForms] = React.useState([])
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())

	useEffect(() => {
		socket.emit('getLeads')
		socket.on('leadsData', (data) => {
			setForms(data)
		})
	}, [])
    console.log(forms);
	const fields = [
		{ key: 'firstName' },
		'lastName',
		'email',
		'mobile',
		'createdAt',
		'residencyStatus',
        'PostCodeBL'
	]
    let arr = forms.data;
    function toCsv(arr) {
		return arr
			.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(','))
			.join('\n')
	}
    function pivot(arr) {
		var mp = new Map()

		function setValue(a, path, val) {
			if (Object(val) !== val) {
				// primitive value
				var pathStr = path.join('.')
				var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr)
				a[i] = val
			} else {
				for (var key in val) {
					setValue(a, key == '0' ? path : path.concat(key), val[key])
				}
			}
			return a
		}

		var result = arr.map((obj) => setValue([], [], obj))
		return [[...mp.keys()], ...result]
	}
    function downloadCSV() {
		var toDownload = []
		for (var i = 0; i < arr.length; i++) {
			if (arr[i].createdAt === undefined || arr[i].createdAt === undefined) continue
			if (new Date(arr[i].createdAt) >= startDate && new Date(arr[i].createdAt <= endDate)) {
				toDownload.push(arr[i])
			}
		}
		var csvContent = toCsv(pivot(toDownload))
		var blob = new Blob([csvContent], {
			type: 'text/csv;charset=utf-8;',
		})
		saveAs(blob, 'test.csv')
	}
 
	function timeout(delay) {
		return new Promise((res) => setTimeout(res, parseInt(delay)))
	}

	return (
		<div className='container incomplete-container'>
            <div className="row">
            <div className='col-8'></div>
            <div className='col-4'>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                Start Date:{' '}
                <input
                    placeholder='start date'
                    type='date'
                    onChange={(e) => {
                        setStartDate(new Date(e.target.value))
                    }}
                />
                End Date:{' '}
                <input
                    placeholder='end date'
                    type='date'
                    onChange={(e) => {
                        setEndDate(new Date(e.target.value))
                    }}
                />
                <Button
                    style={{ width: '100%' }}
                    className='button button-group-download'
                    onClick={downloadCSV}
                >
                    Download
                </Button>
            </div>
                </div>    
            </div>
            
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
					<CDataTable
						items={forms.data}
						fields={fields}
						columnFilter
						tableFilter
						itemsPerPageSelect
						itemsPerPage={50}
						hover
						sorter
						pagination
					/>
				</div>
			)}
		</div>
	)
}

export default LeadsList
