import React from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form'
import auth from './../../services/authService'

class CollegePanel extends Form {
	state = {
		data: { username: '', password: '' },
		errors: {},
	}

	schema = {
		username: Joi.string().required().label('Username'),
		password: Joi.string().required().label('Password'),
	}

	doSubmit = async () => {
		try {
			const { data } = this.state
			await auth.collegeLogin(data.username, data.password)
			window.location = '/collegehome'
		} catch (ex) {
			if (ex.response && ex.respone.status === 400) {
				const errors = { ...this.state.errors }
				errors.username = ex.response.data
				this.setState({ errors })
			}
		}
	}

	render() {
		return (
			<div
				className='col-xl-3 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4'
				style={{ marginBottom: '135px' }}
			>
				<h1>College Login</h1>
				<form onSubmit={this.handleSubmit}>
					{this.renderInput('username', 'Username')}
					{this.renderInput('password', 'Password', 'password')}
					{this.renderButton('Login')}
				</form>
			</div>
		)
	}
}

export default CollegePanel
