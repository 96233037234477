import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { CButton, CDataTable } from '@coreui/react'
import { Link } from 'react-router-dom'

const TITLE = 'Support Staff List'

const SupportStaffList = () => {
    const [accounts,setAccounts] = useState([])

    useEffect(async() => {
		const data = await axios.get('/support/getall')
        setAccounts(data.data.data)
	}, [])

    console.log(accounts)
    const fields = [
		{ key: 'name' },
		'email',
		'password',
        'forms_today',
        'forms_yesterday',
        'forms_week',
        'forms_month',
        'forms_lastmonth',
		{
			key: 'Status',
			label: 'Status',
			_style: { width: '10%' },
			sorter: false,
			filter: false,
		},
		{
			key: 'Delete',
			label: '',
			_style: { width: '10%' },
			sorter: false,
			filter: false,
		}
	]

    const changeStatus = async (id) => {
        await axios.post(process.env.REACT_APP_API_URL + '/support/change-status', {
            id: id
        })
        setTimeout(function () {
			window.location.reload()
		}, 1500)
    }

    const handleDelete = async(accountid) => {
        // console.log(accountid)
        await axios.post(process.env.REACT_APP_API_URL + '/support/delete', {
            id: accountid
        })
        setTimeout(function () {
			window.location.reload()
		}, 1500)
    }

    return (
        <>
            <div className='studentLIst'>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
            </div>
            <div className='container'>
                <h1>{TITLE}</h1>
            <div>
                <Link to="/add-support-staff">                
                    <Button
                        className='button button-group-download'
                        style={{ maxHeight: 50 }}
                    >
                        Add Member
                    </Button>
                </Link>
                
                <br/><br/>

            </div>

            {accounts.length === 0 ? (
                <p>Loading Accounts</p>
            ) : (
                <div>
                    <CDataTable
                        items={accounts}
                        fields={fields}
                        columnFilter
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={50}
                        hover
                        sorter
                        pagination
                        scopedSlots={{
                                Status: (item, index) => {
                                    return (
                                        <td
										className='py-2'
									>
										<CButton
											color='primary'
											variant='outline'
											shape='square'
											size='sm'
											onClick={() => {
												changeStatus(item._id)
											}}
										>
											{item.isActive ? "Deactivate":"Activate"}
										</CButton>
									</td>
                                    )
                                },
                                Delete: (item, index) => {
                                    return (
                                        <td
										className='py-2'
									>
										<CButton
											color='primary'
											variant='outline'
											shape='square'
											size='sm'
											onClick={() => {
												handleDelete(item._id)
											}}
										>
											Delete
										</CButton>
									</td>
                                    )
                                },
                            }}
                            />
                    </div>
                )}
            </div>
        </>
    )
}


export default SupportStaffList