import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import { saveLongEnglishAssessment } from '../../actions/formActions'

const EnglishTestLong = ({ history }) => {
	const urlParams = new URLSearchParams(history.location.search)
	const formId = urlParams.get('form_id')
	
    function scrollTtoView() {
        document.getElementById('title-assessment').scrollIntoView({
          behavior: 'smooth'
        });
      }


    const [answer1, setAnswer1] = useState("")
    const [answer2, setAnswer2] = useState("")
    const [answer3, setAnswer3] = useState("")
    const [answer4, setAnswer4] = useState("")
    const [answer5, setAnswer5] = useState("")
    const [answer6, setAnswer6] = useState("")
    const [answer7, setAnswer7] = useState("")
    const [answer8, setAnswer8] = useState("")
    const [answer9, setAnswer9] = useState("")
    const [answer10, setAnswer10] = useState("")
    const [answer11a, setAnswer11a] = useState("")
    const [answer11b, setAnswer11b] = useState("")
    const [answer11c, setAnswer11c] = useState("")
    const [answer12, setAnswer12] = useState("")
    const [answer13, setAnswer13] = useState("")
    const [answer14, setAnswer14] = useState("")
    const [answer15, setAnswer15] = useState("")
    const [currentStep,setStep] = useState(0)


    const submitHandler = (e) => {

        let data ={
            formId: formId,
            data: {answer1: answer1,
                answer2: answer2,
                answer3: answer3,
                answer4: answer4,
                answer5: answer5,
                answer6: answer6,
                answer7: answer7,
                answer8: answer8,
                answer9: answer9,
                answer10: answer10,
                answer11a: answer11a,
                answer11b: answer11b,
                answer11c: answer11c,
                answer12: answer12,
                answer13: answer13,
                answer14: answer14,
                answer15: answer15}
        }
        
		const form = e.currentTarget
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		} else {
			e.preventDefault()
			saveLongEnglishAssessment({ data })
			history.push('/thank-you')
		}
    }

	return (
		<>

			<FormContainer>
                <div id='spinner-finalstep' style={{ zIndex: '999', display: "none" }}>
                    <center>
                        <Spinner animation='border' />
                    </center>
                </div>
				{/* <ScrollToMount /> */}
				<h2 id="title-assessment">
					<b>English Assessment</b>
				</h2>
                { (currentStep === 0) && <><p>Please complete the following English assessment. This section will be used to asses your basic
                                                English skills to ensure that you are able to complete the course. This will not determine your
                                                acceptance on the course and we may be able to provide extra support on your course.</p>
                <Button
                        onClick={()=>setStep(1)}
						className='next-Button-form'
						style={{
							width: '100px',
							color: 'white',
                            float: 'right'
						}}
					>
						{' '}
						start
					</Button>
                    </>
                    }
				<Form >


                { (currentStep === 1) &&
                      <Form.Group controlId='Question 1'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-7 my-auto'>
                                <Form.Label><b>1.</b>	Look at the picture of Scot at work.
                                    <br/>Use all the words and the full stop below to write a sentence about Scot.
                                </Form.Label>

                            </div>
                            <div className='col-lg-5'>
                                <center>
                                    <img style={{maxWidth:"200px"}} src="https://res.cloudinary.com/my-free-course/image/upload/v1658952736/Site-pics/worker_f1lpe5.png"/>
                                </center>
                            </div>
                        </div>
                        <br/>
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1659591140/Site-pics/image_2022-07-28_074100653_ppys62_1_tzxxdu.png"/>
                        <br/><br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer1}
                            onChange={(e) => setAnswer1(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>}



                    { (currentStep === 2) &&
                      <Form.Group >
                        
                        <Form.Label><b>2.</b>Which of these ‘Safety at Work’ signs warns about slippery floors? Write A, B or C in the answer box.
                            </Form.Label>
                        <div className="row english-assessment-long">
                            
                        <div className='col-lg-3 my-auto'>
                                <center>
                                    <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658961382/Site-pics/4_sw44if.jpg" />
                                    <p><u><b>Caution</b></u></p>
                                    <p>Trip Hazard</p>
                                    <Form.Check
                                        value="Trip Hazard"
                                        type="radio"
                                        aria-label="radio 1"
                                        label="A"
                                        onChange={()=> setAnswer2("Trip Hazard")}
                                        checked={answer2==='Trip Hazard'}
                                    />
                                </center>
                            </div> 
                            <div className='col-lg-3 my-auto'>
                                <center>
                                    <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658961382/Site-pics/1_logbwe.png" />
                                    <p><u><b>Caution</b></u></p>
                                    <p>Hot Surface</p>
                                    <Form.Check
                                        value="Trip Hazard"
                                        type="radio"
                                        aria-label="radio 1"
                                        label="B"
                                        onChange={()=> setAnswer2("Hot Surface")}
                                        checked={answer2==='Hot Surface'}
                                    />
                                </center>
                            </div> 
                            <div className='col-lg-3 my-auto'>
                                <center>
                                    <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658961381/Site-pics/3_wmvbrv.png" />
                                    <p><u><b>Caution</b></u></p>
                                    <p>Slippery Surface</p>
                                    <Form.Check
                                        value="Trip Hazard"
                                        type="radio"
                                        aria-label="radio 1"
                                        label="C"
                                        onChange={()=> setAnswer2("Slippery Surface")}
                                        checked={answer2==='Slippery Surface'}
                                    />
                                </center>
                            </div>
                        </div>
                        <br/>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>}


                        
                { (currentStep === 3) &&
                      <Form.Group controlId='Question 3'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-7 my-auto'>
                                <Form.Label><b>3. </b>Follow these directions from the hospital to the café.
                                    <br/>At which café would you arrive?
                                </Form.Label>
                                <br /><br />
                                <p><b><u>Read:</u></b> From the hospital, go along the street. Turn right at the junction, then next left. Go on to the junction and turn right.
Take the next left and the café is a short way on your left.
</p>

                            </div>
                            <div className='col-lg-5'>
                                <center>
                                    <img style={{maxWidth:"400px"}} src="https://res.cloudinary.com/my-free-course/image/upload/v1658964697/Site-pics/Capture_zagqiq.jpg"/>
                                </center>
                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer3}
                            onChange={(e) => setAnswer3(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>}


                        { (currentStep ===4 ) &&
                      <Form.Group controlId='Question 4'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>4. </b>One word in the note below is wrongly spelt.
                                    <br/>Write it correctly in the answer box
                                </Form.Label>
                                <br /><br />
                                <p><b>Paula</b><br/>It would appear there is a problem with the last order you sent out. The firm ordered 500 lables and you sent 500 envelopes. Please telephone them and sort this out. Hopefully, no harm has been done.
                                <br/><b>Stephen</b></p>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer4}
                            onChange={(e) => setAnswer4(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }


                    { (currentStep ===5) &&
                      <Form.Group >
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>5. </b>Read the information from a driving licence application form.
                                </Form.Label>
                                <br/>
                                <img style={{borderRight: "1px solid black", borderBottom: "1px solid black"}} src="https://res.cloudinary.com/my-free-course/image/upload/v1658966359/Site-pics/image_2022-07-28_045917962_dqtbwq.png"/>
                            </div>
                        </div>
                        <br/>
                        <Form.Label>Do you need to have your photograph signed if you are using a UK passport for identification purposes?</Form.Label>
                        <Form.Check
                            value="Yes"
                            type="radio"
                            aria-label="radio 1"
                            label="Yes"
                            onChange={()=> setAnswer5("Yes")}
                            checked={answer5==='Yes'}
                        />
                        <Form.Check
                            value="No"
                            type="radio"
                            aria-label="radio 1"
                            label="No"
                            onChange={()=> setAnswer5("No")}
                            checked={answer5==='No'}
                        />
                        <Form.Check
                            value="Can't tell"
                            type="radio"
                            aria-label="radio 1"
                            label="Can't tell"
                            onChange={()=> setAnswer5("Can't tell")}
                            checked={answer5==="Can't tell"}
                        />
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }


                        
                { (currentStep ===6 ) &&
                      <Form.Group controlId='Question 6'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>6. </b>The notice below needs proofreading.
                                    <br/>One word is incorrectly used in one of the sentences. Write the word below that is wrongly used.
                                </Form.Label>
                                <img style={{borderRight: "1px solid black", borderBottom: "1px solid black"}} src="https://res.cloudinary.com/my-free-course/image/upload/v1658966743/Site-pics/image_2022-07-28_050541931_owinjp.png" />
                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer6}
                            onChange={(e) => setAnswer6(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }


                { (currentStep ===7 ) &&
                      <Form.Group controlId='Question 7'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>7. </b>One word in this document is incorrectly spelt.
                                    <br/>Write it correctly in the answer box
                                </Form.Label>
                                <br /><br />
                                <i>Dear Sir<br/>
                                <br/>I would like to apply for promotion as advertised on the firm’s bulletin board. I have always wanted to transfer to Marketing ever since I joined the organisation. As you know, I have all the necessary qualifications and consider that I am sufficiently competant to take on the additional responsibilities and demands required by the job.</i>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer7}
                            onChange={(e) => setAnswer7(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }



                    { (currentStep ===8) &&
                      <Form.Group >
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>8. </b>Select the most suitable word to insert at X.
                                </Form.Label>
                                <br/><i>An estimated 75% of the UK working population spend their working day sitting on a chair. <u>X</u>, not sitting on the right chair can cause abdominal, spine, neck or leg damage</i>
                                </div>
                        </div>
                        <br/>
                        <Form.Label>Answer: </Form.Label>
                        <Form.Check
                            value="Consequently"
                            type="radio"
                            aria-label="radio 1"
                            label="Consequently"
                            onChange={()=> setAnswer8("Consequently")}
                            checked={answer8==='Consequently'}
                        />
                        <Form.Check
                            value="However"
                            type="radio"
                            aria-label="radio 1"
                            label="However"
                            onChange={()=> setAnswer8("However")}
                            checked={answer8==='However'}
                        />
                        <Form.Check
                            value="Because"
                            type="radio"
                            aria-label="radio 1"
                            label="Because"
                            onChange={()=> setAnswer8("Because")}
                            checked={answer8==="Because"}
                        />
                        <Form.Check
                            value="Subsequently"
                            type="radio"
                            aria-label="radio 1"
                            label="Subsequently"
                            onChange={()=> setAnswer8("Subsequently")}
                            checked={answer8==="Subsequently"}
                        />
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }


                { (currentStep ===9) &&
                      <Form.Group >
                        <div className="row english-assessment-long">
                            <div className='col-lg-9 my-auto'>
                                <Form.Label><b>9. </b>Which of the following statements about this firm has the correct punctuation and is a complete sentence?
                                </Form.Label>
                                <div>
                                <Form.Check
                            value="Making shirts for the large stores."
                            type="radio"
                            aria-label="radio 1"
                            label="Making shirts for the large stores."
                            onChange={()=> setAnswer9("Making shirts for the large stores.")}
                            checked={answer9==='Making shirts for the large stores.'}
                        />
                        <Form.Check
                            value="our firm makes shirts."
                            type="radio"
                            aria-label="radio 1"
                            label="our firm makes shirts."
                            onChange={()=> setAnswer9("our firm makes shirts.")}
                            checked={answer9==='our firm makes shirts.'}
                        />
                        <Form.Check
                            value="We make shirts for the big stores."
                            type="radio"
                            aria-label="radio 1"
                            label="We make shirts for the big stores."
                            onChange={()=> setAnswer9("We make shirts for the big stores.")}
                            checked={answer9==="We make shirts for the big stores."}
                        />
                        <Form.Check
                            value="Supplying all the large shops."
                            type="radio"
                            aria-label="radio 1"
                            label="Supplying all the large shops."
                            onChange={()=> setAnswer9("Supplying all the large shops.")}
                            checked={answer9==="Supplying all the large shops."}
                        />
                                </div>
                               </div>
                               <div className="col-lg-3">
                                <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658969699/Site-pics/image_2022-07-28_055457234_unyoxw.png"/>
                               </div>
                        </div>
                        <br/>
                        
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }



                    { (currentStep ===10 ) &&
                      <Form.Group controlId='Question 10'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>10. </b>Wriete any of the words below that should have started with a capital letter.
                                </Form.Label>
                                <br /><br />
                                <i>I will see you next thursday at 2 o’clock. Please thank lindsay for her help.</i>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer10}
                            onChange={(e) => setAnswer10(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }



                { (currentStep ===11 ) &&
                      <Form.Group controlId='Question 11'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>11. </b>Read the three sentences. One is trying to persuade; one is trying to inform; and one is trying to explain.
                                    <br/> Write A, B or C in the answer box.
                                </Form.Label>
                                <br /><br />
                                A.	The meeting is on Tuesday.<br/>
                                B.	Why not come to the meeting on Tuesday? We would love to see you there.<br/>
                                C.	The purpose of the meeting on Tuesday is to tell you about our new products.<br/>

                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div className='col-sm-1 my-auto'>
                                <Form.Label>Persuade:</Form.Label>
                            </div>
                            <div className='col-sm-11'>
                                <Form.Control
                                    required
                                    value={answer11a}
                                    onChange={(e) => setAnswer11a(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div className='col-sm-1 my-auto'>
                                <Form.Label>Inform:</Form.Label>
                            </div>
                            <div className='col-sm-11'>
                                <Form.Control
                                    required
                                    value={answer11b}
                                    onChange={(e) => setAnswer11b(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div className='col-sm-1 my-auto'>
                                <Form.Label>Explain:</Form.Label>
                            </div>
                            <div className='col-sm-11'>
                                <Form.Control
                                    required
                                    value={answer11c}
                                    onChange={(e) => setAnswer11c(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        </Form.Group>
                        }

                        
                    { (currentStep ===12) &&
                      <Form.Group >
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>12. </b>Choose the most suitable joining word for this sentence. 
                                </Form.Label>
                                <br/><i>To use the copier, switch on at the mains ____________	pressing the green button.</i>
                                </div>
                        </div>
                        <br/>
                        <Form.Label>Answer: </Form.Label>
                        <Form.Check
                            value="and"
                            type="radio"
                            aria-label="radio 1"
                            label="and"
                            onChange={()=> setAnswer12("and")}
                            checked={answer12==='and'}
                        />
                        <Form.Check
                            value="before"
                            type="radio"
                            aria-label="radio 1"
                            label="before"
                            onChange={()=> setAnswer12("before")}
                            checked={answer12==='before'}
                        />
                        <Form.Check
                            value="or"
                            type="radio"
                            aria-label="radio 1"
                            label="or"
                            onChange={()=> setAnswer12("or")}
                            checked={answer12==="or"}
                        />
                        <Form.Check
                            value="so"
                            type="radio"
                            aria-label="radio 1"
                            label="so"
                            onChange={()=> setAnswer12("so")}
                            checked={answer12==="so"}
                        />
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }





                    { (currentStep ===13 ) &&
                      <Form.Group controlId='Question 13'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>13. </b>In the box write the full address to which the computer should be sent.
                                </Form.Label>
                                <br /><br />
                                <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658972798/Site-pics/image_2022-07-28_064636086_rlrmfi.png"/>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            as = "textarea"
                            required
                            value={answer13}
                            onChange={(e) => setAnswer13(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }




                    { (currentStep ===14 ) &&
                      <Form.Group controlId='Question 14'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>14. </b>In the box write the full address to which the computer should be sent.
                                </Form.Label>
                                <br /><br />
                                <img src="https://res.cloudinary.com/my-free-course/image/upload/v1658973721/Site-pics/image_2022-07-28_070159101_wbd5uv.png"/>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer14}
                            onChange={(e) => setAnswer14(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }




                    { (currentStep ===15 ) &&
                      <Form.Group controlId='Question 15'>
                        <div className="row english-assessment-long">
                            <div className='col-lg-12 my-auto'>
                                <Form.Label><b>15. </b>	Read this section from a safety report and decide which of the following statements is correct.
                                <br/>
                                Write A, B, C or D in the answer box.
<br/><br/>
                                A.	Safety is the responsibility of the union.<br/>
                                B.	All safety standards had fallen.<br/>
                                C.	Floors in the factory were slippery.<br/>
                                D.	Certain safety standards were slipping.<br/>

                                </Form.Label>
                                <br /><br />
                                <img style={{border: "1px solid black"}} src="https://res.cloudinary.com/my-free-course/image/upload/v1658974066/Site-pics/image_2022-07-28_070744529_opzm3z.png"/>

                            </div>
                        </div>
                        <br/>
                        <Form.Label>Answer:</Form.Label>
                        <Form.Control
                            required
                            value={answer15}
                            onChange={(e) => setAnswer15(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Please fill the required field.
                        </Form.Control.Feedback>
                        </Form.Group>
                        }



					{ (currentStep > 0 && currentStep < 15) && <><Button
                        onClick={()=>{
                            setStep(currentStep+1);
                            scrollTtoView();
                        }}
						className='next-Button-form'
						style={{
							width: '100px',
							color: 'white',
                            float: 'right'
						}}
					>
						{' '}
						Next
					</Button></>}
                    { (currentStep=== 15) && <><Button
                        onClick={(e)=>submitHandler(e)}
						className='next-Button-form'
						style={{
							width: '100px',
							color: 'white',
                            float: 'right'
						}}
					>
						{' '}
						Submit
					</Button></>}
                    { (currentStep > 1 && currentStep < 16) && <><Button
                        onClick={()=>{
                            setStep(currentStep-1);
                            scrollTtoView();
                        }}
						className='back-Button-form'
						style={{
							width: '100px',
							color: 'white',
                            float: 'left'
						}}
					>
						{' '}
						Back
					</Button></>
                    }
				</Form>
			</FormContainer>
			<div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
				<center>
					<Spinner animation='border' />
				</center>
			</div>
		</>
	)
}

export default EnglishTestLong