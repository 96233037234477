export const DETAILFORM_SAVE_REQUEST = "DETAILFORM_SAVE_REQUEST";
export const DETAILFORM_SAVE_SUCCESS = "DETAILFORM_SAVE_SUCCESS";
export const DETAILFORM_SAVE_FAIL = "DETAILFORM_SAVE_FAIL";

export const LEADSFORM_SAVE_REQUEST = "LEADSFORM_SAVE_REQUEST";
export const LEADSFORM_SAVE_SUCCESS = "LEADSFORM_SAVE_SUCCESS";
export const LEADSFORM_SAVE_FAIL = "LEADSFORM_SAVE_FAIL";

export const TWINSLEADSFORM_SAVE_REQUEST = "TWINSLEADSFORM_SAVE_REQUEST";
export const TWINSLEADSFORM_SAVE_SUCCESS = "TWINSLEADSFORM_SAVE_SUCCESS";
export const TWINSLEADSFORM_SAVE_FAIL = "TWINSLEADSFORM_SAVE_FAIL";


export const FORM_SAVE_REQUEST = "FORM_SAVE_REQUEST";
export const FORM_SAVE_SUCCESS = "FORM_SAVE_SUCCESS";
export const FORM_SAVE_FAIL = "FORM_SAVE_FAIL";

export const EMERGENCYDETAILS_SAVE_REQUEST = "EMERGENCYDETAILS_SAVE_REQUEST";
export const EMERGENCYDETAILS_SAVE_SUCCESS = "EMERGENCYDETAILS_SAVE_SUCCESS";
export const EMERGENCYDETAILS_SAVE_FAIL = "EMERGENCYDETAILS_SAVE_FAIL";

export const EMPLOYEMENTFORM_SAVE_REQUEST = "EMPLOYEMENTFORM_SAVE_REQUEST";
export const EMPLOYEMENTFORM_SAVE_SUCCESS = "EMPLOYEMENTFORM_SAVE_SUCCESS";
export const EMPLOYEMENTFORM_SAVE_FAIL = "EMPLOYEMENTFORM_SAVE_FAIL";

export const QUALIFICATIONFORM_SAVE_REQUEST = "QUALIFICATIONFORM_SAVE_REQUEST";
export const QUALIFICATIONFORM_SAVE_SUCCESS = "QUALIFICATIONFORM_SAVE_SUCCESS";
export const QUALIFICATIONFORM_SAVE_FAIL = "QUALIFICATIONFORM_SAVE_FAIL";

export const OPPURTUNITIESFORM_SAVE_REQUEST = "OPPURTUNITIESFORM_SAVE_REQUEST";
export const OPPURTUNITIESFORM_SAVE_SUCCESS = "OPPURTUNITIESFORM_SAVE_SUCCESS";
export const OPPURTUNITIESFORM_SAVE_FAIL = "OPPURTUNITIESFORM_SAVE_FAIL";

export const DECLARATIONFORM_SAVE_REQUEST = "DECLARATIONFORM_SAVE_REQUEST";
export const DECLARATIONFORM_SAVE_SUCCESS = "DECLARATIONFORM_SAVE_SUCCESS";
export const DECLARATIONFORM_SAVE_FAIL = "DECLARATIONFORM_SAVE_FAIL";

export const PROOF_SAVE_REQUEST = "PROOF_SAVE_REQUEST";
export const PROOF_SAVE_SUCCESS = "PROOF_SAVE_SUCCESS";
export const PROOF_SAVE_FAIL = "PROOF_SAVE_FAIL";

export const SIGN_SAVE_REQUEST = "SIGN_SAVE_REQUEST";
export const SIGN_SAVE_SUCCESS = "SIGN_SAVE_SUCCESS";
export const SIGN_SAVE_FAIL = "SIGN_SAVE_FAIL";

export const FINAL_SAVE_REQUEST = "FINAL_SAVE_REQUEST";
export const FINAL_SAVE_SUCCESS = "FINAL_SAVE_SUCCESS";
export const FINAL_SAVE_FAIL = "FINAL_SAVE_FAIL";

export const FORM_DETAILS_REQUEST = "FORM_DETAILS_REQUEST";
export const FORM_DETAILS_SUCCESS = "FORM_DETAILS_SUCCESS";
export const FORM_DETAILS_FAIL = "FORM_DETAILS_FAIL";

export const UPDATE_DETAILS_REQUEST = "UPDATE_DETAILS_REQUEST";
export const UPDATE_DETAILS_SUCCESS = "UPDATE_DETAILS_SUCCESS";
export const UPDATE_DETAILS_FAIL = "UPDATE_DETAILS_FAIL";

export const DELETE_FORM_REQUEST = "DELETE_FORM_REQUEST";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_FAIL = "DELETE_FORM_FAIL";

export const DELETE_IN_FORM_REQUEST = "DELETE_IN_FORM_REQUEST";
export const DELETE_IN_FORM_SUCCESS = "DELETE_IN_FORM_SUCCESS";
export const DELETE_IN_FORM_FAIL = "DELETE_IN_FORM_FAIL";

export const INC_FORM_DETAILS_REQUEST = "INC_FORM_DETAILS_REQUEST";


export const ASSESSMENT_SAVE_REQUEST = "ASSESSMENT_SAVE_REQUEST";
export const ASSESSMENT_SAVE_SUCCESS = "ASSESSMENT_SAVE_SUCCESS";
export const ASSESSMENT_SAVE_FAIL = "ASSESSMENT_SAVE_FAIL";

export const CALLCENTERFORM_SAVE_REQUEST = "CALLCENTERFORM_SAVE_REQUEST"
export const CALLCENTERFORM_SAVE_SUCCESS = "CALLCENTERFORM_SAVE_SUCCESS"
export const CALLCENTERFORM_SAVE_FAIL = "CALLCENTERFORM_SAVE_FAIL"
