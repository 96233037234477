import React,{ useEffect, useState} from 'react'
import MaterialTable from 'material-table';
import axios from 'axios';



const ApprovedForms = () => {
	const [forms, setForms] = useState([])
	const [allColleges, setAllColleges] = useState([])
	const [accounts, setAccounts] = React.useState([])
	const [callcenter,setCallcenter] = useState([])

    useEffect(async()=>{
        const data = await axios.get('form/approved-completed')
        setForms(data.data.data)
        console.log(data.data.data)
    },[])

    useEffect(async() => {
        const data = await axios.get('/contracts/all')
        let array = []
        let array2
        for (let i=0;i<data.data.data.length;i++){
            array.push([
                data.data.data[i]._id,data.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data.data.data.length - 1){
                array2=obj
            }
        }
        setAllColleges(array2)
	}, [])

    
    useEffect(async() => {
		const data = await axios.get('/support/getallnadmin')
        let array = []
        let array2
        for (let i=0;i<data.data.data.length;i++){
            array.push([
                data.data.data[i]._id,data.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data.data.data.length - 1){
                array2=obj
            }
        }
        setAccounts(array2)
        array =[]
        array = []
        const data2 = await axios.get('/callcenter/all')
        for (let i=0;i<data2.data.data.length;i++){
            array.push([
                data2.data.data[i]._id,data2.data.data[i].name
            ])
            let obj = Object.fromEntries(array)
            if (i === data2.data.data.length - 1){
                array2=obj
            }
        }
        
        setCallcenter(array2)
	}, [])


    return(<>
    <div className='container incomplete-container'>
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
                    <MaterialTable
                        title="Approved Forms"
                        columns={[
                            { title: 'First Name', field: 'personalDetails.firstName',editable: 'never' },
                            { title: 'Last Name', field: 'personalDetails.lastName',editable: 'never' },
                            { title: 'Email', field: 'personalDetails.email',editable: 'never' },
                            { title: 'Applied Course', field: 'detailsFormData.appliedCourse',editable: 'never'},
                            { title: 'College', field: 'contractId',lookup: allColleges},
                            { title: 'Course', field: 'detailsFormData.appliedCourse',editable: 'never'},
                            { title: 'Date Applied', field: 'date',editable: 'never'},
                            { title: 'Date Approved', field: 'dateApproved',editable: 'never'},
                            { title: 'Completed By', field: 'completedBy',emptyValue:"none", lookup: callcenter},
                            { title: 'Assigned', field: 'supportStaff',emptyValue:"none", lookup: accounts},
                        ]}
                        data={forms} 
                        options={{
                            pageSize:50,
                            exportButton: true,
                            pageSizeOptions: [20,50,100],
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        
                        />
				</div>
			)}
		</div>
    </>)
}

export default ApprovedForms