import React, { useEffect } from 'react'
import { deleteFormEntry , getCompleteUserFormDetails } from '../../actions/formActions'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialTable from 'material-table';
import { alpha } from '@material-ui/core/styles'

const DeletedCompletedForms = () => {
	const dispatch = useDispatch()
	const [forms, setForms] = React.useState([])

    useEffect(async() => {
		const data = await axios.get('/form/deletedcompleteforms')
        let dataa = data.data.data.sort((a,b)=>new Date(b.updatedAt)-new Date(a.updatedAt))
        setForms(dataa)
	}, [])

	const handleDelete = (id) => {
        confirmAlert({
            title: 'Confirmation', 
            closeOnEscape: true,
            closeOnClickOutside: true,
            message: 'Are you Sure you want to permanently delete this application?',
            buttons: [
              {
                label: 'Confirm Delete',
                onClick: () => {
                    dispatch(deleteFormEntry(id))
                    let prevForms = forms
                    setForms(prevForms.filter(x=> x._id !== id))                }
            }
            ]
          });
		
	}

    const handleStatus = async(formId) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL + '/form/change_status', {
            formId: formId,
            status: "pending",
        })
        if(response.status === 200){
            toast('Application Restored')
            setTimeout(function () {
                window.location.reload()
            }, 3000)
            
            return true
        } else {
            return false
        }       
    }

	return (
		<div className='container incomplete-container'>
			{forms.length == 0 ? (
				<p>Loading Forms</p>
			) : (
				<div>
                    <MaterialTable
                        title="Deleted Forms"
                        columns={[
                            { title: 'First Name', field: 'personalDetails.firstName',editable: 'never' },
                            { title: 'Last Name', field: 'personalDetails.lastName',editable: 'never' },
                            { title: 'Email', field: 'personalDetails.email',editable: 'never' },
                            { title: 'Applied Course', field: 'detailsFormData.appliedCourse',editable: 'never'},
                            { title: 'Mobile', field: 'personalDetails.telephone',editable: 'never' },
                            { title: 'Date', field: 'createdAt',editable: 'never'},
                            ]}
                        data={forms} 
                        options={{
                            pageSize:50,
                            filtering: true,
                            actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: 'restore',
                                tooltip: 'Restore Application',
                                onClick: (event, rowData) => {
                                    handleStatus(rowData._id)
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete Application',
                                onClick: (event, rowData) => {
                                    handleDelete(rowData._id)
                                }
                            },{
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: async(event, rowData) => {
                                    event.preventDefault()
                                    await getCompleteUserFormDetails(rowData._id)
                                    window.location.href = '/edit/personal'
                            }
                        }

                        ]}
                        />
				</div>
			)}
		</div>
	)
}

export default DeletedCompletedForms
