import React from "react";
import { Container, Form } from "react-bootstrap";

const Success = () => {
    return (
        <div className="splash2">
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-5 success-box">
                    <Container
                        className="container "
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                        <Form.Label as="legend">
                            <b>Congratulations </b>on completing <b>STEP 1</b> of the application process.
                        </Form.Label><br />
                        <Form.Label>
                            We are now checking your application to make sure it meets the requirements for our college partners and funding guidelines.
                        </Form.Label><br /><br />
                        <Form.Label>
                            A member of our team will contact you within 48 workings.
                        </Form.Label><br /><br />
                        <Form.Label>
                            Please check your email and mobile for our communication.<br />
                            <br />Many Thanks
                            <br />The My Free Course Team
                        </Form.Label>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default Success;
