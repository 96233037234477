import React, { useState } from "react";
import ScrollToTopOnMount from "../common/ScrollToMount";

import { Helmet } from 'react-helmet'


const TITLE = 'My Free Courses'
const NewSwindonPolicy = () => {
  return (
    <div className="container" id="responsive" style={{ marginTop: "10px" }}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>

      <ScrollToTopOnMount />
      <h2>Data Privacy Notice & Declaration</h2>
      <b>Privacy Policy</b><br/>
      <p>This privacy notice is issued by the Education and Skills Funding Agency (ESFA) on behalf of the Secretary
        of State for the Department of Education (DfE) to inform learners about the Individualised Learner Record
        (ILR) and how their personal information is used in the ILR. Your personal information is used by the DfE to
        exercise our functions under article 6(1)(e) of the UK GDPR and to meet our statutory responsibilities,
        including under the Apprenticeships, Skills, Children and Learning Act 2009.</p>

        <p>The ILR collects data about learners and learning undertaken. Publicly funded colleges, training
        organisations, local authorities, and employers (FE providers) must collect and return the data to the ESFA
        each year under the terms of a funding agreement, contract or grant agreement. It helps ensure that public
        money distributed through the ESFA is being spent in line with government targets. It is also used for
        education, training, employment, and well being purposes, including research. We retain ILR learner data for
        3 years for operational purposes and 66 years for research purposes. For more information about the ILR
        and the data collected, please see the ILR specification at
        https://www.gov.uk/government/collections/individualised-learner-record-ilr</p>

        <p>ILR data is shared with third parties where it complies with DfE data sharing procedures and where the law
        allows it. The DfE and the English European Social Fund (ESF) Managing Authority (or agents acting on
        their behalf) may contact learners to carry out research and evaluation to inform the effectiveness of training.</p>

        <p>For more information about how your personal data is used and your individual rights, please see the DfE
            Personal Information Charter (https://www.gov.uk/government/organisations/department-for-
            education/about/personal-information-charter) and the ESFA Privacy Notice
            (https://www.gov.uk/government/publications/esfa-privacy-notice)</p>
        <p>If you would like to get in touch with us or request a copy of the personal information DfE holds about you,
        you can contact the DfE in the following ways:</p>
        <p>Using our online contact form https://www.gov.uk/government/organisations/department-for-
            education/about/personal-information-charter
            By telephoning the DfE Helpline on 0370 000 2288
            Or in writing to – Data Protection Officer, Ministerial and Public Communications Division, Department for
            Education, Piccadilly Gate, Store Street, Manchester, M1 2WD
            If you are unhappy with how we have used your personal data, you can complain to the Information
            Commissioner’s Office (ICO) at:
            Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF. You can also call their helpline on 0303 123
            1113 or visit https://www.ico.org.uk</p>
            <br/>
    <b>College - How we use your data</b>
    <p>HOW WE USE YOUR DATA</p>
    <p>New College Swindon is committed to protecting your privacy. We are a registered data controller under the
    Data Protection Act 2018 and GDPR. The information we collect is essential in order for us to carry out our
    legal responsibilities, functions and tasks. We hold personal information about you electronically and in paper
    format, under the requirements of the Data Protection Act 2018 (DPA) and General Data Protection
    Regulation (GDPR). We follow security procedures regarding the storage and disclosure of information which
    you have given us in order to avoid unauthorised loss or access. As such we have security systems and
    procedures to protect information from unauthorised disclosure, misuse or destruction.</p>
    <p>For our full privacy policy, including information on why we collect personal information, our lawful process
    for processing your information, the information collected, how it is collected and stored, how it is used and
    who has access to it, how it is protected and how long it is kept for, please visit:
    www.newcollege.ac.uk/about-the-college/how- we-use-your-data</p>
    <p>New College Drive, Swindon, Wiltshire, SN3 1AH Tel: 01793 611470 / www.newcollege.ac.uk</p>
    <br/>
    <b>College - Declaration</b><br/>
    <p>I am aware of the opportunities outlined in New College’s publicity materials and I have made use of those I
    consider appropriate for enrolling on the course chosen. I am aware of my entitlement to advice and
    guidance as specified and I have received advice and guidance at induction. I have been made aware of the
    Additional Learning Support Fund. I have received a copy of the College Charter. I am satisfied with my
    choice of course and understand that I can obtain help or information from the College or from my tutor. I
    understand that if I have declared false information, action may be taken to reclaim the tuition fees and any
    associated costs from me. I declare to the best of my knowledge that the information that I have given is
    correct and are to abide by the terms and conditions of the College. I agree to the College processing
    personal data contained in this form and/or other data which may be obtained for legitimate reasons from me
    or other people whilst I am a student. I am aware that the European Union European Social Fund (ESF) may
    financially support my course.</p>
    <br/>
    <b>College - Learner Agreement</b>
    <br/>
    <p>
    You, the student, in signing this agreement agree:
    </p>
    <ul>
        <li>That you have received appropriate advice and guidance on your choice of programme to assess your
        suitability in accordance with College procedures</li>
        <li>To accept responsibility for your own learning and to review your progress with the support of tutor(s)</li>
        <li>To complete all work to the best of your ability and within specified deadlines, as required by your
        programme</li>
        <li>To follow and respect published College policies and Rules & Regulations, copies of which are available on
        the college’s website</li>
        <li>That the information on this Application/Enrolment form is correct and that you will inform the college of any
        future changes</li>
        <li>That (dependent if your age and/or prior qualifications) you will be enrolled on a programme of study
        including maths and/or English</li>
    </ul>
    </div>
  );
};

export default NewSwindonPolicy;
