import axios from 'axios';

// Create a new attachmentLink
export const createAttachmentLink = async (attachmentLinkData) => {
	try {
		const res = await axios.post('/attachmentLink/', attachmentLinkData);
		return res;
	} catch (err) {
		throw err.response.data;
	}
};

// Sending files for attachmentLink
export const uploadsAttachmentLink = async (attachmentLinkData) => {
    console.log(attachmentLinkData)
	try {
		const res = await axios.post('/attachmentLink/submission', attachmentLinkData);
        return res;
	} catch (err) {
		throw err.response.data;
	}
};


// Get all attachmentLinks
export const getAttachmentLinks = async () => {
	try {
		const res = await axios.get('/attachmentLink');
		return res.data;
	} catch (err) {
		throw err.response.data;
	}
};

// Get specific  attachmentLinks for support team
export const getSupportAttachmentLinks = async (id) => {
	try {
		const res = await axios.get(`/attachmentLink/support/${id}`);
		return res.data;
	} catch (err) {
		throw err.response.data;
	}
};

// Get a single attachmentLink by ID
export const getAttachmentLinkById = async (attachmentLinkId) => {
	try {
		const res = await axios.get(`/attachmentLink/${attachmentLinkId}`);
		return res.data;
	} catch (err) {
		throw err.response.data;
	}
};

// Update an existing attachmentLink by ID
export const updateAttachmentLinkById = async (attachmentLinkId, attachmentLinkData) => {
	try {
		const res = await axios.patch(`/attachmentLink/${attachmentLinkId}`, attachmentLinkData);
		return res.data;
	} catch (err) {
		throw err.response.data;
	}
};

// Delete an attachmentLink by ID
export const deleteAttachmentLinkById = async (attachmentLinkId) => {
	try {
		await axios.delete(`/attachmentLink/${attachmentLinkId}`);
	} catch (err) {
		throw err.response.data;
	}
};
